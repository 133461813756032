import React from "react";
import { useRouteMatch } from "react-router-dom";

import Privileges from "../../Constants/Privilages";
import { PaymentTrackingSidebarPrivileges } from "../../Constants/Nav";

import PackageProfile from "./PackageProfile";
import ViewPackageRoles from "./ViewPackageRoles";
import UpsertPackagePage from "./UpsertPackagePage";
import PaymentTrackingPage from "./PaymentTrackingPage";
import SubscriptionProfile from "./SubscriptionProfile";
import ProtectedRouter from "../../Helpers/HOC/ProtectedRouter";

const PaymentTrackingRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <ProtectedRouter
        exact
        allowBP
        path={path}
        component={PaymentTrackingPage}
        privileges={PaymentTrackingSidebarPrivileges}
      />
      <ProtectedRouter
        exact
        allowBP
        path={`${path}/subscription/:id`}
        component={SubscriptionProfile}
        privileges={[
          Privileges.SUPER_PRIVILEGE,
          Privileges.VIEW_PAYMENT_SUBSCRIPTION_PROFILE,
        ]}
      />
      <ProtectedRouter
        exact
        allowBP
        path={`${path}/package/:id`}
        component={PackageProfile}
        privileges={[
          Privileges.SUPER_PRIVILEGE,
          Privileges.VIEW_PACKAGES_PROFILE,
        ]}
      />
      <ProtectedRouter
        exact
        allowBP
        path={`${path}/new-package`}
        component={UpsertPackagePage}
        privileges={[
          Privileges.SUPER_PRIVILEGE,
          Privileges.ADD_EDIT_DELETE_PACKAGES,
        ]}
      />
      <ProtectedRouter
        exact
        allowBP
        path={`${path}/edit-package/:id`}
        component={UpsertPackagePage}
        privileges={[
          Privileges.SUPER_PRIVILEGE,
          Privileges.ADD_EDIT_DELETE_PACKAGES,
        ]}
      />
      <ProtectedRouter
        exact
        allowBP
        path={`${path}/package/:id/roles`}
        component={ViewPackageRoles}
        privileges={[
          Privileges.SUPER_PRIVILEGE,
          Privileges.ADD_EDIT_DELETE_PACKAGES,
        ]}
      />
    </>
  );
};

export default PaymentTrackingRoutes;
