import React from "react";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import gql from "graphql-tag";
import Constants from "../../Constants";
import { onInputResetAction } from "../../Store/Actions";

import { BSelect, InputForm, CheckboxBooleanForm } from "form-builder";

const GET_WORK_DAYS = gql`
  query GetWorkdays($id: ID) {
    attendance_profile(id: $id) {
      workdays
    }
  }
`;

// input name => workRemotelySettings
const WorkRemotely = ({ FormProps, serverValidationPrefix = "" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [workdays, setWorkdays] = React.useState([]);

  // Reducer State
  const inputData = useSelector(
    (state) => state?.[FormProps?.reducer || "super"]?.[FormProps?.formName]
  );

  // Server State
  const [getWorkdays, { loading }] = useLazyQuery(GET_WORK_DAYS);

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (
      inputData?.attendanceType === Constants.attendanceTypes.OFFICE_BASED &&
      inputData?.canWorkRemote &&
      !inputData?.flexibleRemote &&
      inputData?.attendanceProfileId &&
      !workdays?.length
    ) {
      getWorkdays({
        variables: { id: inputData?.attendanceProfileId },
        onCompleted: ({ attendance_profile = {} }) => {
          setWorkdays(
            attendance_profile?.workdays?.length
              ? attendance_profile?.workdays.map((workday) => ({
                  value: workday,
                }))
              : []
          );
        },
      });
    }
  }, [
    inputData?.attendanceType,
    inputData?.canWorkRemote,
    inputData?.flexibleRemote,
    inputData?.attendanceProfileId,
    workdays?.length,
  ]);

  React.useEffect(() => {
    if (
      !inputData?.canWorkRemote ||
      inputData?.attendanceType !== Constants.attendanceTypes.OFFICE_BASED
    ) {
      dispatch(onInputResetAction(FormProps.formName, "remoteDays"));
      dispatch(onInputResetAction(FormProps.formName, "flexibleRemote"));
      dispatch(onInputResetAction(FormProps.formName, "canExchangeDays"));
    }
  }, [inputData?.attendanceType, inputData?.canWorkRemote]);

  return (
    <>
      <CheckboxBooleanForm
        {...FormProps}
        name="canWorkRemote"
        options={["Allow Employee To Work From Home"]}
        validationName={`input.${serverValidationPrefix}canWorkRemote`}
        setWithValue
      />
      <CheckboxBooleanForm
        {...FormProps}
        options={[
          "Employee must submit work remotely request before the start of work day",
        ]}
        name="allow_work_remotely_cutoff"
        validationName={`input.${serverValidationPrefix}.allow_work_remotely_cutoff`}
        type="checkbox"
        containerStyle="leaves_checkbox_container mb-2"
        dependOn="canWorkRemote"
        dependancyType="equal"
        dependancyValue={[true]}
        setWithValue
      />

      <InputForm
        {...FormProps}
        validateBy="textRequired"
        label="Employee must submit work remotely request before the start of work day by"
        name="work_remotely_cutoff"
        validationName={`input.${serverValidationPrefix}.work_remotely_cutoff`}
        type="text"
        hasSuffix
        suffixTitle="Hours"
        containerStyle="leaves_field_container my-2 d-flex justify-content-between"
        inputContainerStyle="d-flex align-items-center"
        dependOn="allow_work_remotely_cutoff"
        dependancyType="equal"
        dependancyValue={[true]}
        labelStyle="text-13px"
      />

      <CheckboxBooleanForm
        {...FormProps}
        name="employee_can_request"
        dependOn="canWorkRemote"
        dependancyType="equal"
        dependancyValue={[true]}
        options={["Allow Employee To Request Work From Home"]}
        validationName={`input.${serverValidationPrefix}employee_can_request`}
        setWithValue
      />

      <InputForm
        {...FormProps}
        label="Max. Number of requests per week"
        name="maxRemoteDaysPerWeek"
        dependOn="canWorkRemote"
        dependancyType="equal"
        dependancyValue={[true]}
        validateBy="week_day"
        stepInput
        labelStyle="mr-2"
        validationName={`input.${serverValidationPrefix}maxRemoteDaysPerWeek`}
        type="number"
      />

      {inputData?.attendanceType === Constants.attendanceTypes.OFFICE_BASED &&
      inputData?.canWorkRemote ? (
        <>
          <CheckboxBooleanForm
            {...FormProps}
            name="flexibleRemote"
            options={[t("Allow Flexible Work From Home Days")]}
            // optionLabelStyle="content-header-sub-label"
            dependOn="canWorkRemote"
            dependancyType="equal"
            dependancyValue={[true]}
            containerStyle="my-2"
            validationName={`input.${serverValidationPrefix}flexibleRemote`}
            setWithValue
          />
          <CheckboxBooleanForm
            {...FormProps}
            name="canExchangeDays"
            options={["Allow Exchanging Work From Home Days"]}
            dependOn="flexibleRemote"
            dependancyType="equal"
            dependancyValue={[false]}
            containerStyle="mb-2"
            validationName={`input.${serverValidationPrefix}canExchangeDays`}
            setWithValue
          />
          <BSelect
            {...FormProps}
            label={"Default Work From Home Days"}
            name="remoteDays"
            placeholder={t("select option")}
            dependOn="flexibleRemote"
            dependancyType="equal"
            dependancyValue={[false]}
            isMulti
            hideSelectedOptions
            optionLabel="value"
            optionValue="value"
            options={workdays}
            validateBy="arrayRequired"
            labelStyle="mb-2"
            validationName={`input.${serverValidationPrefix}remoteDays`}
            isLoading={loading}
            isDisabled={!inputData?.attendanceProfileId}
            icon="suitcase"
          />
        </>
      ) : null}
    </>
  );
};

export default WorkRemotely;
