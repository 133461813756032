export const RequestLimitInitState = {
  has_request_dayoff_limit: 0,
  dayoff_request_limits: 0,
  dayoff_period_type: "month",

  has_request_change_shift_limit: 0,
  change_shift_request_limits: 0,
  change_shift_period_type: "month",

  has_request_additional_shift_limit: 0,
  additional_shift_request_limits: 0,
  additional_shift_period_type: "month",
}

// DayOffExceptionConfigInput
export const dayOffException = {
  day_off_exception_id: null,
  normal_work_timing_id: null,
  first_half_work_timing_id: null,
  second_half_work_timing_id: null,
  weight: 1,
  apply_compensation: 0,
  compensation_type: null,
  payment_factor_id: null,
  compensation_quantity: undefined,
  treat_as_normal: false,
  allow_permission: false,
  allow_overtime: false,
  offices_ids: [],
  additional_work_places: [],
  additional_work_places_signout: [],
  workplace_setting: "DEFAULT",
  workplace_setting_signout: "DEFAULT",
  workplace_setting_identical: 1,
};

// workRemotelySettings
export const workRemotelySettings = {
  canWorkRemote: false,
  employee_can_request: true,
  maxRemoteDaysPerWeek: 1,
  flexibleRemote: true,
  canExchangeDays: false,
  remoteDays: [],
  allow_work_remotely_cutoff: false,
  work_remotely_cutoff: 0,
};

// UpdateOrCreateAttendanceTypeConfigurationInput
export const attendanceTypeConfig = {
  att_type_id: null,
  attendanceType: "office based",
  user_id: null,
  start: undefined,
  end: undefined,
  attendanceProfileId: undefined,
  workGroupId: null,
  workTeamId: null,
  joiningFrom: undefined,
  allowMultipleCheckIns: false,
  checkInFormId: undefined,
  weekStartDay: 0,
  save_with: "default",
  skip_work_timing_changed: true,
  allow_work_on_day_off: false,
  ...workRemotelySettings,
  ...dayOffException,

  // holiday config inputs
  allow_work_on_holiday: 0,
  same_config: 1,
  holiday_exception_id: null,
  holiday_apply_compensation: 0,
  holiday_weight: 1,
  holiday_compensation_type: null,
  holiday_compensation_quantity: null,
  holiday_treat_as_normal: false,
  holiday_allow_permission: false,
  holiday_allow_overtime: false,
  holiday_allow_half_days: 0,
  holiday_normal_work_timing_id: null,
  holiday_first_half_work_timing_id: null,
  holiday_second_half_work_timing_id: null,
  holiday_workplace_setting: "DEFAULT",
  holiday_workplace_setting_signout: "DEFAULT",
  holiday_additional_work_places: [],
  holiday_additional_work_places_signout: [],
  holiday_workplace_setting_identical: 1,

  ...RequestLimitInitState

};


