import React from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import HelperFns from "../../Helpers/HelperFns";
import Privileges from "../../Constants/Privilages";
import { safeJsonParse, showToast } from "../../Helpers/HelperFns";
import { PaymentStats as Stats } from "../../Constants/PaymentTracking";
import { paymentsInvoiceDetailsQuery } from "../../Graphql/query/PaymentTracking";

import {
  Box,
  List,
  Modal,
  SvgIcon,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import {
  UploadFile,
  CreditCard,
  ModeEditOutlined,
  KeyboardArrowDown,
} from "@mui/icons-material";
import Loader from "../Loader";
import { ExportButton } from "../Buttons";
import FloatingMenu from "../FloatingMenu";
import { TimesIconButton } from "../IconButtonWithTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 901,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
    p: 4,
    pt: 2,
  },
  cell: {
    rowGap: 1,
    columnGap: 3,
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "1fr 1fr 1fr",
  },
};

const serializeFetchInvoiceInfo = (data) => {
  const info = safeJsonParse(safeJsonParse(data?.invoiceInfo));
  const { TAX, TOTAL, ...items } = info?.items || {};

  return {
    status: data?.status,
    invoice: data?.invoice,
    taxPercentage: data?.tax?.amount,
    invoiceInfo: {
      ...info,
      tax: TAX,
      total: TOTAL,
      items: Object.values(items),
      method: safeJsonParse(info?.method),
    },
  };
};

const PreviewInvoice = ({ onClose, ...props }) => {
  const { t } = useTranslation();

  // Local State
  const [data, setData] = React.useState({
    id: props?.data?.id,
    invoiceInfo: {},
  });

  // Server State
  const { loading } = useQuery(paymentsInvoiceDetailsQuery, {
    variables: { id: props?.data?.id },
    onCompleted: ({ payment = {} }) => {
      setData((prev) => ({
        ...prev,
        ...serializeFetchInvoiceInfo(payment),
      }));
    },
  });

  // Constants
  const { id, status, invoice, invoiceInfo, taxPercentage } = data;
  const overdueWithInvoice = status === Stats.OVERDUE && invoice;
  const actions = React.useMemo(
    () => [
      {
        visible:
          ([Stats.UPCOMING, Stats.PENDING].includes(status) ||
            (status === Stats.OVERDUE && !invoice)) &&
          HelperFns.checkPrivileges({
            privileges: [Privileges.EDIT_INVOICES, Privileges.SUPER_PRIVILEGE],
          }),
        label: t("edit invoice"),
        icon: <ModeEditOutlined fontSize="small" color="inherit" />,
        action: () =>
          props?.actions?.handleEditInvoice({
            id,
            invoiceInfo,
          }),
      },
      {
        visible:
          ([Stats.GENERATED].includes(status) || overdueWithInvoice) &&
          HelperFns.checkPrivileges({
            privileges: [Privileges.SEND_INVOICES, Privileges.SUPER_PRIVILEGE],
          }),
        label: t("send"),
        icon: (
          <SvgIcon sx={{ fontSize: 16 }}>
            <FontAwesomeIcon icon="fas fa-sm fa-paper-plane" />
          </SvgIcon>
        ),
        action: () => props?.actions?.handleSendPayment(id),
      },
      {
        visible:
          ([Stats.SENT, Stats.GENERATED].includes(status) ||
            overdueWithInvoice) &&
          HelperFns.checkPrivileges({
            privileges: [
              Privileges.SET_INVOICES_AS_PAID,
              Privileges.SUPER_PRIVILEGE,
            ],
          }),
        label: t("pay"),
        icon: <CreditCard fontSize="small" />,
        action: () => props?.actions?.handlePayPayment(id),
      },
      // {
      //   visible:
      //     ![Stats.GENERATED, Stats.SENT, Stats.PAID, Stats.CANCELLED].includes(
      //       status
      //     ) &&
      //     HelperFns.checkPrivileges({
      //       privileges: [
      //         Privileges.GENERATE_INVOICES,
      //         Privileges.SUPER_PRIVILEGE,
      //       ],
      //     }),
      //   label: t("Generate Invoice"),
      //   icon: <UploadFile fontSize="small" />,
      //   action: () => props?.actions?.handleGenerate(id),
      // },
      {
        visible:
          ([Stats.SENT, Stats.PAID, Stats.GENERATED].includes(status) ||
            overdueWithInvoice) &&
          HelperFns.checkPrivileges({
            privileges: [
              Privileges.CANCEL_GENERATED_INVOICES,
              Privileges.SUPER_PRIVILEGE,
            ],
          }),
        label: t("Cancel"),
        icon: (
          <SvgIcon sx={{ fontSize: 16 }}>
            <FontAwesomeIcon icon="far fa-times-circle" />
          </SvgIcon>
        ),
        action: () => props?.actions?.handleCancelPayment(id),
      },
    ],
    [JSON.stringify(data)]
  );

  /* ↓ Helpers ↓ */

  const handleExport = () => {
    const element = document.getElementById("invoice");

    html2canvas(element)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm");

        // Calculate width and height for the PDF page
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        // Add image to PDF
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

        // Save the PDF
        pdf.save("invoice.pdf");
      })
      .catch((error) => {
        showToast("error", "something went wrong while exporting: ", error);
      });
  };

  const handleCloseModal = () => {
    onClose();
  };

  const ActionsList = React.useCallback(
    () => (
      <List sx={{ width: 160 }}>
        {actions.map((a, i) =>
          a.visible ? (
            <ListItemButton
              key={i}
              onClick={() => {
                a.action();
                handleCloseModal();
              }}
              className="gap-20"
              sx={{
                "&:hover": { color: "#2764AC", svg: { color: "#2764AC" } },
              }}
            >
              <ListItemIcon sx={{ flex: 0.25, minWidth: "initial" }}>
                {a.icon}
              </ListItemIcon>
              <ListItemText primary={a.label} sx={{ flex: 1 }} />
            </ListItemButton>
          ) : null
        )}
      </List>
    ),
    [JSON.stringify(data)]
  );

  return (
    <Modal open onClose={handleCloseModal}>
      <Box sx={styles.container}>
        {loading ? (
          <Loader />
        ) : (
          <>
            {/* Actions */}
            <div className="d-flex mb-4 justify-content-end">
              <TimesIconButton label="close" onClick={handleCloseModal} />
            </div>
            <div className="d-flex flex-row-reverse gap-10 mb-3">
              <ExportButton onClick={handleExport} />
              {![Stats.CANCELLED].includes(status) ? (
                <FloatingMenu
                  label="actions"
                  labelIcon={<KeyboardArrowDown />}
                  sx={{
                    pb: 0.5,
                    color: "white",
                    bgcolor: "#2764AC",
                    "&:hover": { bgcolor: "#2764AC" },
                  }}
                  list={<ActionsList />}
                />
              ) : null}
            </div>

            {/* Content */}
            <div id="invoice" className="border rounded-sm p-4">
              {/* Header */}
              <header className="d-flex justify-content-between gap-20 mb-3">
                <div
                  style={{ borderColor: "#009EFB !important" }}
                  className="d-flex flex-1 border-bottom"
                >
                  <strong
                    className="baby-blue-color mt-3 w-50"
                    style={{ lineHeight: "1.2" }}
                  >
                    <span className="d-block">{invoice || "Pro-forma"}</span>
                    <span style={{ fontSize: 32 }}>INVOICE</span>
                  </strong>

                  {[Stats.PAID, Stats.CANCELLED].includes(status) ? (
                    <img
                      height="79"
                      src={`assets/img/payment_${status?.toLowerCase()}.png`}
                      alt=""
                    />
                  ) : null}
                </div>

                <div>
                  <img
                    src="favicon.ico"
                    width="70"
                    height="70"
                    alt=""
                    className="d-block m-auto"
                  />
                  <b>Mawared HR</b>
                </div>
              </header>

              {/* Details */}
              <Box className="mb-2" sx={styles.cell}>
                <VertCell label="Billed To" val={invoiceInfo?.to} />
                <VertCell label="Billed For" val={invoiceInfo?.for} />
                <VertCell label="City" val={invoiceInfo?.city} />

                <VertCell label="Due Date" val={invoiceInfo?.dueDate} />
                <VertCell
                  label="Payment Method"
                  val={invoiceInfo?.method?.type}
                />
                <VertCell label="Payment Terms" val={invoiceInfo?.terms} />
              </Box>

              {/* Items */}
              <div className="mt-2 mb-3">
                <div
                  style={{ backgroundColor: "#313030" }}
                  className="d-flex justify-content-between p-2 text-white mb-2"
                >
                  <span>{t("items")}</span>
                  <span>{t("amount")}</span>
                </div>
                {invoiceInfo?.items?.map((item, i) => (
                  <HorzCell key={i} label={item?.name} val={item?.amount} />
                ))}

                {invoiceInfo?.tax ? (
                  <HorzCell
                    label={`Tax (${+taxPercentage || "14"}%)`}
                    val={invoiceInfo?.tax}
                    labelClasses="font-weight-bold dark-color"
                  />
                ) : null}
                <HorzCell
                  label="Total"
                  val={invoiceInfo?.total}
                  labelClasses="font-weight-bold dark-color"
                />
              </div>

              {/* Payment Info */}
              {!invoiceInfo?.method ||
              invoiceInfo?.method?.type === "Cash" ? null : (
                <>
                  <b className="baby-blue-color d-block mb-2">
                    {t("Payment Info")}
                  </b>
                  <Box className="mt-2 mb-4" sx={styles.cell}>
                    <VertCell
                      label="Bank Name"
                      val={invoiceInfo?.method?.name}
                    />
                    {invoiceInfo?.method?.type === "Bank" ? (
                      <VertCell
                        label="Bank Beneficiary"
                        val="Mawared System And Solutions"
                      />
                    ) : null}
                    <VertCell
                      label="Account Number"
                      val={invoiceInfo?.method?.no}
                    />
                    {invoiceInfo?.method?.iban ? (
                      <VertCell label="IBAN" val={invoiceInfo?.method?.iban} />
                    ) : null}
                  </Box>
                </>
              )}

              {/* Signature */}
              <b className="d-block mb-2 baby-blue-color">
                {t("Payment Signature")}
              </b>
              <img
                src="assets/img/signature.svg"
                width="120"
                height="auto"
                alt="Mawared Logo"
              />
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default PreviewInvoice;

const VertCell = ({ label, val }) => {
  const { t } = useTranslation();

  return (
    <div>
      {val ? <b className="d-block dark-color">{t(label)}</b> : null}
      <span>{val}</span>
    </div>
  );
};

const HorzCell = ({ label, val, labelClasses }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between border-bottom pb-1 mb-1">
      <span className={labelClasses}>{t(label)}</span>
      <b className="dark-color">{val}</b>
    </div>
  );
};
