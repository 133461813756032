import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  storeReminderMutation,
  changeReminderMutation,
} from "../../Graphql/mutation";
import {
  subStatusOptions,
  leadStatusOptions,
  activityTypeOptions,
} from "../../Constants/CRM";
import moment from "moment";
import { reminderFormQuery, fetchLeadContactQuery } from "../../Graphql/query";
import { buildOption, formatOptions, showToast } from "../../Helpers/HelperFns";

import {
  BSelect,
  InputForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import Loader from "../Loader";
import { Box } from "@mui/material";
import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import ContactModal from "./ContactModal";
import Dropzone from "react-dropzone-uploader";
import { LeadsServerRestSelect } from "../CRM";
import { EditIconButton, TimesIconButton } from "../IconButtonWithTooltip";
import { clearCRMValidations, setCRMValidations } from "../../Store/Actions";

const formNameValidation = "reminderClientValidation";
const formServerValidation = "reminderServerValidation";
const contactModalInitState = { isOpen: false, isEdit: false, data: null };
const subscriptionTypes = [
  "2+1 Month Free",
  "50% Off",
  "Annual Plan",
  "Discounted Annual Plan",
];

const ReminderModal = ({
  data,
  isLeadList,
  isLeadProfile,
  leadProfileData,
  onClose,
  refetchList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isEdit = Boolean(data) && !isLeadList;

  // Local State
  const [options, setOptions] = useState({
    plans: [],
    contacts: [],
    contactsActivityLeads: [],
  });
  const [contactModalState, setContactModalState] = useState(
    contactModalInitState
  );
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [isFetchingInputDepend, setIsFetchingInputDepend] = useState(isEdit);
  const [formData, setFormData] = useState({
    id: null,
    lead: isLeadList ? data : leadProfileData || null,
    type: null,
    contact: null,
    due_date: null,
    notes: "",
    attachments: [],
    setActivity: false,
    activityLead: null,
    activityLeadContact: null,
    activityType: null,
    activityDate: null,
    activityNotes: null,
    activityAttachments: [],
    activityStatus: null,
    activitySubStatus: null,
    activity: null,
    subscription: null,
    empNO: null,
    plans: null,
    meetingTime: null,
    videoLink: null,
  });

  // Reducer State
  const reminderClientValidation = useSelector(
    (state) => state.crm[formNameValidation]
  );

  // Server State
  const [getLeadContact, { loading: isLeadContactLoading }] = useLazyQuery(
    fetchLeadContactQuery
  );
  const [storeReminder, { loading: isStoreLoading }] = useMutation(
    storeReminderMutation
  );
  const [changeReminder, { loading: isChangeLoading }] = useMutation(
    changeReminderMutation
  );
  const { loading: isDataLoading } = useQuery(reminderFormQuery, {
    variables: {
      id: isLeadList ? undefined : data?.id,
      isReminderSkip: !isEdit,
      isLeadList,
    },
    onCompleted: ({ reminder, plans }) => {
      // Data
      if (isEdit) {
        setFormData({
          id: reminder?.id,
          lead: reminder?.lead,
          contact: reminder?.contact,
          savedAttachments: reminder.attachments,
          due_date: reminder?.due_date ? moment(reminder.due_date) : null,
          type: reminder.type
            ? buildOption(JSON.parse(reminder.type)?.name)
            : null,
        });
        setIsFetchingInputDepend(false);
      }

      // Options
      setOptions((prev) => ({
        ...prev,
        plans: plans?.data || [],
      }));
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors[0]?.extensions?.reason ||
          err?.graphQLErrors[0]?.message ||
          err?.message
      );
    },
  });

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    formData.setActivity &&
      formData?.activityLead?.id &&
      handleFetchLeadContact(formData?.activityLead?.id, "activityLead");
  }, [formData.setActivity, formData?.activityLead?.id]);

  React.useEffect(() => {
    formData?.lead?.id && handleFetchLeadContact(formData?.lead?.id, "lead");
    !isFetchingInputDepend &&
      setFormData((prev) => ({ ...prev, contact: null }));
  }, [formData.lead?.id]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCRMValidations(formServerValidation));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const getType = () => {
    let type = { name: formData.activity?.name };

    switch (formData.activity?.id) {
      case "Meeting":
        type = { ...type, meetingTime: formData.meetingTime };
        break;
      case "Demo":
        type = { ...type, videoLink: formData.videoLink };
        break;
      case "Proposal":
        type = {
          ...type,
          empNO: formData.empNO,
          plans: formData.plans,
          subscription: formData.subscription,
        };
        break;
      default:
        break;
    }

    return JSON.stringify(type);
  };

  const handleAddContactModal = () => {
    setContactModalState({
      isOpen: true,
      isEdit: false,
      data: { lead_id: formData.lead?.id },
    });
  };
  const handleEditContactModal = () => {
    setContactModalState({
      isOpen: true,
      isEdit: true,
      data: { lead_id: formData.lead?.id, ...formData.contact },
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
      ...(e.target.name === "setActivity" && {
        activityLead: prev?.lead,
        activityLeadContact: prev?.contact,
      }),
    }));
  };

  const handleTextChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleAttachmentChange = ({ file }, status) => {
    if (status === "done") {
      setFormData((prev) => ({
        ...prev,
        attachments: [...prev?.attachments, file],
      }));
    }
  };

  const handleActivityAttachmentChange = ({ file }, status) => {
    if (status === "done") {
      setFormData((prev) => ({
        ...prev,
        activityAttachments: [...prev?.attachments, file],
      }));
    }
  };

  const handleSavedAttachmentRemove = (idx) => {
    setFormData((prev) => ({
      ...prev,
      savedAttachments: prev.savedAttachments.filter((_, i) => i !== idx),
    }));
  };

  const onUpsertContact = () => {
    handleFetchLeadContact(formData?.lead?.id, "lead");
    formData.setActivity &&
      handleFetchLeadContact(formData?.activityLead?.id, "activityLead");
  };

  const handleSelectChange = (val, { name: key }) => {
    setFormData((prev) => ({ ...prev, [key]: val }));

    // fetch contact
    if (["lead", "activityLead"].includes(key))
      handleFetchLeadContact(val.id, key);
  };

  const handleDateChange = (name, val) => {
    setFormData((prev) => ({ ...prev, [name]: val }));
  };

  const handleFetchLeadContact = (id, key) => {
    id &&
      getLeadContact({
        variables: { id },
        onCompleted: ({ lead = {} }) => {
          setOptions((prev) => ({
            ...prev,
            ...(key === "lead" && { contacts: lead?.contacts || [] }),
            ...(key === "activityLead" && {
              contactsActivityLeads: lead?.contacts || [],
            }),
          }));
          const singleContact = lead?.contacts?.length === 1 ? lead.contacts[0] : null;
          setFormData((prev) => ({
            ...prev,
            ...(key === "lead" && {
              contact: singleContact || (prev?.contact ? lead?.contacts.find( (contact) => contact.id === prev.contact.id) : null),
            }),
            ...(key === "activityLead" && {
              activityLeadContact: singleContact || ( prev?.activityLeadContact ? lead?.contacts.find(
                    (contact) => contact.id === prev.activityLeadContact.id
                  )
                : null),
            }),
          }));
        },
      });
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (reminderClientValidation.length) return;

    const upsert = isEdit ? changeReminder : storeReminder;
    const {
      id,
      type,
      lead,
      contact,
      due_date,
      notes,
      attachments,
      savedAttachments,
    } = formData;

    upsert({
      variables: {
        id,
        lead_id: lead?.id || undefined,
        contact_id: contact?.id || undefined,
        due_date: due_date ? due_date.format("YYYY-MM-D HH:mm:ss") : undefined,
        type: type?.name ? JSON.stringify({ name: type.name }) : undefined,
        notes: notes || undefined,
        attachments: attachments?.length ? attachments : undefined,
        ...(isEdit && {
          attachmentIds: savedAttachments.map((a) => +a.id),
        }),
        setActivity: formData?.setActivity,
        activityInput: formData?.setActivity
          ? {
              activityType: getType(),
              activityLeadId: formData?.activityLead?.id || undefined,
              activityAttachments: formData?.activityAttachments || undefined,
              activityLeadContactId:
                formData?.activityLeadContact?.id || undefined,
              activityDate: formData?.activityDate
                ? formData?.activityDate.format("YYYY-MM-D HH:mm:ss")
                : undefined,
              activityNotes: formData?.activityNotes,
              status: [
                formData?.activityStatus?.name,
                formData?.activitySubStatus?.name,
              ],
            }
          : undefined,
      },
      onCompleted: () => {
        refetchList();
        onClose();
        showToast("success");
      },
      onError: (err) => {
        err?.graphQLErrors?.[0]?.extensions?.validation &&
          dispatch(
            setCRMValidations({
              ref: formServerValidation,
              validations: err?.graphQLErrors?.[0]?.extensions?.validation,
            })
          );

        (err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message) &&
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleUpsert}
      modalTitle={t(`${isEdit ? "edit" : "new"} reminder`)}
      btnSubmitLoading={isStoreLoading || isChangeLoading}
    >
      {(isEdit && isDataLoading) ||
      isLeadContactLoading ||
      isLeadContactLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="d-flex flex-column gap-10">
        {isLeadList || isLeadProfile ? (
          <>
            <strong>{t("lead")}</strong>
            <span>{formData.lead.name}</span>
          </>
        ) : (
          <LeadsServerRestSelect
            type="Reminders"
            name="lead"
            label="lead"
            icon="employee"
            keepDefaultStyle
            value={formData.lead}
            onChange={handleSelectChange}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
        )}
        <div>
          <div className="d-flex align-items-center gap-20 mb-2">
            {t("lead contact")}
            <AddButton
              isIconOnly
              onClick={handleAddContactModal}
              disabled={!Boolean(formData.lead?.id)}
            />
            <EditIconButton
              onClick={handleEditContactModal}
              disabled={!Boolean(formData.contact?.id)}
              tooltipProps={{ isDisable: !Boolean(formData.contact?.id) }}
            />
          </div>
          <BSelect
            name="contact"
            icon="employees"
            keepDefaultStyle
            value={formData.contact}
            onChange={handleSelectChange}
            isLoading={isLeadContactLoading}
            options={options?.contacts || []}
            isDisabled={!Boolean(formData.lead?.name)}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
        </div>
        <BSelect
          label="reminder type"
          name="type"
          icon="employee"
          keepDefaultStyle
          labelStyle="w-100"
          value={formData.type}
          onChange={handleSelectChange}
          options={formatOptions(activityTypeOptions)}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
        />
        <DateTimePickerForm
          hasIcon
          dateTime
          label="due date"
          labelStyle="w-100"
          value={formData.due_date}
          datePickerContainer="w-100"
          onSelect={(val) => handleDateChange("due_date", val)}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          validationName="due_date"
          reducer="crm"
        />

        <label className="w-100 mt-2">
          <span>{t("Notes")}</span>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleTextChange}
            style={{ resize: "none" }}
          />
        </label>
        <span>{t("Attachments")}</span>
        {isEdit ? (
          <div>
            {formData?.savedAttachments?.map((attachment, i) => (
              <div key={i} className="d-flex gap-20 align-items-center py-2">
                {attachment?.name}
                <TimesIconButton
                  onClick={() => handleSavedAttachmentRemove(i)}
                />
              </div>
            ))}
          </div>
        ) : null}
        <Box
          className="documents_dropzone_style"
          sx={{
            ".dzu-dropzone .dzu-previewContainer": {
              height: 60,
              justifyContent: "center",
              minHeight: "initial !important",
              ".dzu-previewStatusContainer": { position: "static" },
            },
          }}
        >
          <Dropzone onChangeStatus={handleAttachmentChange} />
        </Box>
      </div>

      {/* Set Activity */}
      <CheckboxBooleanForm
        setWithValue
        name="setActivity"
        options={["Set Activity"]}
        checked={formData?.setActivity}
        onChange={handleCheckboxChange}
      />
      {formData?.setActivity ? (
        <>
          <LeadsServerRestSelect
            type="Reminders"
            name="activityLead"
            label="lead"
            icon="employee"
            keepDefaultStyle
            value={formData.activityLead}
            onChange={handleSelectChange}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
          <BSelect
            label="activity"
            name="activity"
            icon="employee"
            keepDefaultStyle
            labelStyle="w-100"
            value={formData.activity}
            onChange={handleSelectChange}
            options={formatOptions(activityTypeOptions)}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
          <BSelect
            name="activityLeadContact"
            label="Contact"
            icon="employees"
            keepDefaultStyle
            value={formData.activityLeadContact}
            onChange={handleSelectChange}
            isLoading={isLeadContactLoading}
            options={options?.contactsActivityLeads || []}
            isDisabled={!Boolean(formData.activityLead?.name)}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
          <DateTimePickerForm
            hasIcon
            dateTime
            label="date"
            labelStyle="w-100"
            value={formData.activityDate}
            datePickerContainer="w-100"
            onSelect={(val) => handleDateChange("activityDate", val)}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
            formServerValidation={formServerValidation}
            validationName="activityInput.activityDate"
            reducer="crm"
          />
          {formData.activity?.name === "Meeting" ? (
            <DateTimePickerForm
              hasIcon
              dateTime
              labelStyle="w-100"
              label="meeting time"
              value={formData.meetingTime}
              datePickerContainer="w-100"
              onSelect={(val) => handleDateChange("meetingTime", val)}
            />
          ) : null}
          {formData.activity?.name === "Demo" ? (
            <InputForm
              name="videoLink"
              label="video link"
              labelStyle="w-100"
              onChange={handleTextChange}
              inputContainerStyle="w-100"
              value={formData.videoLink}
            />
          ) : null}
          {formData.activity?.name === "Proposal" ? (
            <>
              <div className="d-flex align-items-end gap-10">
                <BSelect
                  name="subscription"
                  icon="employees"
                  keepDefaultStyle
                  label="subscription type"
                  rootStyle="flex-fill"
                  value={formData.subscription}
                  onChange={handleSelectChange}
                  options={formatOptions(subscriptionTypes)}
                  classNamePrefix="b-select-style"
                />
                <InputForm
                  type="number"
                  name="empNO"
                  containerStyle=" "
                  label="number Of employees"
                  rootStyle="flex-fill"
                  value={formData.empNO}
                  onChange={handleTextChange}
                />
              </div>
              <BSelect
                isMulti
                name="plans"
                label="plans"
                icon="employees"
                keepDefaultStyle
                value={formData.plans}
                options={options.plans}
                isLoading={!options.plans.length}
                classNamePrefix="b-select-style"
                onChange={handleSelectChange}
              />
            </>
          ) : null}

          <label className="w-100 mt-2">
            <span>{t("Notes")}</span>
            <textarea
              name="activityNotes"
              value={formData.activityNotes}
              onChange={handleTextChange}
              style={{ resize: "none" }}
            />
          </label>

          <span>{t("Attachments")}</span>
          <Box
            className="documents_dropzone_style"
            sx={{
              ".dzu-dropzone .dzu-previewContainer": {
                height: 60,
                justifyContent: "center",
                minHeight: "initial !important",
                ".dzu-previewStatusContainer": { position: "static" },
              },
            }}
          >
            <Dropzone onChangeStatus={handleActivityAttachmentChange} />
          </Box>
          <div className="d-flex gap-10">
            <BSelect
              name="activityStatus"
              icon="employee"
              label="lead status"
              keepDefaultStyle
              rootStyle="flex-fill"
              value={formData.activityStatus}
              onChange={handleSelectChange}
              options={formatOptions(leadStatusOptions)}
              validateBy="textRequired"
              formSubmitting={formSubmitting}
              formNameValidation={formNameValidation}
            />
            {Object.keys(subStatusOptions).includes(
              formData.activityStatus?.name
            ) ? (
              <BSelect
                name="activitySubStatus"
                icon="employee"
                label="lead sub status"
                keepDefaultStyle
                rootStyle="flex-fill"
                onChange={handleSelectChange}
                value={formData.activitySubStatus}
                classNamePrefix="b-select-style"
                options={formatOptions(
                  subStatusOptions[formData.activityStatus.name]
                )}
                validateBy="textRequired"
                formSubmitting={formSubmitting}
                formNameValidation={formNameValidation}
              />
            ) : null}
          </div>
        </>
      ) : null}

      {contactModalState.isOpen ? (
        <ContactModal
          state={contactModalState}
          onUpsertContact={onUpsertContact}
          onClose={() => setContactModalState(contactModalInitState)}
        />
      ) : null}
    </MainModal>
  );
};

export default ReminderModal;
