import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import Constants from "../../Constants";

// Ui
import {
  Requests,
  Compensation,
  WorkOnDaysOff,
} from "../SharedFormSections/DayOffException";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { CheckboxForm, InputForm, RadioboxForm } from "form-builder";
import { onInputResetAction } from "../../Store/Actions";
import WorkOnHolidays from "../SharedFormSections/WorkOnHolidays";
import _ from "lodash";

const AttWorkingDaysSection = ({ costCentersOptions = [], ...props }) => {
  const { formProps } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Reducer State
  const {
    weekends,
    treat_as_normal,
    compensation_type,
    apply_compensation,
    allow_work_on_day_off,
    normalWorkTiming,
    firstHalfWorkTiming,
    secondHalfWorkTiming,
  } = useSelector((state) => state.super.attendaceProfile);
  const workTimeOptions = useSelector((state) => state?.super?.workTimeOptions);

  /* ↓ State Effects ↓ */

  useDidUpdateEffect(() => {
    if (!weekends?.length || !allow_work_on_day_off) {
      dispatch(onInputResetAction(formProps.formName, "allow_overtime"));
      dispatch(onInputResetAction(formProps.formName, "allow_permission"));
      dispatch(onInputResetAction(formProps.formName, "apply_compensation"));
      dispatch(onInputResetAction(formProps.formName, "allow_work_on_day_off"));
    }
  }, [weekends?.length, allow_work_on_day_off]);

  // start of handle work time options consist of worktime menu data and old selected work time data
  const serilazedNormalWorkTimingArray = [
    workTimeOptions?.filter(
      (el) => el?.work_timing_profile_type?.toLowerCase() === "normal"
    ) ?? [],
    normalWorkTiming ?? [],
  ].flat();

  const serilazedHalfWorkTimingArray = [
    workTimeOptions?.filter(
      (el) => el?.work_timing_profile_type?.toLowerCase() === "half"
    ) ?? [],
    [firstHalfWorkTiming ?? {}, secondHalfWorkTiming ?? {}] ?? [],
  ].flat();

  const normalWorkTimeOptions = _.uniqBy(serilazedNormalWorkTimingArray, "id");
  const halfWorkTimeOptions = _.uniqBy(serilazedHalfWorkTimingArray, "id");
  // End of handle work time options consist of worktime menu data and old selected work time data

  return (
    <>
      <div className="position-relative">
        <InputForm
          {...formProps}
          validateBy="textRequired"
          label="profile name"
          name="name"
          validationName="input.name"
          type="text"
          placeholder={t("attendance profile name")}
          labelStyle="name_label"
          containerStyle="edit_attendance_profile_name"
          inputContainerStyle=" "
          inputStyle="name_input"
        />
        <PersonOutlineIcon className="name_input_icon" />
      </div>

      <h1 className="att_profile_header">{t("work week")}</h1>

      <div className="boxContainer">
        <RadioboxForm
          {...formProps}
          options={Constants.WeekDays}
          validateBy="textRequired"
          label="First Day Of the Week"
          name="week_start_day"
          validationName="input.week_start_day"
          type="radio"
          containerStyle="mt-2 mb-3"
          labelStyle="label-style"
          optionsContainerStyle="optionsContainerStyle"
          optionItemStyle="optionItemStyle"
          optionInputStyle=" "
          optionLabelStyle="optionLabelStyle"
        />
        <CheckboxForm
          {...formProps}
          options={Constants.WeekendDays}
          label="Days Off"
          name="weekends"
          validationName="input.weekends"
          type="checkbox"
          containerStyle="mt-2 mb-3"
          labelStyle="label-style"
          optionsContainerStyle="optionsContainerStyle"
          optionItemStyle="optionItemStyle"
          optionInputStyle=" "
          optionLabelStyle="optionLabelStyle"
          optionLabelActiveStyle="optionLabelActiveStyle"
        />

        {weekends?.length ? (
          <>
            <WorkOnDaysOff
              formProps={{ ...formProps, reducer: "super" }}
              halfWorkTimingOptions={halfWorkTimeOptions}
              normalWorkTimingOptions={normalWorkTimeOptions}
              costCentersOptions={costCentersOptions}
            />
            <div className="ml-3">
              {allow_work_on_day_off && !treat_as_normal ? (
                <>
                  <Compensation
                    formProps={formProps}
                    compensation_type={compensation_type}
                    apply_compensation={apply_compensation}
                  />
                  <Requests formProps={formProps} />
                </>
              ) : null}
            </div>
          </>
        ) : null}
        <WorkOnHolidays
          normalWorkTimingOptions={normalWorkTimeOptions}
          halfWorkTimingOptions={halfWorkTimeOptions}
          formProps={formProps}
          serverValidationPrefix="input"
          costCentersOptions={costCentersOptions}
        />
      </div>
    </>
  );
};

export default AttWorkingDaysSection;
