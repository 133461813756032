import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { InputForm } from "form-builder";
import { Box } from "@mui/material";
import IntlTelInput from "react-intl-tel-input";
import { upsertContactCompanyMutation } from "../../../../../Graphql/mutation";
import { RemoveIconButton } from "../../../../IconButtonWithTooltip";
import {
  fetchCompanyCompanyContactsAction,
  onFormResetAction,
  setInputInValidateAction,
  setInputValidateAction,
  updateValueAction,
} from "../../../../../Store/Actions";
import HelperFns, { showToast } from "../../../../../Helpers/HelperFns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import MainModal from "../../../../MainModal";

const ContactForm = ({ closeContactForm, isModalVissible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const formProps = {
    formName: "addContactForm",
    formNameValidation: "addContactFormValidation",
    formSubmitting,
    formServerValidation: "addContactFormServerValidation",
  };

  const companyID = useSelector(
    (state) => state.super.selectedCompanyProfile?.id
  );

  const formData = useSelector((state) => state?.super?.addContactForm);

  const contactFormDataValidation = useSelector(
    (state) => state?.super?.addContactFormValidation
  );

  const addContactFormServerValidation = useSelector(
    (state) => state?.super?.addContactFormServerValidation
  );

  const [upsertCompanyContact, { loading: upsertCompanyContactIsLoading }] =
    useMutation(upsertContactCompanyMutation);

  const handleUpsertCompanyContact = () => {
    setFormSubmitting(true);
    if (
      contactFormDataValidation?.length ||
      addContactFormServerValidation?.length
    )
      return;
    upsertCompanyContact({
      variables: {
        input: {
          ...formData,
          company_id: companyID,
        },
      },
      onCompleted: (res) => {
        if (res?.upsert_company_contacts?.id) {
          dispatch(fetchCompanyCompanyContactsAction(companyID));
          closeContactForm();
          return;
        }
        showToast("error", t("somthing went wrong"));
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err.message
        );
      },
    });
  };

  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const handlePhoneInputChange = (isValid, value, seletedCountryData) => {
    setIsPhoneValid(isValid);

    dispatch(updateValueAction(formProps?.formName, "phone", value));
    dispatch(
      updateValueAction(
        formProps?.formName,
        "phone_country_code",
        `+${seletedCountryData?.dialCode}`
      )
    );
  };

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formProps?.formName));
    };
  }, []);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={closeContactForm}
      modalTitle={
        formData?.id ? t("Edit company contact") : t("Add company contact")
      }
      btnOnClick={handleUpsertCompanyContact}
      btnSubmitLoading={upsertCompanyContactIsLoading}
      className="company_form_wrapper_style"
    >
      <form>
        <InputForm
          {...formProps}
          icon="user"
          rootStyle="w-100"
          name="name"
          label="name"
          containerStyle="pb-0"
          placeholder={t("contact name")}
          labelStyle="d-block"
          inputContainerStyle="w-100"
          validateBy={"textRequired"}
        />
        <div className="d-flex mt-2 w-100 justify-content-center align-items-center">
          {/* <div
            className={`container-style-default w-50 ${
              formSubmitting && !isPhoneValid ? "invalid-container-style" : ""
            }`}
          > */}

          <div className={`container-style-default w-50`}>
            <label
              className={`label-style-default w-100 validity-label-style `}
            >
              {t("phone")}
            </label>
            <IntlTelInput
              value={formData?.phone ?? ""}
              separateDialCode
              format={false}
              formatOnInit={false}
              autoComplete="tel"
              inputClassName="input-style-default validity-input-style w-150"
              preferredCountries={["eg", "ae", "sa"]}
              onPhoneNumberChange={handlePhoneInputChange}
            />
            {/* <span className="validity-msg-style mt-2">
              {!isPhoneValid ? t("this phone number is not valid") : null}
            </span> */}
          </div>

          <InputForm
            {...formProps}
            icon="phone"
            rootStyle="w-50"
            name="description"
            label="description"
            containerStyle="pb-0"
            placeholder={t("whatsapp number")}
            labelStyle="d-block"
            inputContainerStyle="w-100"
          />
        </div>
        <div className="d-flex mt-2">
          <InputForm
            {...formProps}
            icon="mail"
            rootStyle="w-50"
            name="email"
            label="email"
            containerStyle="pb-0 mr-4"
            placeholder={t("email")}
            labelStyle="d-block"
            inputContainerStyle="w-100"
            validateBy={"email"}
          />
          <InputForm
            {...formProps}
            icon="office"
            rootStyle="w-50"
            name="position"
            label="position"
            containerStyle="pb-0"
            placeholder={t("position")}
            labelStyle="d-block"
            inputContainerStyle="w-100"
          />
        </div>
      </form>
    </MainModal>
  );
};

export default ContactForm;
