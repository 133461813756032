import React from "react";
import { useQuery } from "@apollo/client";

import moment from "moment";
import { dateTimeListFormat } from "../../Constants";
import { calendarEventQuery } from "../../Graphql/query";

import IconButtonWithTooltip, {
  CopyIconButton,
} from "../IconButtonWithTooltip";
import MainModal from "../MainModal";
import { Link } from "react-router-dom";
import { ModalHeader } from "reactstrap";
import { CircularProgress } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const getVariables = (kind) => {
  if (kind.includes("Meetings")) return { isMeeting: true };
  if (kind.includes("Activities")) return { isActivity: true };
  if (kind.includes("Reminders")) return { isReminders: true };
  if (kind.includes("Trial")) return { isTrial: true };
};

const CalendarEventModal = ({ data, onClose }) => {
  const variables = { ...getVariables(data.kind), id: data.kindID };

  // Server State
  const { loading, data: unitData } = useQuery(calendarEventQuery, {
    variables,
    skip: variables?.isMeeting,
  });
  console.log(data);
  return (
    <MainModal
      isOpen
      hasModalFooter={false}
      modalHeader={
        <ModalHeader
          toggle={onClose}
          className="d-flex justify-between align-items-center"
        >
          <strong style={{ color: data.color }}>{data?.kind}</strong>
          {moment(data.start).format(dateTimeListFormat)}
        </ModalHeader>
      }
    >
      {loading ? (
        <div
          style={{ height: 150 }}
          className="d-flex justify-content-center align-items-center"
        >
          <CircularProgress />
        </div>
      ) : (
        <ul className="list-unstyled">
          {/* Meetings */}
          {data.kind.includes("Meetings") ? (
            <>
              <ListUnit name="Name" val={data?.title} />
              <ListUnit name="Agent" val={data?.user} />
              {data?.start_url ? (
                <ListUnit
                  name="agent URL"
                  val={
                    <IconButtonWithTooltip
                      isExternalLink
                      href={data?.start_url}
                      buttonProps={{ target: "_blank" }}
                      icon={<ArrowOutwardIcon fontSize="small" />}
                    />
                  }
                />
              ) : null}
              {data?.join_url ? (
                <ListUnit
                  name="client URL"
                  val={
                    <>
                      <CopyIconButton
                        onClick={() => {
                          navigator.clipboard.writeText(data?.join_url);
                        }}
                      />
                      <IconButtonWithTooltip
                        isExternalLink
                        href={data?.join_url}
                        buttonProps={{ target: "_blank" }}
                        icon={<ArrowOutwardIcon fontSize="small" />}
                      />
                    </>
                  }
                />
              ) : null}
              {data?.directory ? (
                <ListUnit
                  name="meeting URL"
                  val={
                    <IconButtonWithTooltip
                      isExternalLink
                      href={data?.directory}
                      buttonProps={{ target: "_blank" }}
                      icon={<ArrowOutwardIcon fontSize="small" />}
                    />
                  }
                />
              ) : null}
            </>
          ) : null}

          {/* Activities */}
          {data.kind.includes("Activities") ? (
            <>
              <ListUnit
                name="Status"
                val={`${JSON.parse(unitData?.activity?.status || "{[]}")?.[0]}${
                  JSON.parse(unitData?.activity?.status || "{[]}")?.[1]
                    ? " - " +
                      JSON.parse(unitData?.activity?.status || "{[]}")?.[1]
                    : ""
                }`}
              />
              <ListUnit name="Activity" val={data?.type} />
              <ListUnit
                name="Lead"
                val={
                  <Link to={`/crm/lead/${unitData?.activity?.lead?.id}`}>
                    {unitData?.activity?.lead?.name}
                  </Link>
                }
              />
              <ListUnit
                name="Assigned to"
                val={
                  unitData?.activity?.lead?.assignedTo?.name || "not assigned"
                }
              />
              <ListUnit name="Contact" val={unitData?.activity?.contact.name} />
            </>
          ) : null}

          {/* Reminders */}
          {data.kind.includes("Reminders") ? (
            <>
              <ListUnit name="Status" val={unitData?.reminder?.status} />
              <ListUnit
                name="Due Date"
                val={moment(unitData?.reminder?.due_date).format(
                  dateTimeListFormat
                )}
              />
              <ListUnit
                name="Lead"
                val={
                  <Link to={`/crm/lead/${unitData?.reminder?.lead?.id}`}>
                    {unitData?.reminder?.lead?.name}
                  </Link>
                }
              />
              <ListUnit
                name="Assigned to"
                val={
                  unitData?.reminder?.lead?.assignedTo?.name || "not assigned"
                }
              />
              <ListUnit name="Contact" val={unitData?.reminder?.contact.name} />
            </>
          ) : null}

          {/* Trial */}
          {data.kind.includes("Trial") ? (
            <>
              <ListUnit name="Company" val={data?.title} />
              <ListUnit
                name="Plan"
                val={unitData?.trial?.subscription?.plan?.name}
              />
              <ListUnit
                name="Business Partners"
                val={unitData?.trial?.subscription?.company?.businessPartners
                  .map(({ user = {} }) => user?.name)
                  .join(" - ")}
              />
              <br />
              <ListUnit
                name="NO. of employees"
                val={unitData?.trial?.subscription?.limit}
              />
              <ListUnit
                name="Start Date"
                val={moment(unitData?.trial?.subscription?.start_date).format(
                  dateTimeListFormat
                )}
              />
              <ListUnit
                name="End Date"
                val={moment(unitData?.trial?.subscription?.end_date).format(
                  dateTimeListFormat
                )}
              />
            </>
          ) : null}
        </ul>
      )}
    </MainModal>
  );
};

export default CalendarEventModal;

const ListUnit = ({ name, val }) => (
  <li className="d-flex align-items-center gap-10">
    <strong>{name || "s"}</strong>
    <span>{val}</span>
  </li>
);
