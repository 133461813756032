import React from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import HelperFns, {
  showToast,
  formatOptions,
  formatSendingInputs,
} from "../../../Helpers/HelperFns";
import {
  GET_LEADS_BREAKDOWN_STATUS_REPORT,
  GET_LEADS_BREAKDOWN_REPORT_OPTIONS,
  GET_LEADS_BREAKDOWN_STATUS_SUB_STATUS_REPORT,
} from "../../../Graphql/query";
import Privileges from "../../../Constants/Privilages";
import { leadSourcesOptions } from "../../../Constants";
import { onFormResetAction } from "../../../Store/Actions";

import {
  CRMTagsSelect,
  LeadAgentSelect,
  CRMReportBarChart,
} from "../../../Components/CRM";
import IconButtonWithTooltip, {
  BarsIconButton,
} from "../../../Components/IconButtonWithTooltip";
import { Box, Grow } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Loader from "../../../Components/Loader";
import DataTable from "react-data-table-component";
import MainModal from "../../../Components/MainModal";
import { BSelect, InputForm, DateTimePickerForm } from "form-builder";
import { ViewRelevantLeads } from "../../../Components/CRMFormModals";

/**
 *
 * Start of `Leads Breakdown Report`
 *
 */

const modalInitState = { isOpen: false, data: null };
const filtersReducerKey = "leadsBreakdownReportReport";
const handleSendSourceCampaign = (source, campaign) => {
  if (!source && !campaign) return undefined;

  if (source === "Facebook") {
    return [source, ...(campaign ? [campaign] : [])];
  } else {
    return !source ? ["Facebook", ...(campaign ? [campaign] : [])] : [source];
  }
};
const sendFilters = ({ ...filters }) => {
  return {
    input: {
      ...formatSendingInputs({ ...filters, campaign: undefined }),
      name: filters?.name,
      source: handleSendSourceCampaign(
        filters?.source?.id,
        filters?.campaign?.id
      ),
    },
  };
};
const getChartData = (data) => {
  return {
    labels: [
      "Fresh Lead",
      "Interested",
      "Unreachable",
      "Not Interested",
      "Follow Up",
      "Reviewing Proposal",
      "Demo",
      "Trial",
      "Client",
    ],
    datasets: [
      {
        label: "Lead Status Count",
        data: [
          +data?.fresh_lead_count?.split(" ")?.[0],
          +data?.interested_count?.split(" ")?.[0],
          +data?.unreachable_count?.split(" ")?.[0],
          +data?.not_interested_count?.split(" ")?.[0],
          +data?.follow_up_count?.split(" ")?.[0],
          +data?.reviewing_proposal_count?.split(" ")?.[0],
          +data?.demo_count?.split(" ")?.[0],
          +data?.trial_count?.split(" ")?.[0],
          +data?.client_count?.split(" ")?.[0],
        ],
      },
    ],
  };
};

const LeadsBreakdownReport = () => {
  const dispatch = useDispatch();
  // const isTabletMatch = useMediaQuery("(max-width: 767px)");

  // Local State
  const [chartData, setChartData] = React.useState(null);
  const [showChart, setShowChart] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState(null);
  const [data, setData] = React.useState({ total: {}, agents: [] });
  const [modalState, setModalState] = React.useState(modalInitState);
  const [chartModalState, setChartModalState] = React.useState(modalInitState);
  const [options, setOptions] = React.useState({
    tags: [],
    countries: [],
    campaigns: [],
  });

  // Reducer State
  const filters = useSelector((state) => state.crm[filtersReducerKey]);

  // Server State
  const { loading: isOptionsLoading } = useQuery(
    GET_LEADS_BREAKDOWN_REPORT_OPTIONS,
    {
      onCompleted: ({ countries, campaigns, tags }) => {
        setOptions((prev) => ({
          ...prev,
          tags: tags?.data || [],
          campaigns: campaigns || [],
          countries: countries?.length ? formatOptions(countries) : [],
        }));
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    }
  );
  const { loading } = useQuery(GET_LEADS_BREAKDOWN_STATUS_REPORT, {
    variables: {
      ...sendFilters(filters),
    },
    onCompleted: (res) => {
      setData(res?.leadsStatusReport || null);
      setChartData(getChartData(res?.leadsStatusReport?.total));
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  // Constants
  const totalColumns = [
    {
      name: "name",
    },
    {
      name: "Total leads",
      selector: "lead_count",
      center: true,
    },
    {
      name: "Fresh leads",
      selector: "fresh_lead_count",
      center: true,
    },
    {
      name: "Interested",
      center: true,
      cell: (row) => (
        <ViewCell
          ignoreClickableIfZero
          num={row?.interested_count}
          onClick={() => handleShowSubTable("Interested")}
        />
      ),
    },
    {
      name: "Unreachable",
      center: true,
      cell: (row) => (
        <ViewCell
          num={row?.unreachable_count}
          onClick={() => handleShowSubTable("Unreachable")}
        />
      ),
    },
    {
      name: "Not interested",
      center: true,
      cell: (row) => (
        <ViewCell
          num={row?.not_interested_count}
          onClick={() => handleShowSubTable("Not Interested")}
        />
      ),
    },
    {
      name: "Follow up",
      center: true,
      cell: (row) => (
        <ViewCell
          num={row?.follow_up_count}
          onClick={() => handleShowSubTable("Follow-Up")}
        />
      ),
    },
    {
      name: "Reviewing Proposal",
      center: true,
      cell: (row) => (
        <ViewCell
          num={row?.reviewing_proposal_count}
          onClick={() => handleShowSubTable("Reviewing Proposal")}
        />
      ),
    },
    {
      name: "Demo",
      center: true,
      cell: (row) => (
        <ViewCell
          num={row?.demo_count}
          // onClick={() => handleShowSubTable("Demo")}
        />
      ),
    },
    {
      name: "Trial",
      center: true,
      cell: (row) => (
        <ViewCell
          num={row?.trial_count}
          // onClick={() => handleShowSubTable("Trial")}
        />
      ),
    },
    {
      name: "Client",
      center: true,
      cell: (row) => (
        <ViewCell
          num={row?.client_count}
          // onClick={() => handleShowSubTable("Client")}
        />
      ),
    },
  ];
  const agentColumns = [
    {
      name: "Agent name",
      grow: 2,
      cell: (row) => (
        <ViewCell
          num={row?.name}
          onClick={() =>
            handleShowChart({ name: row?.name, chartData: getChartData(row) })
          }
        />
      ),
    },
    {
      name: "Total leads",
      cell: (row) => (
        <ViewCell
          viewLeads
          ignoreClickableIfZero
          num={row?.lead_count}
          onClick={() => openRelevantLeadsModal(row.total_leads_ids)}
        />
      ),
    },
    {
      name: "Fresh leads",
      cell: (row) => (
        <ViewCell
          viewLeads
          ignoreClickableIfZero
          num={row?.fresh_lead_count}
          onClick={() => openRelevantLeadsModal(row.fresh_leads_ids)}
        />
      ),
    },
    {
      name: "Interested",
      cell: (row) => (
        <ViewCell
          viewLeads
          ignoreClickableIfZero
          num={row?.interested_count}
          onClick={() => openRelevantLeadsModal(row.interested_leads_ids)}
        />
      ),
    },
    {
      name: "Unreachable",
      cell: (row) => (
        <ViewCell
          viewLeads
          ignoreClickableIfZero
          num={row?.unreachable_count}
          onClick={() => openRelevantLeadsModal(row.unreachable_leads_ids)}
        />
      ),
    },
    {
      name: "Not interested",
      cell: (row) => (
        <ViewCell
          viewLeads
          ignoreClickableIfZero
          num={row?.not_interested_count}
          onClick={() => openRelevantLeadsModal(row.not_interested_leads_ids)}
        />
      ),
    },
    {
      name: "Follow up",
      cell: (row) => (
        <ViewCell
          viewLeads
          ignoreClickableIfZero
          num={row?.follow_up_count}
          onClick={() => openRelevantLeadsModal(row.follow_up_leads_ids)}
        />
      ),
    },
    {
      name: "Reviewing Proposal",
      cell: (row) => (
        <ViewCell
          viewLeads
          ignoreClickableIfZero
          num={row?.reviewing_proposal_count}
          onClick={() =>
            openRelevantLeadsModal(row.reviewing_proposal_leads_ids)
          }
        />
      ),
    },
    {
      name: "Demo",
      cell: (row) => (
        <ViewCell
          viewLeads
          ignoreClickableIfZero
          num={row?.demo_count}
          onClick={() => openRelevantLeadsModal(row.demo_leads_ids)}
        />
      ),
    },
    {
      name: "Trial",
      cell: (row) => (
        <ViewCell
          viewLeads
          ignoreClickableIfZero
          num={row?.trial_count}
          onClick={() => openRelevantLeadsModal(row.trial_leads_ids)}
        />
      ),
    },
    {
      name: "Client",
      cell: (row) => (
        <ViewCell
          viewLeads
          ignoreClickableIfZero
          num={row?.client_count}
          onClick={() => openRelevantLeadsModal(row.client_leads_ids)}
        />
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handleShowSubTable = (type) => {
    setSelectedType(type);
  };

  const handleHideSubTable = () => {
    setSelectedType(null);
  };

  const openRelevantLeadsModal = (ids) => {
    const leadIDs = ids?.split(",");
    setModalState({ isOpen: true, data: { leadIDs } });
  };

  // const handleSelectAllStatus = () => {
  //   dispatch(
  //     onFormResetAction(filtersReducerKey, {
  //       ...filters,
  //       status: formatOptions(["Fresh Lead", ...leadStatusOptions]),
  //     })
  //   );
  // };

  const handleShowChart = (data) => {
    setChartModalState({ isOpen: true, data });
  };

  const handleFilters = (e) => {
    dispatch(
      onFormResetAction(filtersReducerKey, {
        ...filters,
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleSelectFilters = (val, { name }) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, [name]: val }));
  };
  const handleDateFiltersChange = (name, val) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, [name]: val }));
  };

  const Child = () => (
    <DataTable
      noHeader
      noTableHead
      data={data.agents}
      columns={agentColumns}
      className="cards_table"
      progressPending={loading}
      progressComponent={<Loader />}
      customStyles={{
        rows: {
          style: {
            minHeight: "35px  !important",
            marginTop: "0 !important",
            border: "none !important",
            boxShadow: "none !important",
          },
        },
      }}
    />
  );

  return (
    <>
      {/* Filters */}
      <div className="my-4">
        {/* <div className="d-flex gap-10 align-items-end">
          <InputForm
            name="name"
            icon="search"
            value={filters.name}
            inputStyle="border-0"
            onChange={handleFilters}
            placeholder={"Search"}
            inputContainerStyle="w-100"
            rootStyle="boxContainer shadow-sm flex-fill px-2 m-0 py-0"
          />
        </div> */}
        <Box
          className="align-items-end gap-20 mt-3"
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, minmax(100px, 1fr))",
            // gridTemplateColumns: `repeat(${
            //   isTabletMatch ? "3" : "6"
            // }, minmax(100px, 1fr))`,
          }}
        >
          <BSelect
            isClearable
            icon="city"
            name="country"
            label="country"
            placeholder={"select country"}
            value={filters.country}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            isLoading={isOptionsLoading}
            options={options?.countries}
            containerStyle=" "
          />
          {/* <div className="">
            <div className="d-flex justify-content-between">
              <span>status</span>
              <button className="link-btn" onClick={handleSelectAllStatus}>
                Select All
              </button>
            </div>
            <BSelect
              isMulti
              isClearable
              name="status"
              icon="document"
              placeholder={"select status"}
              value={filters?.status || []}
              onChange={handleSelectFilters}
              inputContainerStyle="w-100"
              options={formatOptions(["Fresh Lead", ...leadStatusOptions])}
              containerStyle=" "
            />
          </div>
          <BSelect
            isClearable
            name="sub_status"
            label="sub status"
            icon="document"
            placeholder={"select sub status"}
            value={filters.sub_status}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            options={formatOptions(subStatusOptions[filters.status?.[0]?.id])}
            containerStyle=" "
            isDisabled={filters?.status?.length > 1}
          /> */}
          <LeadAgentSelect
            isClearable
            page="REPORTS"
            name="assigned_to"
            label="assigned to"
            placeholder={"assigned to"}
            value={filters.assigned_to}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            containerStyle=" "
          />
          <DateTimePickerForm
            hasIcon
            isClearable
            label="from"
            labelStyle="w-100"
            value={filters.from}
            rootStyle="flex-fill"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("from", val)}
          />
          <DateTimePickerForm
            hasIcon
            label="to"
            isClearable
            labelStyle="w-100"
            value={filters.to}
            rootStyle="flex-fill"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("to", val)}
          />
          <BSelect
            isClearable
            icon="employee"
            name="source"
            label="source"
            placeholder={"source"}
            value={filters.source}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            isLoading={isOptionsLoading}
            options={formatOptions(leadSourcesOptions)}
            containerStyle=" "
          />
          <BSelect
            isClearable
            name="campaign"
            label="campaign"
            placeholder="campaign"
            value={
              filters.source && filters.source?.id !== "Facebook"
                ? null
                : filters.campaign
            }
            isLoading={loading}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            options={formatOptions(options.campaigns)}
            isDisabled={filters.source && filters.source?.id !== "Facebook"}
            containerStyle=" "
          />
          <CRMTagsSelect
            isMulti
            name="tagIds"
            label="tags"
            rootStyle="w-100"
            value={filters.tagIds}
            onChange={handleSelectFilters}
            options={options?.tags}
            isLoading={loading}
            inputContainerStyle="w-100"
            containerStyle=" "
          />
          <div className="d-flex gap-5">
            <InputForm
              name="minNo"
              containerStyle=" "
              value={filters.minNo}
              onChange={handleFilters}
              placeholder="min emp"
            />
            <InputForm
              name="maxNo"
              containerStyle=" "
              value={filters.maxNo}
              onChange={handleFilters}
              placeholder="max emp"
            />
          </div>
          {/* <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.VIEW_OTHER_LEADS,
              Privileges.VIEW_UNASSIGNED_LEADS,
            ]}
          >
            <RadioboxForm
              name="type"
              value={filters.type}
              options={[
                {
                  label: "all",
                  value: "All",
                  optProps: { onChange: handleFilters },
                },
                {
                  label: "Assigned",
                  value: "Assigned",
                  optProps: { onChange: handleFilters },
                },
                {
                  label: "Unassigned",
                  value: "Unassigned",
                  optProps: { onChange: handleFilters },
                },
              ]}
              optionInputStyle=" "
              containerStyle="mb-0"
              optionItemStyle="d-inline-block mx-2"
              optionsContainerStyle="d-flex"
            />
          </HasPrivileges> */}
        </Box>
      </div>

      {/* Navigation */}
      <div className="d-flex justify-content-between mb-3">
        {Boolean(selectedType) ? (
          <IconButtonWithTooltip
            sx={{ mb: 1.5 }}
            icon={<ArrowBack />}
            onClick={handleHideSubTable}
            label="navigate back to base table"
          />
        ) : null}
        {Boolean(data) ? (
          <BarsIconButton
            className="ml-auto"
            onClick={() => setShowChart((prev) => !prev)}
            label={showChart ? "show table" : "show chart"}
          />
        ) : null}
      </div>

      {/* Tables */}
      <Box
        sx={{
          display: "grid",
          ".base": { order: !Boolean(selectedType) ? 1 : 2 },
          ".sub": { order: Boolean(selectedType) ? 1 : 2 },
          "& > *": { maxWidth: "100%", overflow: "auto" },
        }}
      >
        {/* Base Table */}
        <Grow unmountOnExit in={!Boolean(selectedType)}>
          <Box className="base">
            {showChart ? (
              <CRMReportBarChart loading={loading} chartData={chartData} />
            ) : (
              <DataTable
                noHeader
                data={data?.total ? [data.total] : []}
                columns={totalColumns}
                className="cards_table expandable"
                progressPending={loading}
                progressComponent={<Loader />}
                expandableRows
                expandableRowExpanded={() => true}
                expandableRowDisabled={() => true}
                expandableRowsComponent={<Child />}
              />
            )}
          </Box>
        </Grow>

        {/* Sub Table */}
        <Grow unmountOnExit in={Boolean(selectedType)}>
          <Box className="sub">
            <SubTable
              filters={filters}
              showChart={showChart}
              selectedType={selectedType}
              handleShowChart={handleShowChart}
              openRelevantLeadsModal={openRelevantLeadsModal}
            />
          </Box>
        </Grow>
      </Box>

      {/* Modals */}
      {modalState?.isOpen ? (
        <ViewRelevantLeads
          data={modalState.data}
          onClose={() => setModalState(modalInitState)}
        />
      ) : null}
      {chartModalState.isOpen ? (
        <MainModal
          isOpen
          size="lg"
          hasModalFooter={false}
          modalTitle={chartModalState?.data?.name}
          toggle={() => setChartModalState(modalInitState)}
          sx={{ "& > div > div": { height: "100% !important" } }}
        >
          <CRMReportBarChart
            loading={loading}
            chartData={chartModalState?.data?.chartData}
          />
        </MainModal>
      ) : null}
    </>
  );
};

export default LeadsBreakdownReport;

/**
 *
 * End of `Leads Breakdown Report`
 *
 * Start of `Sub Status Report`
 *
 */

const getBooleans = (type) => {
  return {
    Interested: type === "Interested",
    Unreachable: type === "Unreachable",
    FollowUp: type === "Follow-Up",
    NotInterested: type === "Not Interested",
    ReviewingProposal: type === "Reviewing Proposal",
  };
};
const getSubChartData = (type, subData) => {
  let labels = [],
    data = [];

  switch (type) {
    case "Interested":
      labels = ["Highly Interested", "Semi-Interested", "Low interest"];
      data = [
        +subData.highly_interested_count?.split(" ")?.[0],
        +subData.semi_interested_count?.split(" ")?.[0],
        +subData.low_interested_count?.split(" ")?.[0],
      ];
      break;
    case "Unreachable":
      labels = [
        "First Call",
        "Second Call",
        "Third Call",
        "Fourth Call",
        "Fifth Call",
        "Sixth Call",
        "Details Sent",
      ];
      data = [
        +subData.First_call_unreachable_count?.split(" ")?.[0],
        +subData.Second_call_unreachable_count?.split(" ")?.[0],
        +subData.Third_call_unreachable_count?.split(" ")?.[0],
        +subData.Fourth_call_unreachable_count?.split(" ")?.[0],
        +subData.Fifth_call_unreachable_count?.split(" ")?.[0],
        +subData.Sixth_call_unreachable_count?.split(" ")?.[0],
        +subData.Details_sent_unreachable_count?.split(" ")?.[0],
      ];
      break;
    case "Not Interested":
      labels = [
        "Misunderstood",
        "Issues with pricing",
        "Client is using another system",
        "System is not fulfilling client requirements",
        "Others",
      ];
      data = [
        +subData.misunderstood_not_interested_count?.split(" ")?.[0],
        +subData.issues_with_pricing_not_interested_count?.split(" ")?.[0],
        +subData.client_is_using_another_system_not_interested_count?.split(
          " "
        )?.[0],
        +subData.system_is_not_fulfilling_client_requirements_not_interested_count?.split(
          " "
        )?.[0],
        +subData.others_not_interested_count?.split(" ")?.[0],
      ];
      break;
    case "Follow-Up":
      labels = ["Requested By Client", "Busy at the moment"];
      data = [
        +subData.requested_by_client_follow_up_count?.split(" ")?.[0],
        +subData.busy_at_the_moment_follow_up_count?.split(" ")?.[0],
      ];
      break;
    case "Reviewing Proposal":
      labels = ["In Progress", "Accepted Proposal", "Rejected Proposal"];
      data = [
        +subData.in_progress_reviewing_proposal_count?.split(" ")?.[0],
        +subData.accepted_proposal_reviewing_proposal_count?.split(" ")?.[0],
        +subData.rejected_proposal_reviewing_proposal_count?.split(" ")?.[0],
      ];
      break;
    default:
      break;
  }

  return {
    labels,
    datasets: [{ label: "Lead Sub Status Count", data }],
  };
};

const SubTable = ({
  filters,
  showChart,
  selectedType,
  handleShowChart,
  openRelevantLeadsModal,
}) => {
  // Local State
  const [chartData, setChartData] = React.useState(null);
  const [data, setData] = React.useState({ total: {}, agents: [] });

  // Server State
  const { loading } = useQuery(GET_LEADS_BREAKDOWN_STATUS_SUB_STATUS_REPORT, {
    variables: {
      ...getBooleans(selectedType),
      ...sendFilters({ ...filters, status: [selectedType] }),
    },
    onCompleted: (res) => {
      setData(res?.leadsSubStatusReport || null);
      setChartData(
        getSubChartData(selectedType, res?.leadsSubStatusReport?.total)
      );
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  // Constants
  const totalColumns = [
    {
      name: "name",
      grow: 2,
    },
    {
      name: "Total leads",
      selector: "lead_count",
    },

    ...(getBooleans(selectedType).Interested
      ? [
          {
            name: "Highly Interested",
            selector: "highly_interested_count",
          },
          {
            name: "Semi-Interested",
            selector: "semi_interested_count",
          },
          {
            name: "Low Interested",
            selector: "low_interested_count",
          },
        ]
      : []),

    ...(getBooleans(selectedType).NotInterested
      ? [
          {
            name: "Misunderstood",
            selector: "misunderstood_not_interested_count",
          },
          {
            name: "Issues with pricing",
            selector: "issues_with_pricing_not_interested_count",
          },
          {
            name: "Client is using another system",
            selector: "client_is_using_another_system_not_interested_count",
          },
          {
            name: "System is not fulfilling client requirements",
            selector:
              "system_is_not_fulfilling_client_requirements_not_interested_count",
          },
          {
            name: "Others",
            selector: "others_not_interested_count",
          },
        ]
      : []),

    ...(getBooleans(selectedType).Unreachable
      ? [
          {
            name: "First Call",
            selector: "First_call_unreachable_count",
          },
          {
            name: "Second Call",
            selector: "Second_call_unreachable_count",
          },
          {
            name: "Third Call",
            selector: "Third_call_unreachable_count",
          },
          {
            name: "Fourth Call",
            selector: "Fourth_call_unreachable_count",
          },
          {
            name: "Fifth Call",
            selector: "Fifth_call_unreachable_count",
          },
          {
            name: "Sixth Call",
            selector: "Sixth_call_unreachable_count",
          },
          {
            name: "Details Sent",
            selector: "Details_sent_unreachable_count",
          },
        ]
      : []),

    ...(getBooleans(selectedType).FollowUp
      ? [
          {
            name: "Requested By Client",
            selector: "requested_by_client_follow_up_count",
          },
          {
            name: "Busy at the moment",
            selector: "busy_at_the_moment_follow_up_count",
          },
        ]
      : []),

    ...(getBooleans(selectedType).ReviewingProposal
      ? [
          {
            name: "In Progress",
            selector: "in_progress_reviewing_proposal_count",
          },
          {
            name: "Accepted Proposal",
            selector: "accepted_proposal_reviewing_proposal_count",
          },
          {
            name: "Rejected Proposal",
            selector: "rejected_proposal_reviewing_proposal_count",
          },
        ]
      : []),
  ];
  const agentColumns = [
    {
      grow: 2,
      style: { paddingInlineStart: "58px" },
      cell: (row) => (
        <ViewCell
          num={row?.name}
          onClick={() =>
            handleShowChart({
              name: row?.name,
              chartData: getSubChartData(selectedType, row),
            })
          }
        />
      ),
    },
    {
      name: "Total leads",
      cell: (row) => (
        <ViewCell
          viewLeads
          ignoreClickableIfZero
          num={row?.lead_count}
          onClick={() => openRelevantLeadsModal(row.total_leads_ids)}
        />
      ),
    },

    ...(getBooleans(selectedType).Interested
      ? [
          {
            name: "Highly Interested",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.highly_interested_count}
                onClick={() =>
                  openRelevantLeadsModal(row.highly_interested_leads_ids)
                }
              />
            ),
          },
          {
            name: "Semi-Interested",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.semi_interested_count}
                onClick={() =>
                  openRelevantLeadsModal(row.semi_interested_leads_ids)
                }
              />
            ),
          },
          {
            name: "Low Interested",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.low_interested_count}
                onClick={() =>
                  openRelevantLeadsModal(row.low_interested_leads_ids)
                }
              />
            ),
          },
        ]
      : []),

    ...(getBooleans(selectedType).NotInterested
      ? [
          {
            name: "Misunderstood",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.misunderstood_not_interested_count}
                onClick={() =>
                  openRelevantLeadsModal(row.misunderstood_leads_ids)
                }
              />
            ),
          },
          {
            name: "Issues with pricing",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.issues_with_pricing_not_interested_count}
                onClick={() =>
                  openRelevantLeadsModal(row.issues_with_pricing_leads_ids)
                }
              />
            ),
          },
          {
            name: "Client is using another system",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.client_is_using_another_system_not_interested_count}
                onClick={() =>
                  openRelevantLeadsModal(
                    row.client_is_using_another_system_leads_ids
                  )
                }
              />
            ),
          },
          {
            name: "System is not fulfilling client requirements",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={
                  row?.system_is_not_fulfilling_client_requirements_not_interested_count
                }
                onClick={() =>
                  openRelevantLeadsModal(
                    row.system_is_not_fulfilling_client_requirements_leads_ids
                  )
                }
              />
            ),
          },
          {
            name: "Others",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.others_not_interested_count}
                onClick={() => openRelevantLeadsModal(row.others_leads_ids)}
              />
            ),
          },
        ]
      : []),

    ...(getBooleans(selectedType).Unreachable
      ? [
          {
            name: "First Call",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.First_call_unreachable_count}
                onClick={() => openRelevantLeadsModal(row.First_call_leads_ids)}
              />
            ),
          },
          {
            name: "Second Call",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.Second_call_unreachable_count}
                onClick={() =>
                  openRelevantLeadsModal(row.Second_call_leads_ids)
                }
              />
            ),
          },
          {
            name: "Third Call",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.Third_call_unreachable_count}
                onClick={() => openRelevantLeadsModal(row.Third_call_leads_ids)}
              />
            ),
          },
          {
            name: "Fourth Call",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.Fourth_call_unreachable_count}
                onClick={() =>
                  openRelevantLeadsModal(row.Fourth_call_leads_ids)
                }
              />
            ),
          },
          {
            name: "Fifth Call",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.Fifth_call_unreachable_count}
                onClick={() => openRelevantLeadsModal(row.Fifth_call_leads_ids)}
              />
            ),
          },
          {
            name: "Sixth Call",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.Sixth_call_unreachable_count}
                onClick={() => openRelevantLeadsModal(row.Sixth_call_leads_ids)}
              />
            ),
          },
          {
            name: "Details Sent",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.Details_sent_unreachable_count}
                onClick={() =>
                  openRelevantLeadsModal(row.Details_sent_leads_ids)
                }
              />
            ),
          },
        ]
      : []),

    ...(getBooleans(selectedType).FollowUp
      ? [
          {
            name: "Requested By Client",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.requested_by_client_follow_up_count}
                onClick={() =>
                  openRelevantLeadsModal(row.requested_by_client_leads_ids)
                }
              />
            ),
          },
          {
            name: "Busy at the moment",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.busy_at_the_moment_follow_up_count}
                onClick={() =>
                  openRelevantLeadsModal(row.busy_at_the_moment_leads_ids)
                }
              />
            ),
          },
        ]
      : []),

    ...(getBooleans(selectedType).ReviewingProposal
      ? [
          {
            name: "In Progress",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.in_progress_reviewing_proposal_count}
                onClick={() =>
                  openRelevantLeadsModal(row.in_progress_leads_ids)
                }
              />
            ),
          },
          {
            name: "Accepted Proposal",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.accepted_proposal_reviewing_proposal_count}
                onClick={() =>
                  openRelevantLeadsModal(row.accepted_proposal_leads_ids)
                }
              />
            ),
          },
          {
            name: "Rejected Proposal",
            cell: (row) => (
              <ViewCell
                viewLeads
                ignoreClickableIfZero
                num={row?.rejected_proposal_reviewing_proposal_count}
                onClick={() =>
                  openRelevantLeadsModal(row.rejected_proposal_leads_ids)
                }
              />
            ),
          },
        ]
      : []),
  ];

  const Child = () => (
    <DataTable
      noHeader
      noTableHead
      data={data?.agents || []}
      columns={agentColumns}
      className="cards_table"
      progressPending={loading}
      progressComponent={<Loader />}
    />
  );

  return showChart ? (
    <CRMReportBarChart loading={loading} chartData={chartData} />
  ) : (
    <DataTable
      noHeader
      data={data?.total ? [data.total] : []}
      columns={totalColumns}
      className="cards_table expandable"
      progressPending={loading}
      progressComponent={<Loader />}
      expandableRows
      expandableRowExpanded={() => true}
      expandableRowDisabled={() => true}
      expandableRowsComponent={<Child />}
    />
  );
};

/**
 *
 * End of `Sub Status Report`
 *
 * Start of `View Cell`
 *
 */

export const ViewCell = ({
  viewLeads,
  ignoreClickableIfZero,
  num,
  onClick,
}) => {
  const canView = viewLeads
    ? HelperFns.checkPrivileges({
        privileges: [Privileges.VIEW_LEADS, Privileges.SUPER_PRIVILEGE],
      })
    : true;
  const isClickable =
    canView &&
    (ignoreClickableIfZero ? +num?.split(" ")?.[0] : true) &&
    Boolean(onClick);

  return (
    <Box
      {...(isClickable && {
        onClick,
        sx: {
          "&:hover": {
            cursor: "pointer",
            color: "#23aaeb",
            textDecoration: "underline",
          },
        },
      })}
    >
      {num}
    </Box>
  );
};
