import React from "react";
import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { gql } from "@apollo/client";

import Loader from "../Loader";
import MainModal from "../MainModal";
import { SystemPrivilegesTree } from "../PaymentTracking";
import { onFormResetAction } from "../../Store/Actions";

export const viewPlanPrivilegesQuery = gql`
  query viewPlanPrivileges($id: ID) {
    system_privileges
    package: plan(id: $id) {
      id
      name
      privileges {
        id
      }
      trial_value
      features {
        id
        pivot {
          limits
        }
      }
    }
  }
`;

const ViewPackagePrivileges = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  // Server State
  const { data, loading } = useQuery(viewPlanPrivilegesQuery, {
    variables: { id },
    onCompleted: (res) => {
      dispatch(
        onFormResetAction("systemPlan", {
          id: res?.package?.id,
          name: res?.package?.name,
          features: res?.package?.features?.map((feat) => ({
            id: feat.id,
            limit: feat?.pivot?.limits,
          })),
          trial_value: res?.package?.trial_value,
          is_free: res?.package?.trial_value == null ? 1 : 0,
          selectedPrivileges: res?.package?.privileges?.map(
            (privilege) => privilege?.id
          ),
        })
      );
    },
  });

  return (
    <MainModal
      isOpen
      hasModalFooter={false}
      toggle={props.onClose}
      modalTitle={props?.data?.title}
      className="manage_privilages_modal_wrapper_style"
    >
      {loading ? (
        <Loader />
      ) : (
        <SystemPrivilegesTree
          edit
          parents={JSON.parse(data?.system_privileges)}
        />
      )}
    </MainModal>
  );
};

export default ViewPackagePrivileges;
