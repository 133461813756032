import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { InputForm, DateTimePickerForm, CheckboxBooleanForm, BSelect, RadioboxForm } from "form-builder";
import Dropzone from "react-dropzone-uploader";
import IntlTelInput from "react-intl-tel-input";

// utils
import CONST from "../../../Constants";
import { onInputChangeAction } from "../../../Store/Actions";

// Icons
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Avatar from "@mui/material/Avatar";
import { VpnKeyOutlined } from "@mui/icons-material";
import moment from "moment";
import HelperFns from "../../../Helpers/HelperFns";
import Constants from "../../../Constants";

const PersonalInfoSection = ({ FormProps, formSubmitting }) => {
	const { t } = useTranslation();

	// Redux Selectors
	const dispatch = useDispatch();
	const face_path = useSelector((state) => state.super?.[FormProps?.formName]?.face_path);
	const user_image = useSelector((state) => state.super?.[FormProps?.formName]?.user_image);
	const date_of_birth_month = useSelector((state) => state.super?.[FormProps?.formName]?.date_of_birth_month);
	const has_credentials = useSelector((state) => state.super?.[FormProps?.formName]?.has_credentials);
	const phone_country_code = useSelector((state) => state.super?.[FormProps?.formName]?.phone_country_code);
	const phone = useSelector((state) => state.super?.[FormProps?.formName]?.phone);
	const empServerValidation = useSelector((state) => state?.super?.[FormProps?.formServerValidation]);

	const handleImageChange = ({ file }, status) => {
		if (status == "done") {
			let e = {
				target: {
					value: file,
					name: "user_image",
				},
			};
			dispatch(onInputChangeAction(FormProps?.formName, e));
		}

		if (status == "removed") {
			let e = {
				target: {
					value: null,
					name: "user_image",
				},
			};
			dispatch(onInputChangeAction(FormProps?.formName, e));
		}
		return;
	};

	const handleRemoveImage = () => {
		let e = {
			target: {
				value: null,
				name: "face_path",
			},
		};
		dispatch(onInputChangeAction(FormProps?.formName, e));
	};

	const handleUpdateCountryCode = (currentNumber, seletedCountryData, fullNumber, isValid) => {
		let e = {
			target: {
				value: seletedCountryData?.iso2,
				name: "phone_country_code",
			},
		};
		dispatch(onInputChangeAction(FormProps?.formName, e));
	};

	const handlePhoneChange = (isValid, value, seletedCountryData, fullNumber, extension, event) => {
		let e = {
			target: {
				value,
				name: "phone",
			},
		};

		dispatch(onInputChangeAction(FormProps?.formName, e));

		let eCode = {
			target: {
				value: seletedCountryData.iso2,
				name: "phone_country_code",
			},
		};
		dispatch(onInputChangeAction(FormProps?.formName, eCode));
	};

	return (
		<>
			<div className="boxContainer">
				<div className="name_container">
					<PersonOutlineIcon />
					<h4 className="sub-title-style mb-0">{t("personal info")}</h4>
				</div>
				<hr />

				<div className="row mt-3 mb-2 align-items-baseline fix-uploader-height">
					<div className="col-lg-4 col-xl-2 mb-lg-0 mb-2">
						<label className="mb-2 text-capitalize">{t("facial recognition image")}</label>

						{Boolean(face_path) ? (
							<div className="d-flex flex-column align-items-center justify-content-center">
								<Avatar src={face_path} className="avatar blackish-avatar employee_avatar"></Avatar>
								<button onClick={handleRemoveImage} className="mt-2 btn-danger">
									{t("remove")}
								</button>
							</div>
						) : (
							<div className="upload_image_zone">
								<Dropzone
									onChangeStatus={handleImageChange}
									// onSubmit={handleImageSubmit}
									canRemove
									accept="image/*"
									name="user_image"
									multiple={false}
									maxFiles={1}
									files={user_image}
									inputContent={t("face_placeholder")}
								/>
							</div>
						)}
					</div>

					<div
						className={`col-lg-8 col-xl-9 mb-lg-0 mb-2 row input_fields_container ${face_path && "mt-2"}`}
					>
						<div className="col-12 col-md-6 mt-3 input_field">
							<InputForm
								{...FormProps}
								label={t("name")}
								name="name"
								placeholder={t("name")}
								type="text"
								validateBy="textRequired"
								labelStyle="mb-2"
								containerStyle=" "
								inputContainerStyle=" "
								validationName="input.user_input.name"
								icon="person"
							/>
						</div>

						<div className="col-12 col-md-6 mt-3 input_field">
							<label className="select-def-label validity-label-style mb-2">{t("date of birth")}</label>

							<div className="d-flex align-items-center">
								<BSelect
									{...FormProps}
									name="date_of_birth_year"
									keepDefaultStyle
									placeholder={t("year")}
									options={HelperFns.generateYears(1900, parseInt(moment().format("YYYY")))}
									rootStyle="w-100 mr-2"
									containerStyle=" "
									optionLabel="label"
									optionValue="value"
									isClearable
								/>
								<BSelect
									{...FormProps}
									name="date_of_birth_month"
									keepDefaultStyle
									placeholder={t("month")}
									options={Constants.MonthsData}
									rootStyle="w-100 mr-2"
									containerStyle=" "
									optionLabel="value"
									optionValue="key"
									isClearable
								/>
								<BSelect
									{...FormProps}
									name="date_of_birth_day"
									keepDefaultStyle
									placeholder={t("day")}
									options={
										!!date_of_birth_month
											? HelperFns.getMonthDaysOptions(date_of_birth_month)
											: []
									}
									rootStyle="w-100"
									containerStyle=" "
									optionLabel="value"
									optionValue="key"
									isClearable
								/>
							</div>
						</div>

						<div className="col-12 col-md-6 mt-3 input_field">
							<RadioboxForm
								{...FormProps}
								options={CONST.genderOptions}
								label="gender"
								labelStyle="mb-2"
								name="gender"
								validationName="input.user_input.gender"
								type="radio"
								optionItemStyle="mr-3"
								optionInputStyle=" "
							/>
						</div>

						<div className="col-12 col-md-6 mt-3 input_field">
							<BSelect
								{...FormProps}
								label="marital status"
								labelStyle="mb-2"
								name="marital_status"
								inputContainerStyle="w-100"
								placeholder={t("select type")}
								options={CONST.maritalStatusOptions}
								optionLabel="label"
								optionValue="value"
							/>
						</div>

						<div className="col-12 col-md-6 mt-3 input_field">
							<DateTimePickerForm
								{...FormProps}
								label={t("start date")}
								name="starts_at"
								validateBy="textRequired"
								labelStyle="mb-2"
								containerStyle=" "
								inputContainerStyle=" "
								placeholder={t("select date")}
								validationName="input.user_input.starts_at"
								requestFormat="YYYY-MM-DD"
							/>
						</div>

						<div className="col-12 col-md-6 mt-3 input_field">
							<DateTimePickerForm
								{...FormProps}
								label="joining date"
								name="joining_date"
								labelStyle="mb-2"
								containerStyle=" "
								inputContainerStyle=" "
								placeholder={t("joining date")}
								validationName="input.user_input.joining_date"
								requestFormat="YYYY-MM-DD"
								isClearable
							/>
						</div>

						<div className="align-self-end col-12 col-md-6 input_field mb-2 mt-3">
							<CheckboxBooleanForm
								{...FormProps}
								name="has_credentials"
								options={[t("Employee can login to their account")]}
								optionLabelStyle="content-header-sub-label"
								containerStyle="mt-3"
								validationName="input.user_input.has_credentials"
							/>
						</div>

						{has_credentials === 1 && (
							<>
								<div className="col-12 col-md-6 mb-2 input_field mt-3">
									<InputForm
										{...FormProps}
										label={t("email")}
										name="email"
										placeholder={t("email")}
										type="email"
										validateBy="email"
										labelStyle="mb-2"
										containerStyle=" "
										inputContainerStyle=" "
										validationName="input.user_input.email"
										icon="mail"
									/>
								</div>

								<div className="col-12 col-md-6 mt-3 input_field">
									<div
										className={
											formSubmitting && empServerValidation && empServerValidation?.["input.user_input.phone"]
												? "invalid-container-style"
												: " "
										}
									>
										<label className="validity-label-style employee-form-phone-label-style">{t("phone")}</label>
										<div dir="ltr">
											<IntlTelInput
												inputClassName="input-style-default"
												style={{ display: "flex" }}
												fieldName={"phone"}
												telInputProps={{
													dir: "ltr",
												}}
												preferredCountries={["eg", "ae", "sa"]}
												onPhoneNumberChange={(isValid, value, seletedCountryData, fullNumber, extension, event) => {
													handlePhoneChange(isValid, value, seletedCountryData, fullNumber, extension, event);
												}}
												onSelectFlag={(currentNumber, seletedCountryData, fullNumber, isValid) => {
													handleUpdateCountryCode(currentNumber, seletedCountryData, fullNumber, isValid);
												}}
												defaultCountry={phone_country_code?.toLowerCase()}
												separateDialCode
												format={false}
												formatOnInit={false}
												value={phone}
											/>
										</div>
										<div className="validity-msg-style">
											{empServerValidation && empServerValidation?.["input.user_input.phone"]
												? empServerValidation?.["input.user_input.phone"]
												: null}
										</div>
									</div>
								</div>
							</>
						)}

						<div className="col-12 col-md-6 mt-3 input_field">
							<InputForm
								{...FormProps}
								placeholder={t("enter code")}
								name="emp_code"
								label={t("employee code")}
								labelStyle="mb-2"
								inputContainerStyle="w-100 emp_code_input"
								validationName="input.user_input.emp_code"
								icon={<VpnKeyOutlined />}
							/>
						</div>

						<div className="col-md-6 input_field">
							{/* Start of probation section */}
							<div className="d-flex flex-column mt-3">
								<div className="col-12 col-xl-4 px-0">
									<CheckboxBooleanForm
										{...FormProps}
										name="probation_period"
										options={[t("Probation Period")]}
										optionLabelStyle="content-header-sub-label"
										validationName="input.user_input.probation_period"
										containerStyle="pt-1"
									/>
								</div>

								<div className="d-flex align-items-center mt-1">
									<div className="col-9 col-xl-6 px-0">
										<InputForm
											{...FormProps}
											placeholder={t("Enter Period")}
											name="value"
											type="number"
											validateBy="textRequired"
											inputContainerStyle="probation_input_style ml-0 w-100"
											dependOn="probation_period"
											dependancyType="equal"
											dependancyValue={[1]}
											validationName="input.user_input.probation_period.value"
											icon={<HourglassEmptyIcon />}
										/>
									</div>

									<div className="col-3 col-xl-3 px-0" style={{ marginTop: "1px" }}>
										<BSelect
											{...FormProps}
											name="type"
											inputContainerStyle="w-100"
											placeholder={t("select type")}
											options={CONST?.probationPeriodTypeOptions}
											optionLabel="label"
											optionValue="type"
											validateBy="textRequired"
											dependOn="probation_period"
											dependancyType="equal"
											dependancyValue={[1]}
											validationName="input.user_input.probation_period.type"
										/>
									</div>
								</div>
							</div>
							{/* End of probation section */}
						</div>
						<div className="col-md-6 input_field mt-3">
							<CheckboxBooleanForm
								{...FormProps}
								name="display_roster"
								options={[t("Allow this employee to sign-in using the office roster account")]}
								optionLabelStyle="content-header-sub-label"
								validationName="input.user_input.probation_period"
								containerStyle="pt-1"
								dependOn="id"
								dependancyType="greater"
								dependancyValue={[null, undefined,""]}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PersonalInfoSection;
