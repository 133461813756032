import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainModal from "../../../Components/MainModal";
import { BSelect } from "form-builder";
import { useMutation, useQuery } from "@apollo/client";
import { EXCHANGE_ITEM, UPSERT_ASSET_TYPE } from "../../../Graphql/mutation";
import {
  onFormResetAction,
  toggleExchangeItemModal,
} from "../../../Store/Actions";
import {
  showToast,
  handleFilterOptionByEmpCodeOrName,
} from "../../../Helpers/HelperFns";
import { GET_EXCHANGE_EMPLOYEES_MENU } from "../../../Graphql/query";

const formName = "exchangeItemForm";
const formNameValidation = "exchangeItemClientValidations";

const ExchangeItemModal = ({ handleRefetch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: employeesQueryData, loading: employeesLoading } = useQuery(
    GET_EXCHANGE_EMPLOYEES_MENU
  );

  const [formSubmitting, setFormSubmitting] = useState(false);

  const isOpen = useSelector(
    (state) => state?.assets?.exchangeItemModal?.isOpen
  );

  const itemId = useSelector(
    (state) => state?.assets?.exchangeItemModal?.itemId
  );

  const formData = useSelector((state) => state?.assets?.[formName]);
  const clientValidations = useSelector(
    (state) => state?.assets?.[formNameValidation]
  );

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
    };
  }, []);

  const [attemptExchangeItem, { loading }] = useMutation(EXCHANGE_ITEM, {
    onCompleted: (data) => {
      if (data?.changeAssignOrder?.__typename == "GeneralException") {
        showToast("error", data?.changeAssignOrder?.message);

        return;
      }
      dispatch(toggleExchangeItemModal({ isOpen: false, itemId: null }));
      handleRefetch();
    },
    onError(err) {
      showToast(
        "error",
        err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
      );
    },
  });

  const handleSubmit = () => {
    setFormSubmitting(true);

    if (!clientValidations?.length) {
      attemptExchangeItem({
        variables: {
          input: {
            item_id: itemId,
            employee_id: formData?.employeeId,
          },
        },
      });
    }
  };

  const dismiss = () => {
    dispatch(toggleExchangeItemModal({ isOpen: false, itemId: null }));
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={dismiss}
      modalTitle={t("transfer assets")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
    >
      <BSelect
        formName={formName}
        formNameValidation={formNameValidation}
        formSubmitting={formSubmitting}
        reducer="assets"
        validateBy="textRequired"
        name="employeeId"
        label="employee"
        labelStyle="mb-2"
        keepDefaultStyle
        isLoading={employeesLoading}
        options={employeesQueryData?.users_by_role?.data ?? []}
        filterOption={handleFilterOptionByEmpCodeOrName}
        inputContainerStyle=""
        placeholder={t("Select employee")}
        icon="location"
      />
    </MainModal>
  );
};

export default ExchangeItemModal;
