import React from "react";

import HelperFns from "../../../Helpers/HelperFns";
import Privileges from "../../../Constants/Privilages";

import { Box, Tab } from "@mui/material";
import PipelineReport from "./PipelineReport";
import AgentActionsReport from "./AgentActionsReport";
import AgentPipelineReport from "./AgentPipelineReport";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LeadsBreakdownReport from "./LeadsBreakdownReport";
import AgentActivitiesReport from "./AgentActivitiesReport";
import AgentLeadsStatusReport from "./AgentLeadsStatusReport";
import AgentOverdueActivitiesReport from "./AgentOverdueActivitiesReport";

const checkPrivileges = ({ privileges }) =>
  !privileges?.length || HelperFns.checkPrivileges({ privileges });
const reports = [
  {
    value: "1",
    label: "Leads Breakdown",
    Panel: LeadsBreakdownReport,
    privileges: [Privileges.VIEW_OTHER_CRM_REPORTS],
  },
  {
    value: "2",
    label: "Pipeline",
    Panel: PipelineReport,
    privileges: [Privileges.VIEW_OTHER_CRM_REPORTS],
  },
  {
    value: "3",
    label: "Agent Pipeline",
    Panel: AgentPipelineReport,
    privileges: [],
  },
  {
    value: "4",
    label: "Agent Leads Status",
    Panel: AgentLeadsStatusReport,
    privileges: [],
  },
  {
    value: "5",
    label: "Agent Activities",
    Panel: AgentActivitiesReport,
    privileges: [],
  },
  {
    value: "6",
    label: "Agent Overdue Activities",
    Panel: AgentOverdueActivitiesReport,
    privileges: [],
  },
  {
    value: "7",
    label: "Agent Actions",
    Panel: AgentActionsReport,
    privileges: [],
  },
];

const Reports = () => {
  // Local State
  const [value, setValue] = React.useState(getInitialValue());

  /* ↓ Helpers ↓ */

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  function getInitialValue() {
    for (let i = 0; i < reports.length; i++) {
      const report = reports[i];
      if (checkPrivileges({ privileges: report.privileges })) {
        return report.value;
      }
    }
  }

  return (
    <TabContext value={value}>
      {/* Tabs */}
      <Box sx={{ mt: 3, borderBottom: 1, borderColor: "divider" }}>
        <TabList variant="scrollable" onChange={handleChange}>
          {reports.map((report) =>
            checkPrivileges({ privileges: report.privileges }) ? (
              <Tab
                key={report.value}
                label={report.label}
                value={report.value}
              />
            ) : null
          )}
        </TabList>
      </Box>

      {/* Panels */}
      {reports.map((report) =>
        checkPrivileges({ privileges: report.privileges }) ? (
          <TabPanel key={report.value} value={report.value} sx={{ p: 1 }}>
            <report.Panel />
          </TabPanel>
        ) : (
          []
        )
      )}
    </TabContext>
  );
};

export default Reports;
