import HelperFns from "../../Helpers/HelperFns";
import Types from "../Actions/types";

export const initState = {
  systemPlanActions: {
    isVissible: false,
    isLoading: false,
    message: "",
  },

  systemPlan: {
    id: null,
    name: "",
    features: [
      { id: "1", limit: null },
      { id: "2", limit: null },
      { id: "5", limit: null },
      { id: "6", limit: null },
    ],
    trial_value: null,
    is_free: 0,
    selectedPrivileges: [],
  },
  systemPlanValidation: [],
  systemPlanServerValidation: {},

  system_privileges: null,

  newPlanRoles: [
    {
      name: "manger",
      id: "1",
    },
  ],
  loadingNewPlanRoles: false,

  roleForm: {
    id: null,
    name: "",
    managed: [],
    all: [],
    main: [],
  },
  roleFormValidation: [],
  roleServerValidation: {},

  plan_privileges: null,

  loadingPlanPrivileges: false,

  systemRolePlanActions: {
    isVissible: false,
    isEditVissible: false,
    isLoading: false,
    message: "",
  },

  fetchEditRole: false,

  loadingEditPlan: false,

  viewedPlan: null,
  loadingPlanPage: false,

  viewPlanPrivilegesModal: {
    isVissible: false,
  },

  adminHolidaysList: {
    year: new Date().getFullYear(),
    name: "",
    country_id: null,
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },

  holidaysCountriesFilterOptions: [],

  adminHolidaysModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  adminHolidaysModal: {
    name: "",
    start_date: "",
    end_date: "",
    countries_ids: [],
  },
  adminHolidaysModalValidation: [],
  adminHolidaysModalServerValidation: {},

  deleteAdminHolidaysLoading: false,

  isGenerateDemoLoading: false,

  taxFormClientValidations: [],
};

export const adminReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.FETCH_ADMIN_HOLIDAYS_LIST_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
        },
      };
    case Types.FETCH_ADMIN_HOLIDAYS_LIST_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          data: action?.payload?.list?.data,
          pagination: action?.payload?.list?.paginatorInfo,
          isLoading: false,
        },
      };
    case Types.FETCH_ADMIN_HOLIDAYS_LIST_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
        },
      };

    case Types.RESET_SYSTEM_PLAN_FORM:
      return {
        ...state,
        systemPlanActions: {
          ...initState.systemPlanActions,
        },
        systemPlan: {
          ...initState.systemPlan,
          features: [
            { id: "1", limit: null },
            { id: "2", limit: null },
            { id: "5", limit: null },
            { id: "6", limit: null },
          ],
        },
        system_privileges: null,
        systemPlanServerValidation: {},
        systemPlanValidation: [],
      };

    case Types.UPDTAE_PLAN_FETAURE_INPUT:
      let modifiedFeatures = [...state.systemPlan.features];
      modifiedFeatures[action?.payload?.index].limit =
        action.payload.event.target.value == "unlimted"
          ? null
          : action.payload.event.target.value;

      return {
        ...state,
        systemPlan: { ...state.systemPlan, features: [...modifiedFeatures] },
      };

    case Types.UPDATE_PRIVILEGES_RADIO:
      if (state[action.payload.formName]) {
        switch (action.payload?.name) {
          case "all":
            return {
              ...state,
              [action.payload.formName]: {
                ...state[action.payload.formName],
                managed: state[action.payload.formName]?.managed?.filter(
                  (val) => val != action.payload?.value
                ),
                all: [
                  ...state[action.payload.formName]?.all,
                  action.payload?.value,
                ],
              },
            };
          case "managed":
            return {
              ...state,
              [action.payload.formName]: {
                ...state[action.payload.formName],
                all: state[action.payload.formName]?.all?.filter(
                  (val) => val != action.payload?.value
                ),
                managed: [
                  ...state[action.payload.formName]?.managed,
                  action.payload?.value,
                ],
              },
            };
          default:
            return {
              ...state,
              [action.payload.formName]: {
                ...state[action.payload.formName],
                all: state[action.payload.formName]?.all?.filter(
                  (item) => !action.payload?.value.includes(+item)
                ),
                managed: state[action.payload.formName]?.managed?.filter(
                  (item) => !action.payload?.value.includes(+item)
                ),
              },
            };
        }
      }
      return state;

    case Types.FETCH_PLAN_PRIVILEGES_ATTEMPT:
      return {
        ...state,
        loadingPlanPrivileges: true,
      };

    case Types.FETCH_PLAN_PRIVILEGES_SUCCESS:
      let mainPrivileges = HelperFns.getAllBasicPrivileges(
        JSON.parse(action.payload)
      );
      return {
        ...state,
        roleForm: {
          ...state.roleForm,
          main: [...mainPrivileges],
        },
        loadingPlanPrivileges: false,
        plan_privileges: action.payload,
      };

    case Types.FETCH_ROLE_PRESET_PRIVILEGS_SUCCESS:
      return {
        ...state,
        roleForm: {
          ...state.roleForm,
          managed: action?.payload?.managed,
          all: action?.payload?.all,
          main: action?.payload?.main,
        },
      };
    case Types.FETCH_PLAN_PRIVILEGES_FAILED:
      return {
        ...state,
        loadingPlanPrivileges: false,
        plan_privileges: null,
      };

    case Types.FETCH_EDIT_ROLE_SUCCESS:
      return {
        ...state,
        fetchEditRole: false,
        systemRolePlanActions: {
          ...state.systemRolePlanActions,
          isEditVissible: true,
        },
        plan_privileges: action?.payload?.plan_privileges,
        roleForm: { ...initState.roleForm, ...action.payload?.role },
      };

    case Types.FETCH_ADMIN_HOLIDAYS_COUNTRIES_OPTIONS:
      return {
        ...state,
        holidaysCountriesFilterOptions: action?.payload,
      };

    case Types.SHOW_ADMIN_HOLIDAYS_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data,
        },
      };
    case Types.HIDE_ADMIN_HOLIDAYS_MODAL:
      return {
        ...state,
        adminHolidaysModalServerValidation: {},
        adminHolidaysModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        adminHolidaysModal: initState.adminHolidaysModal,
      };

    case Types.UPSERT_ADMIN_HOLIDAYS_ATTEMPT:
      return {
        ...state,
        adminHolidaysModalServerValidation: {},
        adminHolidaysModalActions: {
          ...state.adminHolidaysModalActions,
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.UPSERT_ADMIN_HOLIDAYS_SUCCESS:
      return {
        ...state,
        adminHolidaysModalServerValidation: {},
        adminHolidaysModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        adminHolidaysModal: initState.adminHolidaysModal,
      };
    case Types.UPSERT_ADMIN_HOLIDAYS_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          modalMessage: action.payload?.modalMessage ?? "",
        },
        [action.payload.serverRef]: action.payload?.errors ?? {},
      };

    case Types.DELETE_ADMIN_HOLIDAYS_ATTEMPT:
      return {
        ...state,
        deleteAdminHolidaysLoading: true,
      };
    case Types.DELETE_ADMIN_HOLIDAYS_SUCCESS:
    case Types.DELETE_ADMIN_HOLIDAYS_FAILED:
      return {
        ...state,
        deleteAdminHolidaysLoading: false,
      };

    case Types.GENERATE_DEMO_ATTEMPT:
      return {
        ...state,
        isGenerateDemoLoading: true,
      };
    case Types.GENERATE_DEMO_SUCCESS:
    case Types.GENERATE_DEMO_FAILED:
      return {
        ...state,
        isGenerateDemoLoading: false,
      };

    case Types.LOG_OUT:
    case "SWITCH_ACCOUNT":
      return { ...initState };

    default:
      return state;
  }
};
