import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import {
  upsertRoleMutation,
  upsertRoleTemplateToPackageMutation,
} from "../../Graphql/mutation/PaymentTracking";
import { connect, useDispatch } from "react-redux";
import { showToast } from "../../Helpers/HelperFns";
import { rolePackagesFormQuery } from "../../Graphql/query/PaymentTracking";
import { onFormResetAction, fetchEditRoleSuccess } from "../../Store/Actions";

import Loader from "../Loader";
import MainModal from "../MainModal";
import { InputForm } from "../../Builder/Form";
import RolePrivilegesTreeView from "../RolePrivilegesTreeView";

const formName = "roleForm";
const formNameValidation = "roleFormValidation";
const formServerValidation = "roleServerValidation";

const UpsertPackageRoleModal = ({ data, refetch, onClose, ...props }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Constants
  const roleId = data?.id || props.managePrivilagesModal.id;
  const isEdit = Boolean(roleId);
  const formProps = {
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  // Server State
  const [upsert, { loading: upsertLoading }] = useMutation(
    props?.isPackageProfile
      ? upsertRoleTemplateToPackageMutation
      : upsertRoleMutation
  );
  const { loading } = useQuery(rolePackagesFormQuery, {
    variables: {
      roleId,
      packageId: id,
      includeRole: isEdit,
    },
    onCompleted: ({ plan_privileges, role }) => {
      props.fetchEditRoleSuccess(plan_privileges, role || {});
    },
    onError: () => {
      showToast("error");
    },
  });

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const handleSubmitBtn = () => {
    setFormSubmitting(true);
    if (props.roleFormValidation.length) {
      return;
    }

    upsert({
      variables: {
        input: {
          plan_id: id,
          id: roleId,
          name: props.managePrivilagesModal.name,
          privileges: props.managePrivilagesModal.main,
          PrivilegesWithScopeAll: props.managePrivilagesModal.all,
          PrivilegesWithScopeManaged: props.managePrivilagesModal.managed,
        },
      },
      onCompleted: () => {
        refetch();
        toggleModal();
        showToast("success");
      },
      onError: (err) => {
        if (err?.graphQLErrors?.[0]?.extensions?.validation) {
          props?.onFormResetAction(
            "roleServerValidation",
            err?.graphQLErrors?.[0]?.extensions?.validation
          );
        } else {
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
        }
      },
    });
  };

  const toggleModal = () => {
    onClose();
    setFormSubmitting(false);
    dispatch(onFormResetAction("roleForm"));
    dispatch(onFormResetAction("roleFormValidation"));
    dispatch(onFormResetAction("roleServerValidation"));
  };

  if (props?.loadingPlanPrivileges) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }
  return (
    <MainModal
      isOpen
      btnLabelCancel="Cancel"
      btnOnCancelClick={toggleModal}
      modalTitle={isEdit ? t("edit Role Privileges") : t("Role Privileges")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={upsertLoading}
      className="manage_privilages_modal_wrapper_style"
    >
      {loading ? <Loader fixed /> : null}

      <div>
        <InputForm
          {...formProps}
          name="name"
          reducer="admin"
          inputContainerStyle="w-100"
          validateBy="textRequired"
          placeholder={t("name")}
          validationName="input.name"
        />
        <RolePrivilegesTreeView parents={JSON.parse(props.plan_privileges)} />
        <div className="warnig-msg-style">
          {props?.roleServerValidation?.["input.privileges"]?.toString() ?? ""}
          <br />
          {props?.roleServerValidation?.["input.plan_id"]?.toString() ?? ""}
        </div>

        {/* (Start) Error Message */}
        {props?.modalMessage && formSubmitting && (
          <p role="alert" className="warnig-msg-style mb-0">
            {props?.modalMessage}
          </p>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  managePrivilagesModal: state.admin[formName],
  roleFormValidation: state.admin?.roleFormValidation,
  roleServerValidation: state.admin?.roleServerValidation,
  modalMessage: state.admin.systemRolePlanActions?.modalMessage,
  loadingPlanPrivileges: state.admin.loadingPlanPrivileges,
  plan_privileges: state.admin?.plan_privileges,
});

export default connect(mapStateToProps, {
  onFormResetAction,
  fetchEditRoleSuccess,
})(UpsertPackageRoleModal);
