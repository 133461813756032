import React, { useEffect } from "react";
import MainModal from "../../Components/MainModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { Alert, AlertTitle } from "@mui/material";

import RequestLimitConfig from "../../Components/AttendanceTypeModal/RequestLimitConfig";
import { useSelector } from "react-redux";
import { addRequestLimitSettingsForEmployeesMutation } from "../../Graphql/mutation";
import {
  ADDITIONAL_SHIFT,
  CHANGE_SHIFT,
  DAY_OFF,
} from "../../Constants/Requests";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { onFormResetAction } from "../../Store/Actions";

const FormProps = {
  formName: "requestLimitBulkConfig",
  reducer: "super",
};

const RequsetLimitConfigModal = (props) => {
  const { t } = useTranslation();

  const requestLimitBulkConfig = useSelector(
    (state) => state?.super?.requestLimitBulkConfig
  );

  // // add Work Request Limit Settings For Employees
  const [
    addRequestLimitSettingsForEmployees,
    { loading: submitLoading, submitError },
  ] = useMutation(addRequestLimitSettingsForEmployeesMutation, {
    onError: (err) => {
      if (err?.graphQLErrors[0]?.extensions?.validation) {
        let validation = err?.graphQLErrors[0]?.extensions?.validation;
        setValid({ isValid: false, msg: Object.values(validation).toString() });
      } else
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason || err.message
        );
    },
    onCompleted: (res) => {
      if (
        res?.addRequestSettingConfigurationForEmployees?.status === "success"
      ) {
        props.resetSelection();
        handleCancle();
      }
      showToast(
        res?.addRequestSettingConfigurationForEmployees?.status,
        res?.addRequestSettingConfigurationForEmployees.message
      );
    },
  });

  // validate state
  const [valid, setValid] = useState(true);

  const handleSave = () => {
    HelperFns.checkPassword("defaut_warning_messsage", "", "Confirm", "", () =>
      onsubmit()
    );
  };

  const onsubmit = () => {
    const request_configuration_settings = [
      requestLimitBulkConfig?.has_request_dayoff_limit && {
        request_type_id: +DAY_OFF,
        request_limits: Number(requestLimitBulkConfig?.dayoff_request_limits),
        period_type: requestLimitBulkConfig?.dayoff_period_type,
      },
      requestLimitBulkConfig?.has_request_change_shift_limit && {
        request_type_id: +CHANGE_SHIFT,
        request_limits: Number(
          requestLimitBulkConfig?.change_shift_request_limits
        ),
        period_type: requestLimitBulkConfig?.change_shift_period_type,
      },

      requestLimitBulkConfig?.has_request_additional_shift_limit && {
        request_type_id: +ADDITIONAL_SHIFT,
        request_limits: Number(
          requestLimitBulkConfig?.additional_shift_request_limits
        ),
        period_type: requestLimitBulkConfig?.additional_shift_period_type,
      },
    ].filter(Boolean);

    addRequestLimitSettingsForEmployees({
      variables: {
        input: {
          request_configuration_settings,
          employees_ids: props.selectedEmployees,
        },
      },
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onFormResetAction("requestLimitBulkConfig"));
    return () => {
      setValid({
        isValid: true,
        msg: null,
      });
    };
  }, [props.requestLimitConfigModal?.isOpen]);

  const handleCancle = () => {
    props.closeRequestLimitConfigModal();
  };

  return (
    <MainModal
      isOpen={props?.requestLimitConfigModal?.isOpen}
      size="lg"
      modalTitle={t("Bulk Actions")}
      btnSubmitLoading={submitLoading}
      btnOnClick={handleSave}
      toggle={handleCancle}
      className="p-5 "
    >
      <div className="p-2">
        <Alert severity="info">
          <AlertTitle>{t("Change The Remote work settings")}</AlertTitle>
          <strong>
            {t("Employees will be effected", {
              count: props.selectedEmployees.length,
            })}
          </strong>
        </Alert>
      </div>

      <div>
        <RequestLimitConfig FormProps={FormProps} />
        <div
          className={"pt-3 " + (valid.isValid ? "" : "invalid-container-style")}
        >
          <div className="validity-msg-style text-left">{t(valid.msg)}</div>
        </div>
      </div>
    </MainModal>
  );
};

export default RequsetLimitConfigModal;
