import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";

import {
  serializeEditPayment,
  serializeFetchPayments,
} from "../../Helpers/HelperFns/PaymentTracking";
import { EgyptId } from "../../Constants";
import { onFormResetAction } from "../../Store/Actions";
import { safeJsonParse, showToast } from "../../Helpers/HelperFns";
import { editPaymentFormQuery } from "../../Graphql/query/PaymentTracking";
import { editPaymentMutation } from "../../Graphql/mutation/PaymentTracking";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import Loader from "../Loader";
import MainModal from "../MainModal";
import { PaymentInfo } from "../PaymentTracking";

const reducer = "paymentTracking";
const formName = "editPaymentModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";
const formatData = (data) => {
  const firstItem = data?.firstItem
    ? safeJsonParse(safeJsonParse(data?.firstItem || "{}") || "{}")
    : null;

  return {
    id: data?.id,
    dueDate: data?.due_date,
    eInvoice: data?.eInvoice,
    amount: firstItem?.amount,
    paymentDetails: firstItem?.name,
    country: EgyptId,
    taxation: data?.tax?.type,
    taxAmount: data?.tax?.amount,
    applyTax: data?.tax?.isApplied,
    ...serializeFetchPayments(safeJsonParse(data?.method)),
  };
};

const EditPaymentModal = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const options = useSelector(
    (state) => state?.[reducer]?.paymentTrackingOptions
  );

  // Server State
  const [editPayment, { loading: editLoading }] =
    useMutation(editPaymentMutation);
  const { loading } = useQuery(editPaymentFormQuery, {
    variables: { id: data?.id },
    onCompleted: ({ payment = {}, countries = [] }) => {
      // Data
      dispatch(onFormResetAction(formName, formatData(payment)));

      // Options
      dispatch(
        onFormResetAction("paymentTrackingOptions", { ...options, countries })
      );
    },
  });

  // Constants
  const FormProps = {
    reducer,
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (formClientValidation.length) return;

    editPayment({
      variables: serializeEditPayment({ ...formData, id: data?.id }),
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
        dispatch(onFormResetAction(formServerValidation, validation || {}));

        const msg =
          validation?.json?.[0] ||
          err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message;
        setErrMsg(msg || "");
      },
    });
  };

  return (
    <MainModal
      isOpen
      modalTitle="Edit Payment"
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={editLoading}
    >
      {loading ? <Loader fixed /> : null}

      <InputForm
        {...FormProps}
        name="paymentDetails"
        label="Payment Details"
        icon="document"
        rootStyle="w-100 mb-2"
        containerStyle="w-100"
        validateBy="textRequired"
        isLoading={loading}
        disabled
      />

      <div className="d-flex gap-10">
        <BSelect
          isDisabled
          {...FormProps}
          label="country"
          name="country"
          icon="globe"
          validateBy="textRequired"
          rootStyle="flex-1"
          options={options.countries}
          isLoading={!options.countries.length}
          validationName="country_id"
        />
        <BSelect
          isDisabled
          {...FormProps}
          label="taxation"
          name="taxation"
          icon="document"
          validateBy="textRequired"
          rootStyle="flex-1"
          options={[
            { id: "Custom", name: "Custom" },
            { id: "Egyptian taxation", name: "Egyptian taxation" },
          ]}
          dependOn="country"
          dependancyType="equal"
          dependancyValue={[EgyptId]}
          validationName="taxation"
        />
      </div>
      <div className="d-flex align-items-end justify-content-between gap-10 my-2">
        <RadioboxForm
          {...FormProps}
          name="applyTax"
          options={[
            { label: "Apply VAT", value: 1 },
            { label: "Don't Apply VAT", value: 0 },
          ]}
          optionInputStyle=" "
          optionItemStyle=" "
          containerStyle=" "
          optionsContainerStyle="d-flex gap-20"
          rootStyle="flex-1"
        />
        {formData.taxation === "Custom" && formData.applyTax ? (
          <InputForm
            {...FormProps}
            name="taxAmount"
            placeholder="taxation percentage"
            labelStyle="mb-2 pt-1"
            containerStyle="w-100"
            rootStyle="flex-1"
            validateBy="textRequired"
            dependOn="applyTax"
            dependancyType="equal"
            dependancyValue={[1]}
          />
        ) : null}
      </div>

      <CheckboxBooleanForm
        {...FormProps}
        name="eInvoice"
        options={["E-invoices"]}
      />

      <InputForm
        {...FormProps}
        name="amount"
        label="Amount"
        icon="money"
        rootStyle="w-50 d-inline-block pr-2 mb-2"
        containerStyle="w-100"
        validateBy="textRequired"
        isLoading={loading}
        validationName="amount"
      />
      <DateTimePickerForm
        {...FormProps}
        name="dueDate"
        label="suspension date"
        hasIcon
        containerStyle="w-100"
        rootStyle="d-inline-block w-50 mb-2"
        isLoading={loading}
        validationName="due_date"
      />

      <PaymentInfo FormProps={FormProps} />

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {t(errMsg)}
        </p>
      ) : null}
    </MainModal>
  );
};

export default EditPaymentModal;
