import React from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  storeSubscriptionMutation,
  endCreateSubscriptionMutation,
} from "../../Graphql/mutation/PaymentTracking";
import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction } from "../../Store/Actions";
import { serializeUpsertSubscription } from "../../Helpers/HelperFns/PaymentTracking";

import MainModal from "../MainModal";
import { SubscriptionForm } from "../PaymentTracking";

const reducer = "paymentTracking";
const formName = "subscriptionForm";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";

const UpsertSubscriptionModal = ({ data, actions, onClose, refetchList }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [errorMsg, setErrorMsg] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Server State
  const [store, { loading: storeLoading }] = useMutation(
    storeSubscriptionMutation
  );
  const [endCreate, { loading: endCreateLoading }] = useMutation(
    endCreateSubscriptionMutation
  );

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const isInvalidClientValidation = () => {
    if (formClientValidation?.length) return true;

    if (formData.applyPromotion) {
      if (
        formData?.applyPromotionAll &&
        (!formData?.promotions?.[0]?.type || !formData?.promotions?.[0]?.amount)
      ) {
        setErrorMsg("all promotions fields are required");
        return true;
      }

      if (
        !formData?.applyPromotionAll &&
        formData?.promotions?.some((prom) => !prom?.type || !prom?.amount)
      ) {
        setErrorMsg("all promotions fields are required");
        return true;
      }
    }

    return false;
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (isInvalidClientValidation()) return;

    if (data?.isSubscriptionProfile) {
      const { company, country, taxation, eInvoice, ...rest } = formData;

      endCreate({
        variables: actions?.formatEndCreateData(rest),
        onCompleted: () => {
          history.push("/payment-tracking?tab=subscriptions");
        },
        onError: (err) => {
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
        },
      });
    } else {
      store({
        variables: serializeUpsertSubscription(formData),
        onCompleted: () => {
          refetchList();
          handleCloseModal();
          showToast("success");
        },
        onError: (err) => {
          const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
          dispatch(onFormResetAction(formServerValidation, validation || {}));

          const msg =
            validation?.json?.[0] ||
            err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message;
          setErrorMsg(msg || "");
        },
      });
    }
  };

  return (
    <MainModal
      isOpen
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={storeLoading || endCreateLoading}
      modalTitle="New Subscription"
    >
      <SubscriptionForm data={{ ...data, formSubmitting }} />
      {errorMsg ? (
        <p role="alert" className="error-color mt-2 mb-0">
          {t(errorMsg)}
        </p>
      ) : null}
    </MainModal>
  );
};

export default UpsertSubscriptionModal;
