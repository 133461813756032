import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import {
  BSelect,
  InputForm,
  DateTimePickerForm,
  RadioboxForm,
} from "form-builder";
import { connect } from "react-redux";
import {
  dismissModifyPettyCashBalanceModalAction,
  upsertPettyCashBalanceAction,
  fetchEmployeeCurrentBalanceAction,
  fetchEmployeeOfficeCurrencyInPettyCashAction,
  deletePettyCashAttachedFileAction,
  onInputResetAction,
  onFormResetAction,
  fetchPettyCashCurrenciesAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import Dropzone from "react-dropzone-uploader";
import Constants from "../../Helpers/Constants";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import swal from "sweetalert";
import {
  RemoveIconButton,
  DownloadFileIconButton,
} from "../IconButtonWithTooltip";
import {
  handleFilterOptionByEmpCodeOrName,
} from "../../Helpers/HelperFns";

const formName = "modifyPettyCashBalanceModal";
const formNameValidation = "modifyPettyCashBalanceModalValidation";
const formServerValidation = "modifyPettyCashBalanceModalServerValidation";

const ModifyPettyCashModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  // initail vlaue for requestFiles set to empty array
  const [requestFiles, setRequestFiles] = useState([]);

  // function to add object to requestFiles array
  const addRequestFileObject = (file) => {
    setRequestFiles((requestFiles) => [...requestFiles, file]);
  };

  // function to remove an object from an requestFiles array
  const removeRequestFileObject = (id) => {
    setRequestFiles((requestFiles) =>
      requestFiles.filter((file) => file?.id !== id)
    );
  };

  // handle uploader image change
  const handleImageChange = ({ file, meta }, status) => {
    // to convert image to base64 string
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let base64 = reader.result.split(",");
      let fileType = file?.type.split("/");

      if (status === "done") {
        let target = {
          file: base64[1],
          extension: fileType[1],
          id: meta?.id,
        };
        addRequestFileObject(target);
      }
    };

    if (status === "removed") {
      removeRequestFileObject(meta?.id);
    }
    return;
  };
  // Start of filtering current balance according to selected employee id
  const handleEmployeesCurrentFilter = () => {
    if (!isModalVissible) {
      return;
    }
    props.onInputResetAction(formName, "currency_id");

    props.fetchEmployeeCurrentBalanceAction({
      ref: formName,
      [formName]: {
        ...props.data[formName],
      },
    });

    // Start of filtering currency according to selected employee id and its office id
    // to filter by selected emp id and get this emp office id
    let selectedEmployeeOfficeCurrencies =
      props?.data?.pettyCashEmployees?.filter(
        (emp) => emp?.id === props?.modifyPettyCashBalanceModal?.emp_id
      )[0]?.office?.id;

    props.fetchEmployeeOfficeCurrencyInPettyCashAction({
      ref: formName,
      office_id: props.modifyPettyCashBalanceModal?.isUserProfile
        ? props?.employeeOfficeId
        : selectedEmployeeOfficeCurrencies,
      [formName]: {
        ...props.data[formName],
      },
    });
    // End of filtering currency according to selected employee id and its office id
  };

  const useDidEmployeesUpdate = useDidUpdateEffect(
    handleEmployeesCurrentFilter,
    [props.data[formName].emp_id]
  );
  // End of filtering current balance according to selected employee id

  // handle delete petty cash attached file
  const handleDeleteAttchment = (attachment_id) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deletePettyCashAttachedFileAction(
          attachment_id,
          props?.modifyPettyCashBalanceModal?.id
        );
      }
    });
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    // to send file and extenstion only without id key in the array
    let formAttachments = requestFiles?.map(({ file, extension }) => ({
      file,
      extension,
    }));

    props.upsertPettyCashBalanceAction({
      allData: {
        ...props?.modifyPettyCashBalanceModal,
        attachments: formAttachments,
      },
      ref: "modifyPettyCashBalanceModalActions",
    });
  };

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissModifyPettyCashBalanceModalAction({
      ref: "modifyPettyCashBalanceModalActions",
      formName,
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
    setRequestFiles([]);
    props.onFormResetAction("employeeCurrentBalanceData");
    return () => {};
  }, [isModalVissible]);

  useEffect(() => {
    let selectedEmployeeOfficeCurrencies =
      props?.data?.pettyCashEmployees?.filter(
        (emp) => emp?.id === props?.modifyPettyCashBalanceModal?.emp_id
      )[0]?.office?.id;

    props.fetchEmployeeOfficeCurrencyInPettyCashAction({
      ref: formName,
      office_id: props.modifyPettyCashBalanceModal?.isUserProfile
        ? props?.employeeOfficeId
        : selectedEmployeeOfficeCurrencies,
      [formName]: {
        ...props.data[formName],
      },
    });
  }, []);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t(
        `${
          props.modifyPettyCashBalanceModal?.id != null ? "Edit" : "Modify"
        } Petty Cash Balance`
      )}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="modify_petty_cash_form_wrapper_style"
    >
      {props?.pettyCashModalDataIsLoading ||
      props?.employeeCurrenciesInPettyCashLoading ||
      props?.isDeletePettyCashFileLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}
      <div>
        <div className="row mt-2">
          <div className="col-12">
            <RadioboxForm
              reducer="finances"
              {...FormProps}
              validateBy="textRequired"
              name="type"
              validationName={`input.type`}
              options={Constants.pettyCashTypes?.map(({ label, value }) => ({
                label: t(label),
                value,
              }))}
              type="radio"
              containerStyle="pb-2"
              labelStyle="label-style"
              optionsContainerStyle="optionsContainerStyle"
              optionItemStyle="optionItemStyle"
              optionInputStyle=" "
              optionLabelStyle="optionLabelStyle"
            />
          </div>
        </div>

        {props?.isEmployeePettyCash ||
        props.modifyPettyCashBalanceModal?.isUserProfile ? (
          ""
        ) : (
          <div className="row mt-2">
            <div className="col-12">
              <BSelect
                label="employee"
                labelStyle="mb-2"
                reducer="finances"
                {...FormProps}
                name="emp_id"
                validationName={`input.emp_id`}
                options={props?.data?.pettyCashEmployees}
                filterOption={handleFilterOptionByEmpCodeOrName}
                keepDefaultStyle
                optionLabel="name"
                optionValue="id"
                containerStyle="my-0 mb-2 mb-lg-0"
                isClearable
                placeholder={t("select_employee")}
                icon="person"
              />
            </div>
          </div>
        )}

        {props?.data?.employeeCurrentBalanceData?.get_total_employee_balance
          ?.length > 0 && props?.data?.employeeCurrentBalanceData != null ? (
          <div className="row mt-3 mb-0">
            <div className="col-12">
              <div>
                <h5 className="d-flex current_balance_style mb-0">
                  {t("current balance")}

                  {props?.data?.employeeCurrentBalanceData?.get_total_employee_balance?.map(
                    (currentBalance, i) => (
                      <p key={i} className="px-3">
                        <span className="px-1">
                          {currentBalance?.balance?.toFixed(2)}
                        </span>
                        {currentBalance?.symbol}
                      </p>
                    )
                  )}
                </h5>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row mt-2">
          <div className="col-12">
            <DateTimePickerForm
              label="date"
              dateTime
              labelStyle="mb-2"
              reducer="finances"
              {...FormProps}
              name="date"
              validationName={`input.date`}
              datePickerContainer="w-100"
              hasIcon
              disabled={
                props.modifyPettyCashBalanceModal?.id != null ? true : false
              }
              requestFormat="yyyy-MM-DD HH:mm:ss"
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="col-12 col-lg-6">
            <InputForm
              label="amount"
              labelStyle="mb-2"
              placeholder={t("amount")}
              reducer="finances"
              {...FormProps}
              name="amount"
              validationName={`input.amount`}
              containerStyle=""
              inputContainerStyle="w-100"
              type="number"
              icon="money"
            />
          </div>

          <div className="col-12 col-lg-6">
            <BSelect
              label="Currency"
              labelStyle="mb-2"
              reducer="finances"
              name="currency_id"
              {...FormProps}
              validationName={`input.currency_id`}
              options={props?.data?.employeePettyCashOfficeCurrency}
              keepDefaultStyle
              optionLabel="name"
              optionValue="id"
              isDisabled={
                props?.data?.employeePettyCashOfficeCurrency.length === 1
                  ? true
                  : false
              }
              placeholder={t("select currency")}
              icon="currency"
            />
          </div>
        </div>

        <div className="row">
          <div
            className={`col-12 mt-1 mb-2 ${
              props?.serverVaildation &&
              "input.files" in props?.serverVaildation
                ? "invalid-container-style"
                : ""
            }`}
          >
            <label className="mb-2 validity-label-style">
              {t("Attachments")}
            </label>

            <div className="attachment_wrapper_style">
              {props?.modifyPettyCashBalanceModal?.attachments?.map((file) => (
                <div className="d-flex my-2">
                  <DownloadFileIconButton key={file?.id} href={file?.path} />
                  <RemoveIconButton
                    onClick={() => handleDeleteAttchment(file?.id)}
                  />
                </div>
              ))}
            </div>

            <div className="documents_dropzone_style">
              <Dropzone
                onChangeStatus={handleImageChange}
                canRemove
                accept="image/*,.pdf,.doc,.docx,.xlsm,.xls,.xlsx"
                name="attachments"
                multiple={true}
                inputContent={t("face_placeholder")}
              />
            </div>

            <span className="validity-msg-style">
              {props?.serverVaildation &&
              "input.files" in props?.serverVaildation
                ? props?.serverVaildation["input.files"]
                : null}
            </span>
          </div>
        </div>

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.finances.modifyPettyCashBalanceModalActions,
  modalValidation: state.finances[formNameValidation],
  modifyPettyCashBalanceModal: state.finances[formName],
  serverVaildation: state.finances[formServerValidation],
  data: state.finances,
  pettyCashModalDataIsLoading: state?.finances?.employeeCurrentBalanceLoading,
  employeeCurrenciesInPettyCashLoading:
    state?.finances?.employeeCurrenciesInPettyCashLoading,
  isDeletePettyCashFileLoading: state?.finances?.deletePettyCashFileLoading,
});

export default connect(mapStateToProps, {
  dismissModifyPettyCashBalanceModalAction,
  upsertPettyCashBalanceAction,
  fetchEmployeeCurrentBalanceAction,
  fetchEmployeeOfficeCurrencyInPettyCashAction,
  deletePettyCashAttachedFileAction,
  onInputResetAction,
  onFormResetAction,
  fetchPettyCashCurrenciesAction,
})(ModifyPettyCashModal);
