import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import gql from "graphql-tag";
import HelperFns, {
  handleFilterOptionByEmpCodeOrName,
} from "../../Helpers/HelperFns";

import Box from "@mui/material/Box";
import Dropzone from "react-dropzone-uploader";
import MainModal from "../../Components/MainModal";
import { ColorButton } from "../../Components/Buttons";
import { RadioboxForm, BSelect } from "../../Builder/Form";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const GET_OPTIONS = gql`
  query getOptions {
    users: users_by_role(first: 9999, input: { status: ["Active"] }) {
      data {
        id
        name
        emp_code
      }
    }
    offices: company_offices(first: 999) {
      data {
        id
        name
      }
    }
    positions: company_positions(first: 9999) {
      data {
        id
        name
      }
    }
    departments: company_departments(first: 999) {
      data {
        id
        name
      }
    }
  }
`;

const DOWNLOAD_DEDUCTIONS_TEMPLATE = gql`
  mutation downloadDeductions(
    $userIds: [Int]
    $officeIds: [Int]
    $positionIds: [Int]
    $departmentIds: [Int]
  ) {
    download_deduction_template(
      userIds: $userIds
      officeIds: $officeIds
      positionIds: $positionIds
      departmentIds: $departmentIds
    )
  }
`;

const IMPORT_DEDUCTIONS_TEMPLATE = gql`
  mutation importDeductions($file: Upload) {
    import_deductions(file: $file)
  }
`;

const typesOptions = [
  { label: "employees", value: "users" },
  { label: "offices", value: "offices" },
  { label: "positions", value: "positions" },
  { label: "departments", value: "departments" },
];

const ImportDeductionsModal = ({ onClose, refetchList }) => {
  const { t } = useTranslation();

  // Local State
  const [file, setFile] = React.useState(null);
  const [filters, setFilters] = React.useState({
    users: [],
    offices: [],
    positions: [],
    departments: [],
    type: "users",
  });
  const [options, setOptions] = React.useState({
    users: [],
    offices: [],
    positions: [],
    departments: [],
  });

  // Server State
  const [downloadTemplate, { loading: downloadTemplateLoading }] = useMutation(
    DOWNLOAD_DEDUCTIONS_TEMPLATE
  );
  const [importTemplate, { loading: importLoading }] = useMutation(
    IMPORT_DEDUCTIONS_TEMPLATE
  );
  const { loading } = useQuery(GET_OPTIONS, {
    onCompleted: (res) => {
      setOptions((prev) => ({
        ...prev,
        users: res?.users?.data || [],
        offices: res?.offices?.data || [],
        positions: res?.positions?.data || [],
        departments: res?.departments?.data || [],
      }));
    },
    onError: (err) => {
      HelperFns.showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  /* ↓ Helpers ↓ */

  const handleCLoseModal = () => {
    onClose();
  };

  const handleTypeChange = (e) => {
    setFilters({
      users: [],
      offices: [],
      positions: [],
      departments: [],
      type: e?.target?.value,
    });
  };

  const handleSelectChange = (val, { name: key }) => {
    setFilters((prev) => ({ ...prev, [key]: val }));
  };

  const handleUploadChange = ({ file }, status) => {
    if (status === "done") setFile(file);
  };

  const handleDownload = () => {
    downloadTemplate({
      variables: {
        userIds: filters?.users?.length
          ? filters?.users?.map((user) => +user?.id)
          : null,
        officeIds: filters?.offices?.length
          ? filters?.offices?.map((office) => +office?.id)
          : null,
        positionIds: filters?.positions?.length
          ? filters?.positions?.map((position) => +position?.id)
          : null,
        departmentIds: filters?.departments?.length
          ? filters?.departments?.map((department) => +department?.id)
          : null,
      },
      onCompleted: ({ download_deduction_template }) => {
        HelperFns.downloadFile(download_deduction_template);
      },
      onError: (err) => {
        HelperFns.showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  const handleImport = () => {
    importTemplate({
      variables: { file },
      onCompleted: () => {
        refetchList();
        handleCLoseModal();
        HelperFns.showToast("success");
      },
      onError: (err) => {
        HelperFns.showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={handleCLoseModal}
      btnOnClick={handleImport}
      modalTitle="import deductions"
      className="documents_dropzone_style"
      disableSubmitButton={!file}
      btnSubmitLoading={importLoading}
    >
      <strong className="d-block">{t("template")}</strong>
      <p className="font-13 ">
        {t(
          "for a successful import, please make sure you are using this template when uploading your data"
        )}
      </p>

      <RadioboxForm
        value={filters.type}
        optionItemStyle=" "
        optionInputStyle=" "
        options={typesOptions}
        onChange={handleTypeChange}
        optionsContainerStyle="d-flex justify-content-between"
      />
      <Box
        className="d-flex flex-wrap mb-2"
        sx={{
          [`& > div:not(.${filters.type})`]: { display: "none" },
        }}
      >
        <BSelect
          isMulti
          icon="employees"
          name="users"
          placeholder={t("select employees")}
          rootStyle="flex-1 users"
          labelStyle="w-100"
          isLoading={loading}
          options={options?.users}
          filterOption={handleFilterOptionByEmpCodeOrName}
          value={filters?.users}
          onChange={handleSelectChange}
        />
        <BSelect
          isMulti
          icon="person"
          name="positions"
          placeholder={t("select positions")}
          rootStyle="flex-1 positions"
          labelStyle="w-100"
          isLoading={loading}
          options={options?.positions}
          value={filters?.positions}
          onChange={handleSelectChange}
        />
        <BSelect
          isMulti
          icon="departments"
          name="departments"
          placeholder={t("select departments")}
          rootStyle="flex-1 departments"
          labelStyle="w-100"
          isLoading={loading}
          options={options?.departments}
          value={filters?.departments}
          onChange={handleSelectChange}
        />
        <BSelect
          isMulti
          icon="offices"
          name="offices"
          placeholder={t("select offices")}
          rootStyle="flex-1 offices"
          labelStyle="w-100"
          isLoading={loading}
          options={options?.offices}
          value={filters?.offices}
          onChange={handleSelectChange}
        />
      </Box>

      <ColorButton
        loadingButton
        color="#23aaeb"
        className="mb-4"
        label="download template"
        onClick={handleDownload}
        loading={downloadTemplateLoading}
        icon={<FileDownloadIcon fontSize="small" />}
      />

      <Dropzone
        canRemove
        maxFiles="1"
        name="attachments"
        accept=".csv,.xlsx,.xls"
        onChangeStatus={handleUploadChange}
        inputContent={t("drag files here or browse")}
      />
    </MainModal>
  );
};

export default ImportDeductionsModal;
