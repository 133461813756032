import { RequestLimitInitState } from "./sharedFormSections";

const employeeForm = {
  name: "",
  emp_code: "",
  has_credentials: 1,
  email: "",
  phone: "",
  phone_country_code: "",
  probation_period: 0,
  value: "",
  type: "",
  office_id: "",
  company_id: "",
  department_id: "",
  manager_id: "",
  copied_managers: [],
  att_profile_id: null,
  role_id: "",
  position_id: "",
  can_work_home: 0, // work remotely
  employee_can_request: true, // work remotely
  max_homeDays_per_week: 0, // work remotely
  flexible_home: 1, // work remotely
  home_days: [], // work remotely
  can_ex_days: 0, // work remotely
  first_day_of_the_week: null, // work remotely
  starts_at: "",
  active: 1,
  face_path: null,
  user_image: null,
  force_save: false,
  Friday_sign_in_location: [],
  Friday_sign_out_location: [],
  Saturday_sign_in_location: [],
  Saturday_sign_out_location: [],
  Sunday_sign_in_location: [],
  Sunday_sign_out_location: [],
  Monday_sign_in_location: [],
  Monday_sign_out_location: [],
  Tuesday_sign_in_location: [],
  Tuesday_sign_out_location: [],
  Wednesday_sign_in_location: [],
  Wednesday_sign_out_location: [],
  Thursday_sign_in_location: [],
  Thursday_sign_out_location: [],
  optionsName: "",
  annual_leaves: "",
  emergency_leaves: "",
  sick_leaves: null,
  attendanceType: "office based",
  mainPermissionLeavesBreakSettingId: null,	
  permissionLeavesBreakSettingId: null,	
  workGroupId: null,
  workTeamId: null,
  workTeamStartDate: null,
  allowMultipleCheckIns: 0,
  allow_customize_check_ins: 0,
  check_in_form_id: null,
  check_out_form_id: null,

  allow_work_on_day_off: false,
  day_off_exception_id: null,
  apply_compensation: 0,
  weight: 1,
  compensation_type: null,
  compensation_quantity: null,
  treat_as_normal: false,
  allow_permission: false,
  allow_overtime: false,
  workplace_setting: "DEFAULT",
  workplace_setting_signout: "DEFAULT",
  additional_work_places: [],
  additional_work_places_signout: [],
  workplace_setting_identical: 1,

  date_of_birth_year: null,
  date_of_birth_month: null,
  date_of_birth_day: null,
  gender: null,
  marital_status: null,
  national_id: null,
  insurance_number: null,
  check_in_setting_id: null,
  check_in_settings_as_work_timing_sign_in_settings: 1,
  reuiqre_facial_recognition: 0,
  employee_can_check_in_from: "ANY_PLACE",
  check_in_specific_work_laces: [],
  apply_same_setting_when_checking_out: 1,
  employee_can_check_out_from: "ANY_PLACE",
  check_out_specific_work_laces: [],
  metres: "",
  total_income_amount: null,
  total_tax_amount: null,

  // holiday config inputs
  same_config: 1,
  allow_work_on_holiday: 0,
  holiday_exception_id: null,
  holiday_apply_compensation: 0,
  holiday_weight: 1,
  holiday_compensation_type: null,
  holiday_compensation_quantity: null,
  holiday_treat_as_normal: false,
  holiday_allow_permission: false,
  holiday_allow_overtime: false,
  holiday_workplace_setting: "DEFAULT",
  holiday_workplace_setting_signout: "DEFAULT",
  holiday_additional_work_places: [],
  holiday_additional_work_places_signout: [],
  holiday_workplace_setting_identical: 1,
  display_roster: 1,

  ...RequestLimitInitState
};

const workTimings = {
  sign_in_req: 0,
  id: null,
  name: "",
  weight: 1,
  work_timing_profile_type: "normal",

  // sign in
  sign_in_start_time: "",
  sign_in_end_time: "",
  work_hours: "",
  end_of_day: "00:00",
  time_allowed_before_sign_in: "",
  consider_permissibility_hours: false,

  // tardiness policy
  apply_minor_tardiness: 0,
  late_reset_period: "month",
  annual_leave_hours_sign_in: null,
  tardiness_type_sign_in: "time_interval",
  late_tardiness_penalty_type: "Leaves",
  max_lateness_permissibility: "",
  minor_tardiness_range: "",
  skip_not_applied_minor_penalties: 0,
  minor_violations_deduct_as: "days",
  minor_violations: [
    {
      id: null,
      value: "",
      penalty_type: "minor",
      deduct_minutes_by: "minutes",
    },
  ],
  apply_major_tardiness: 0,
  skip_not_applied_major_penalties: 0,
  major_tardiness_range: "",
  major_violations_deduct_as: "days",
  major_violations: [
    {
      id: "",
      value: "",
      penalty_type: "major",
      deduct_minutes_by: "minutes",
    },
  ],
  apply_major_tardiness1: 0,
  skip_not_applied_major_penalties1: 0,
  major_tardiness_range1: "",
  major_violations_1_deduct_as: "days",
  major_violations1: [
    {
      id: "",
      value: "",
      penalty_type: "major1",
      deduct_minutes_by: "minutes",
    },
  ],
  apply_major_tardiness2: 0,
  skip_not_applied_major_penalties2: 0,
  major_tardiness_range2: "",
  major_violations_2_deduct_as: "days",
  major_violations2: [
    {
      id: "",
      value: "",
      penalty_type: "major2",
      deduct_minutes_by: "minutes",
    },
  ],
  apply_major_tardiness3: 0,
  skip_not_applied_major_penalties3: 0,
  major_tardiness_range3: "",
  major_violations_3_deduct_as: "days",
  major_violations3: [
    {
      id: "",
      value: "",
      penalty_type: "major3",
      deduct_minutes_by: "minutes",
    },
  ],
  apply_major_tardiness4: 0,
  skip_not_applied_major_penalties4: 0,
  major_tardiness_range4: "",
  major_violations_4_deduct_as: "days",
  major_violations4: [
    {
      id: "",
      value: "",
      penalty_type: "major4",
      deduct_minutes_by: "minutes",
    },
  ],
  apply_major_tardiness5: 0,
  skip_not_applied_major_penalties5: 0,
  major_tardiness_range5: "",
  major_violations_5_deduct_as: "days",
  major_violations5: [
    {
      id: "",
      value: "",
      penalty_type: "major5",
      deduct_minutes_by: "minutes",
    },
  ],
  apply_major_tardiness6: 0,
  skip_not_applied_major_penalties6: 0,
  major_tardiness_range6: "",
  major_violations_6_deduct_as: "days",
  major_violations6: [
    {
      id: "",
      value: "",
      penalty_type: "major6",
      deduct_minutes_by: "minutes",
    },
  ],
  apply_major_tardiness7: 0,
  skip_not_applied_major_penalties7: 0,
  major_tardiness_range7: "",
  major_violations_7_deduct_as: "days",
  major_violations7: [
    {
      id: "",
      value: "",
      penalty_type: "major7",
      deduct_minutes_by: "minutes",
    },
  ],
  apply_major_tardiness8: 0,
  skip_not_applied_major_penalties8: 0,
  major_tardiness_range8: "",
  major_violations_8_deduct_as: "days",
  major_violations8: [
    {
      id: "",
      value: "",
      penalty_type: "major8",
      deduct_minutes_by: "minutes",
    },
  ],
  apply_major_tardiness9: 0,
  skip_not_applied_major_penalties9: 0,
  major_tardiness_range9: "",
  major_violations_9_deduct_as: "days",
  major_violations9: [
    {
      id: "",
      value: "",
      penalty_type: "major9",
      deduct_minutes_by: "minutes",
    },
  ],
  apply_major_tardiness10: 0,
  skip_not_applied_major_penalties10: 0,
  major_tardiness_range10: "",
  major_violations_10_deduct_as: "days",
  major_violations10: [
    {
      id: "",
      value: "",
      penalty_type: "major10",
      deduct_minutes_by: "minutes",
    },
  ],
  // absent -- no Show
  absent_reset_period: "year",
  emergency_penalty_type: "Leaves",
  absent_violations_deduct_unit: "days",
  absent_violations: [
    {
      id: null,
      value: "1",
      penalty_type: "absent",
    },
  ],
  skip_not_applied_absent_penalties: 0,
  // sign out
  // early_signout_action
  allow_early_signout: 0,
  annual_leave_hours_sign_out: "",
  tardiness_type_sign_out: "time_interval",
  early_signout_reset_period: "month",
  early_signout_deduction_type: "Leaves",
  early_violations_deduct_unit: "days",
  early_sign_out_violations: [
    {
      id: "",
      value: "",
      penalty_type: "early_sign_out",
    },
  ],
  early_sign_out_violations1: [
    {
      id: "",
      value: "",
      penalty_type: "early_sign_out1",
    },
  ],
  early_sign_out_violations2: [
    {
      id: "",
      value: "",
      penalty_type: "early_sign_out2",
    },
  ],
  early_sign_out_violations3: [
    {
      id: "",
      value: "",
      penalty_type: "early_sign_out3",
    },
  ],
  early_sign_out_violations4: [
    {
      id: "",
      value: "",
      penalty_type: "early_sign_out4",
    },
  ],
  early_sign_out_violations5: [
    {
      id: "",
      value: "",
      penalty_type: "early_sign_out5",
    },
  ],
  early_sign_out_violations6: [
    {
      id: "",
      value: "",
      penalty_type: "early_sign_out6",
    },
  ],
  early_sign_out_violations7: [
    {
      id: "",
      value: "",
      penalty_type: "early_sign_out7",
    },
  ],
  early_sign_out_violations8: [
    {
      id: "",
      value: "",
      penalty_type: "early_sign_out8",
    },
  ],
  early_sign_out_violations9: [
    {
      id: "",
      value: "",
      penalty_type: "early_sign_out9",
    },
  ],
  early_sign_out_violations10: [
    {
      id: "",
      value: "",
      penalty_type: "early_sign_out10",
    },
  ],
  skip_not_applied_early_sign_out_penalties: 0,

  // allow missing sign out
  apply_missing_sign_out: 0,
  missing_sign_out_reset_period: "month",
  missing_sign_out_deduction_type: "Leaves",
  missing_violations_deduct_unit: "days",
  missing_sign_out_violations: [
    {
      id: "",
      value: "",
      penalty_type: "missing_sign_out",
    },
  ],

  skip_not_applied_missing_sign_out_penalties: 0,
  absent_early_sign_out: 0,
  sign_out_cuttoff_time: 0,
  calc_sign_out_time_from_actual_sign_in: 0,

  // Overtime

	overtime_permissible: 0,
	min_overtime_cuttoff: 0,
	overtime_limit: 0,
	max_overtime_per_day: 0,
	overtime_type: "up",
	overtime_value: 0,
	record_actual: true,

  // Sign (in-out) Settings

  face_rekognition: 0,
  // apply_facial_challenge_to_sign_in: 0,
  home_face_rekognition: 0,
  remote_sign_in: 1,
  sign_in_from_office_ips: 0,
  sign_in_from_office_geolocation: 0,
  allow_locations_custom_override: 0,
  allow_sign_in_outside_specified_locations: 0,

  same_policies: 1,
  remote_sign_out: 1,
  sign_out_from_office_ips: 0,
  sign_out_from_office_geolocation: 0,
  sign_out_locations_custom_override: 0,
  allow_sign_out_outside_specified_locations: 0,

  auto_sign_out: 0,
  auto_sign_out_time: "",
  sign_in_reminder: 0,
  sign_in_reminder_time: null,
  sign_out_reminder: 0,
  sign_out_reminder_time: "",
  can_not_sign_out_before: null,
  can_not_sign_in_after: null,

  allow_early_signout1: 0,
  skip_not_applied_early_sign_out_penalties1: 0,
  early_signout_range_to1: null,
  allow_early_signout2: 0,
  skip_not_applied_early_sign_out_penalties2: 0,
  early_signout_range_to2: null,
  allow_early_signout3: 0,
  skip_not_applied_early_sign_out_penalties3: 0,
  early_signout_range_to3: null,
  allow_early_signout4: 0,
  skip_not_applied_early_sign_out_penalties4: 0,
  early_signout_range_to4: null,
  allow_early_signout5: 0,
  skip_not_applied_early_sign_out_penalties5: 0,
  early_signout_range_to5: null,
  allow_early_signout6: 0,
  skip_not_applied_early_sign_out_penalties6: 0,
  early_signout_range_to6: null,
  allow_early_signout7: 0,
  skip_not_applied_early_sign_out_penalties7: 0,
  early_signout_range_to7: null,
  allow_early_signout8: 0,
  skip_not_applied_early_sign_out_penalties8: 0,
  early_signout_range_to8: null,
  allow_early_signout9: 0,
  skip_not_applied_early_sign_out_penalties9: 0,
  early_signout_range_to9: null,
  allow_early_signout10: 0,
  skip_not_applied_early_sign_out_penalties10: 0,
  early_signout_range_to10: null,
  //Break Settings
  override_break_setting: false,
  allow_break: false,
  deduct_from_work_hours: false,
  break_type: "flexible",
  start_break: "manual",
  can_end_break: true,
  break_interval: [],
  allow_limit_total_break: true,
  break_work_timing_setting_id: null,
  number_of_allowed_break_sessions_per_day: "",
  maximum_break_duration: "",
  maximum_break_session_duration: "",

  // limit work timings
  allow_work_timing_assigment: 1,
  offices: [],
  departments: [],
  positions: [],
  employees: [],

  // min work hours
  min_work_hours: "",
  allow_min_work_hours: 0,
};

export default {
  employeeForm,
  workTimings,
};
