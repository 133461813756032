import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { dateTimeListFormat } from "../../../Constants";
import { upsertCommentCompanyActivityMutation } from "../../../Graphql/mutation";
import { showToast } from "../../../Helpers/HelperFns";
import Loader from "../../Loader";
import MainModal from "../../MainModal";
import { InputForm } from "form-builder";
import SendIcon from "@mui/icons-material/Send";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import IconButtonWithTooltip from "../../IconButtonWithTooltip";

const CompanyCommentModal = ({
  data,
  onClose,
  refetchList,
}) => {
  const { t } = useTranslation();

  // Local State
  const [comment, setComment] = useState("");

  // Server State
  // Start of handle upsert company comment activity mutation
  const handleUpsertCompanyCommentActivity = () => {
    upsertCompanyActivity({
      variables: {
        input: {
          id: null,
          comment: comment,
          comment_id: data?.id,
          comment_type: data?.type,
        },
      },
      onCompleted: (data) => {
        if (data?.upsert_comments?.id) {
          refetchList();
          onClose();
          showToast("success");
        }
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
        );
      },
    });
  };

  const [
    upsertCompanyActivity,
    { loading: upsertCompanyCommentActivityIsLoading },
  ] = useMutation(upsertCommentCompanyActivityMutation);
  // End of handle upsert company comment activity mutation

  /* ↓ Helpers ↓ */

  // handle save comment function
  const handleSaveComment = () => {
    handleUpsertCompanyCommentActivity();
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      modalTitle={t("comments")}
      hasModalFooter={false}
    >
      {upsertCompanyCommentActivityIsLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      {data?.comments?.length ? (
        data?.comments?.map(({ comment, user, created_at }, i) => (
          <div key={i}>
            <div className="d-flex justify-content-between">
              <strong>{user?.name}</strong>
              <time dateTime={created_at}>
                {moment(created_at).format(dateTimeListFormat)}
              </time>
            </div>
            <p>{comment}</p>
          </div>
        ))
      ) : (
        <p>{t("no comments added yet")}</p>
      )}
        <div className="d-flex align-items-center gap-20">
          <InputForm
            value={comment}
            rootStyle="flex-fill"
            inputContainerStyle="w-100"
            onChange={(e) => setComment(e.target.value)}
          />
          <IconButtonWithTooltip
            label="add comment"
            onClick={handleSaveComment}
            icon={<SendIcon sx={{ color: "#23aaeb" }} />}
          />
        </div>
    </MainModal>
  );
};

export default CompanyCommentModal;
