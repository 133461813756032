import React from "react";

import Privileges from "../../../Constants/Privilages";

import RemindersDashboard from "./RemindersDashboard";
import ActivitiesDashboard from "./ActivitiesDashboard";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";

const Dashboard = () => {
  return (
    <>
      <HasPrivileges
        reqireMain={[Privileges.VIEW_REMINDERS, Privileges.SUPER_PRIVILEGE]}
      >
        <RemindersDashboard />
      </HasPrivileges>
      <HasPrivileges
        reqireMain={[Privileges.VIEW_ACTIVITIES, Privileges.SUPER_PRIVILEGE]}
      >
        <ActivitiesDashboard />
      </HasPrivileges>
    </>
  );
};

export default Dashboard;
