import React, { useState, useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PayrollWidget from "../../Components/PayrollWidget";
import Constants from "../../Helpers/Constants";
import {
  BSelect,
  RadioboxForm,
  CheckboxBooleanForm,
  InputForm,
} from "form-builder";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faExchangeAlt,
  faFileInvoiceDollar,
  faFileExport,
  faAngleUp,
  faAngleDown,
  faSlidersH,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import PayslipModal from "../../Components/PayslipModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import Privilages from "../../Constants/Privilages";

import { Spinner } from "reactstrap";
import ExportPayrollModal from "./ExportPayrollModal";
import { Link, useLocation } from "react-router-dom";
import AntSwitch from "../../Components/AntSwitch";
import moment from "moment";
import {
  GET_PAYROLL_DETAILS,
  GET_PAYROLL_FILTERS_DATA,
  allowancesDetailsQuery,
  bonusDetailsQuery,
  fetchExportPayrollOptsQuery,
  otherAddedDetailsQuery,
  otherDeductablesDetailsQuery,
  overtimeDetailsQuery,
  penaltyDetailsQuery,
  userPayslipQuery,
  weeklyPayrollQuery,
} from "../../Graphql/query";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import swal from "sweetalert";
import {
  EditIconButton,
  InfoIconButton,
  TimesIconButton,
  AcceptIconButton,
} from "../../Components/IconButtonWithTooltip";
import { GET_WEEK_OPTIONS } from "../../Graphql/query";
import { SUBMIT_PAYROLLS } from "../../Graphql/mutation";
import ViewWeeklyPayrollDetails from "./ViewWeeklyPayrollDetails";
import {
  fetchExportPayrollOptionsSuccess,
  onFormResetAction,
} from "../../Store/Actions";
import EditWeeklyDeductiblesModal from "./EditWeeklyDeductiblesModal";
import Swal from "sweetalert2";
import { Box, CircularProgress, TextField, Tooltip } from "@mui/material";
import { showErrorToast } from "../../Store/Actions";
import EditPenaltyModal from "../../Components/EditPenaltyModal";
import gql from "graphql-tag";
import HelperFns, { showToast, safeJsonParse } from "../../Helpers/HelperFns";
import FiltersDrawer from "../../Components/FiltersDrawer";
import { Filter } from "@mui/icons-material";
import FilterBadge from "../../Components/FilterBadge";
import ClearIcon from "@mui/icons-material/Clear";
import NewMultiSelect from "../../Components/FiltersDrawer/NewMultiSelect";

const normalizeChildrenPayroll = (parentPayroll, childPayroll) => ({
  user: {
    id: parentPayroll?.user?.id,
    user_type: parentPayroll?.user?.user_type,
  },
  from: childPayroll?.FROM,
  paid_at: parentPayroll?.paid_at,
  base_salary: childPayroll?.SALARY,
  allowances_amount: childPayroll?.ALLOWANCE,
  bonuses_amount: childPayroll?.BONUS,
  overtime_amount: childPayroll?.OVERTIME,
  extra_others: childPayroll?.OTHERS?.EXTRA,
  penalties_amount: childPayroll?.PENALTY,
  social_amount: childPayroll?.SOCIAL,
  medical_amount: childPayroll?.MEDICAL,
  taxes_amount: childPayroll?.TAX,
  deductible_others: childPayroll?.OTHERS?.DEDUCTION,
  payable_amount: childPayroll?.TOTAL,
  status: childPayroll?.STATUS,
  config_type: childPayroll?.MANAGEMENT_TYPE,
  currency: parentPayroll?.currency ?? {},
  commission_id: childPayroll?.ID,
  is_commission: childPayroll?.IS_COMMISSION || 0,
});

const payrollManagementTypes = {
  EXTERNAL: "External",
  INTERNAL: "Internal",
};

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const externalTableStyle = {
  backgroundColor: "#fff !important",
  // borderRightColor: "#fff !important",
  borderLeftColor: "#fff !important",
};

const extraTableStyle = {
  backgroundColor: "rgba(220, 220, 220, 0.84)",
};

const editAmountModalInitialState = {
  isOpen: false,
  title: null,
  userId: null,
  paidAt: null,
  from: null,
  deductibleType: null,
  oldAmount: null,
  currency: null,
};

const EXPORT_PAYSLIPS = gql`
  mutation exportPayslips($input: [exportPayslipReportInput]) {
    export_payslip_report(input: $input) {
      file
      status
      message
    }
  }
`;

const dataTableRef = "payrollList";

const filtersInitState = {
  office_id: "",
  department_id: "",
  month: moment().format("MM"),
  year: new Date().getFullYear(),
  currency_id: "",
  status: "unpaid",
  user_id: "",
  employee_search: null,
};

const PayrollView = ({ isMonthly, ...props }) => {
  const dispatch = useDispatch();
  const search = useLocation().search;

  const isExportPayrolModalOpen = useSelector(
    (state) => state?.super?.exportPayrollModalActions
  );

  const [exportPayslips, { loading: exportPayslipsLoading }] =
    useMutation(EXPORT_PAYSLIPS);
  const [getExportPayrollOptions, { loading: exportPayrollOptionsLoading }] =
    useLazyQuery(fetchExportPayrollOptsQuery, {
      onCompleted: (data) => {
        dispatch(fetchExportPayrollOptionsSuccess(data));
      },
    });

  const [payrollsToSubmit, setPayrollsToSubmit] = useState([]);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const [isExpected, setIsExpected] = useState(false);
  const [isExternal, setIsExternal] = useState(false);

  const handleToggleShowExternal = (_, value) => {
    setIsExternal(value);
  };

  const payrollList = useSelector((state) => state?.super?.payrollList);
  const [filters, setFilters] = useState(filtersInitState);
  const [shouldFetch, setShouldFetch] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsFilterDrawerOpen(open);
  };

  const onFilterReset = () => {
    setFilters({
      ...filtersInitState,
      month: moment(payrollListVars, "YYYY-MM-DD").format("MM"),
    });
  };

  const onFilterSubmit = () => {
    const isDiff =
      JSON.stringify(payrollListVars) !==
      JSON.stringify(weeklyPayrollQueryVariables);
    if (isDiff) handelFetchWeeklyPayrollData();
    setIsFilterDrawerOpen(false);
  };

  useEffect(() => {
    setPayrollsToSubmit({});

    return () => {
      setPayrollsToSubmit({});
    };
  }, [payrollList, isExternal]);

  const { data: payrollFiltersData } = useQuery(GET_PAYROLL_FILTERS_DATA, {
    variables: {
      office_id: filters?.office_id,
    },
    onCompleted: (data) => {
      const preSelectedOffice =
        data?.payrollReportOfficesFilter?.data?.length > 1
          ? data?.payrollReportOfficesFilter?.data?.find(
              (office) => !!office?.head_quarter
            )
          : data?.payrollReportOfficesFilter?.data?.length === 1
          ? data?.payrollReportOfficesFilter?.data?.[0]
          : null;
    },
    fetchPolicy: "cache-and-network",
  });

  const { t } = useTranslation();
  const [pagination, setPagination] = useState(paginationInitState);

  const [revertLoadingId, setRevertLoadingId] = useState(null);

  const [editAmountModal, setEditAmountModal] = useState(
    editAmountModalInitialState
  );

  const handleCloseEditAmountModal = () => {
    setEditAmountModal(editAmountModalInitialState);
  };

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const user = useSelector((state) => state?.auth?.userProfile);

  const closedMonths = useSelector(
    (state) => state?.super?.closedPayrollMonths
  );

  const selectedCurrency =
    payrollFiltersData?.payrollReportCurrenciesFilter?.find(
      (currency) => currency?.id === filters?.currency_id
    );

  const groupColumns = [
    {
      name: "",
      grow: 3.07,
    },
    {
      name: <div className="extra_main_title_style mx-auto">{t("extra")}</div>,
      grow: 4,
      center: true,
    },
    {
      name: (
        <div className="deductions_main_title_style">{t("deductions")}</div>
      ),
      grow: 5.48,
      center: true,
    },
    {
      name: "",
      grow: 1.24,
    },
  ];

  const monthYear = `${filters.month}-${filters.year}`;

  const [
    attemptGetWeekOptions,
    { loadin: weekOptionsloading, data: weekData },
  ] = useLazyQuery(GET_WEEK_OPTIONS, {
    onCompleted: (data) => {
      const currentWeek =
        data?.weeks?.find(
          (week) =>
            (moment(week?.from).isSame(moment(), "days") ||
              moment(week?.from).isBefore(moment(), "days")) &&
            (moment(week?.paidAt).isSame(moment(), "days") ||
              moment(week?.paidAt).isAfter(moment(), "days"))
        ) ?? data?.weeks?.[0];
      setFilters((prev) => ({ ...prev, paid_at: currentWeek?.paidAt }));
      setShouldFetch(true);
    },
  });

  const headQuarterOffice = useSelector((state) =>
    state.auth.userProfile?.offices?.find((office) => office.head_quarter == 1)
  );

  useEffect(() => {
    const month = new URLSearchParams(search).get("month");
    const year = new URLSearchParams(search).get("year");

    if (isMonthly && month && year) {
      setFilters((prev) => ({
        ...prev,
        month: moment(month, "M").format("MM"),
      }));
      setFilters((prev) => ({ ...prev, year }));
    }

    if (!isMonthly) {
      setFilters((prev) => ({ ...prev, office_id: headQuarterOffice?.id }));
    }
    setFilters((prev) => ({
      ...prev,
      currency_id: headQuarterOffice?.currency?.id,
    }));
    setShouldFetch(true);

    return () => {
      dispatch(onFormResetAction(dataTableRef));
      setFilters(filtersInitState);
    };
  }, [isMonthly]);

  // const selectedOfficeStartOfMonth =
  //   +payrollFiltersData?.payrollReportOfficesFilter?.data?.find((office) =>
  //     !!payrollList?.office_id
  //       ? office?.id === payrollList?.office_id
  //       : !!office?.head_quarter
  //   )?.month_start_day;

  const selectedOfficeStartOfMonth = payrollList?.office_id
    ? +payrollFiltersData?.payrollReportOfficesFilter?.data?.find(
        (office) => office?.id === payrollList?.office_id
      )?.month_start_day
    : payrollFiltersData?.payrollReportOfficesFilter?.data?.some(
        (office) => !!office?.head_quarter
      )
    ? +payrollFiltersData?.payrollReportOfficesFilter?.data?.find(
        (office) => !!office?.head_quarter
      )?.month_start_day
    : +payrollFiltersData?.payrollReportOfficesFilter?.data?.[0]
        ?.month_start_day;

  const shouldViewNextMonth =
    selectedOfficeStartOfMonth > 15 &&
    Number(selectedOfficeStartOfMonth) <= Number(moment().format("DD"));

  useEffect(() => {
    if (isMonthly) {
      if (shouldViewNextMonth) {
        interceptChange("month", moment().add(1, "month").format("MM"));
        setShouldFetch(true);
      }
    }
  }, [filters?.office_id, shouldViewNextMonth]);

  useEffect(() => {
    if (!isMonthly) {
      attemptGetWeekOptions({
        variables: {
          from: moment(monthYear, "MM-YYYY")
            ?.startOf("month")
            ?.format("YYYY-MM-DD"),
          to: moment(monthYear, "MM-YYYY")
            ?.endOf("month")
            ?.format("YYYY-MM-DD"),
          userId: filters?.office_id ? +filters?.office_id : 2,
          filterKey: "from",
        },
      });
    }
  }, [monthYear, filters?.office_id]);

  const getPaidAt = () => {
    if (isMonthly) {
      return monthYear
        ? moment(monthYear, "MM-YYYY").startOf("month").format("YYYY-MM-DD")
        : undefined;
    } else {
      return filters?.paid_at ? filters?.paid_at : undefined;
    }
  };

  const weeklyPayrollQueryVariables = {
    user_id: filters?.user_id ? filters?.user_id : undefined,
    office_ids: filters?.office_id ? [filters?.office_id] : undefined,
    department_id: filters?.department_id ? filters?.department_id : undefined,
    page: pagination.currentPage || 1,
    type: isMonthly ? "MONTHLY" : "WEEKLY",
    paid_at: getPaidAt(),
    currency_id: filters?.currency_id ? filters?.currency_id : undefined,
    status:
      filters?.status === "unpaid"
        ? "Pending"
        : filters?.status === "paid"
        ? "Paid"
        : undefined,
    ...(filters?.employee_search
      ? {
          employee_search: filters?.employee_search,
        }
      : {}),
    isExpected: isExpected ? 1 : 0,
    isExternal: isExternal ? 1 : 0,
    isAuthPayroll: false,
    first: 50,
  };

  const [
    fetchWeeklyPayroll,
    { data, loading, refetch: refetchPayrollList, variables: payrollListVars },
  ] = useLazyQuery(weeklyPayrollQuery);

  const handelFetchWeeklyPayrollData = (extravar = {}) => {
    fetchWeeklyPayroll({
      variables: { ...weeklyPayrollQueryVariables, ...extravar },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      onCompleted: (queryData) => {
        const paginationInfo = queryData?.payrollReport?.paginatorInfo ?? {};
        setPagination(paginationInfo);
      },
    });
  };

  useEffect(() => {
    if (!(!isMonthly && !filters?.office_id)) {
      handelFetchWeeklyPayrollData();
    }
  }, [pagination.currentPage]);

  const payrollAggregator = JSON.parse(data?.payrollReport?.aggregator ?? "{}");

  const PaidPayrollAmount = parseFloat(payrollAggregator?.paid ?? 0);
  const PendingPayrollAmount = parseFloat(payrollAggregator?.pending ?? 0);

  const totalPayrollAmount = parseFloat(
    +payrollAggregator?.paid + +payrollAggregator?.pending
  );

  const [showExportModal, setShowExportModal] = useState(false);
  const toggleShowExportModal = () => setShowExportModal(!showExportModal);

  // for show payslip modal and use modal component one time
  const [showPayslipModal, setShowPayslipModal] = useState(false);

  const [
    attemptGetUserPayslip,
    { data: payslipDetailsData, loading: userPayslipLoading },
  ] = useLazyQuery(userPayslipQuery);

  const [payrollDetailsForPaySlip, setPayrollDetailsForPaySlip] =
    useState(null);

  const handleViewPayslipModal = (payrollDetails) => {
    const payrollFromDate = payrollDetails?.details
      ? JSON.parse(payrollDetails?.details?.[0] ?? "{}")?.FROM
      : payrollDetails?.from;

    setPayrollDetailsForPaySlip({
      paid_at: payrollDetails?.paid_at,
      from: payrollFromDate,
    });

    attemptGetUserPayslip({
      variables: {
        input: {
          user_id: payrollDetails?.user?.id,
          type: isMonthly ? "Monthly" : "Weekly",
          from: payrollFromDate,
          paid_at: payrollDetails?.paid_at,
          monthly_view: isExpected,
          month_year: payrollDetails?.paid_at, // should not send this one but it's included in the schema
        },
      },
      onCompleted: (data) => {
        setShowPayslipModal(true);
      },
    });

    // dispatch(
    //   fetchUserPaySlipAction({
    //     user_id: payslipDetailsData?.user?.id,
    //     month_year_payroll: payslipDetailsData?.month_year_payroll,
    //   })
    // );
  };

  const toggleHidePayslipModal = () => {
    setShowPayslipModal(false);
    setPayrollDetailsForPaySlip(null);
  };

  // start handle view cell details

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [detailsModalData, setDetailsModalData] = useState({});

  const dismissDetailsModal = () => {
    setIsDetailsModalOpen(false);
    setDetailsModalData({});
  };

  const [attemptGetPayrollDetails, { loading: payrollDetailsLoading }] =
    useLazyQuery(GET_PAYROLL_DETAILS, {
      variables: {
        payrollType: isMonthly ? "Monthly" : "Weekly",
      },
    });

  const [penaltyViewDetails, setPenaltyViewDetails] = useState({});

  const handleViewDetails = ({ detailsType, title, payroll }) => {
    const { user, paid_at } = payroll;

    const from = payroll?.from
      ? payroll?.from // child case
      : JSON.parse(payroll?.details?.[0])?.FROM; // parent case

    attemptGetPayrollDetails({
      variables: {
        detailsType,
        user_id: user?.id,
        paid_at,
        from,
        isExpected: isExpected ? "1" : "0",
      },
      onCompleted: (data) => {
        setIsDetailsModalOpen(true);

        setDetailsModalData({
          title,
          month_year: paid_at,
          name: user?.name,
          data:
            detailsType === "ALLOWANCE"
              ? JSON.parse(data?.payroll_v2_details)?.map((allowance) => {
                  return {
                    name: allowance?.id,
                    amount: allowance?.q,
                  };
                })
              : detailsType === "PENALTY"
              ? JSON.parse(data?.payroll_v2_details)?.map((penalty) => {
                  return {
                    id: penalty?.id,
                    apply_flag: penalty?.applyFlag,
                    canEdit: penalty?.canEdit,
                    canApply: penalty?.canApply,
                    date: penalty?.date,
                    quantity: penalty?.q,
                    quantity_unit: penalty?.unit,
                    PenaltyAmount: penalty?.amount,
                    full_cause: penalty?.reason,
                  };
                })
              : detailsType === "BONUS"
              ? JSON.parse(data?.payroll_v2_details)?.map((bonus) => {
                  return {
                    name: bonus?.name ?? "",
                    value: bonus?.value ?? "",
                    unit: bonus?.unit ?? "",
                    amount: bonus?.amount ?? "",
                    description: bonus?.reason ?? "",
                    createdBy: bonus?.user ?? "",
                    payroll_at: bonus?.date ?? "",
                  };
                })
              : detailsType === "OVERTIME"
              ? JSON.parse(data?.payroll_v2_details)?.map((overtime) => {
                  return {
                    req_day: overtime?.date ?? "",
                    interval_in_time: overtime?.interval ?? "",
                    payable_interval_in_time: overtime?.paid ?? "",
                    amount: parseFloat(overtime?.amount) ?? "",
                    accepted_by: overtime?.approved_by ?? "",
                    payable_at: overtime?.payable_at ?? "",
                    month_year_payroll: overtime?.payroll ?? "",
                  };
                })
              : detailsType === "DEDUCTION"
              ? {
                  deductions: JSON.parse(
                    data?.payroll_v2_details
                  )?.deductions?.map((deduction) => {
                    return {
                      name: deduction?.name ?? "",
                      amount: parseFloat(deduction?.amount) ?? "",
                      // payment_factor_id: deduction?.payment_factor ?? "",
                      // value: "",
                      // description: "",
                      // createdBy: "",
                      // payroll_at: "",
                    };
                  }),
                  installments: JSON.parse(
                    data?.payroll_v2_details
                  )?.installments?.map((installment) => {
                    return {
                      name: installment?.loan?.name,
                      amount: installment?.loan?.amount,
                      installment_amount: installment?.amount,
                    };
                  }),
                  taxSettlements: JSON.parse(data?.payroll_v2_details)
                    ?.tax_settlements?.filter(
                      (arr) => Array.isArray(arr) && arr?.length
                    )
                    ?.map((taxSettlement) => {
                      return {
                        start: taxSettlement[0]?.from,
                        end: taxSettlement[0]?.to,
                        year: taxSettlement[0]?.year,
                        value: parseFloat(taxSettlement[0]?.amount),
                      };
                    }),
                  // deductibles: [
                  //   {
                  //     deductible: "",
                  //     amount: "",
                  //     target_amount: "",
                  //   },
                  // ],
                }
              : detailsType === "EXTRA"
              ? {
                  claims: JSON.parse(data?.payroll_v2_details)?.claims?.map(
                    (claim) => ({
                      amount: claim?.amount ?? "",
                      reason: claim?.reason ?? "",
                      incurred_at: claim?.incurred_at ?? "",
                      actionBy: claim?.action_by ?? "",
                    })
                  ),
                  loans: JSON.parse(data?.payroll_v2_details)?.loans?.map(
                    (loan) => ({
                      name: loan?.name ?? "",
                      amount: loan?.amount ?? "",
                      createdBy: loan?.user ?? "",
                    })
                  ),
                  // taxSettlements: [
                  //   {
                  //     start: "",
                  //     end: "",
                  //     year: "",
                  //     value: "",
                  //   },
                  // ],
                }
              : [],
        });
      },
    });
  };

  const [fetchOvertimeDetails, { loading: loadingOvertimeDetails, variables }] =
    useLazyQuery(overtimeDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
          payroll_id: variables?.payroll_id,
        }));
      },
    });

  const [fetchBonusDetails, { loading: loadingBonusDetails }] = useLazyQuery(
    bonusDetailsQuery,
    {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
        }));
      },
    }
  );
  const [fetchPenaltyDetails, { loading: loadingPenaltyDetails }] =
    useLazyQuery(penaltyDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
        }));
      },
    });

  const [fetchOtherDeductablesDetails, { loading: loadingOtherDeductDetails }] =
    useLazyQuery(otherDeductablesDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
        }));
      },
    });

  const [fetchOtherAddedDetails, { loading: loadingOtherAddedDetails }] =
    useLazyQuery(otherAddedDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
        }));
      },
    });

  const [fetchAllowancesDetails, { loading: loadingAllowancesDetails }] =
    useLazyQuery(allowancesDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData?.userAllowancesHistory,
        }));
      },
    });
  // end handle View cell details
  const TableCell = ({
    configType,
    symbol,
    value,
    allowNegativeValue = false,
  }) =>
    configType != payrollManagementTypes.EXTERNAL ? (
      <div className="d-flex align-items-center">
        <div className="pr-1">{symbol}</div>
        <p className="mb-0">
          {value
            ? roundToTwo(
                allowNegativeValue && value.toFixed(2) < 0
                  ? value
                  : Math.abs(value)
              )
            : "0.00"}
        </p>
      </div>
    ) : (
      <div></div>
    );

  const canEditOrViewRow = (row) => {
    if (isManagedExternlly(row)) {
      return false;
    }
    if (!row?.details) {
      //child record
      return true;
    } else {
      //parent record
      return row?.details?.length === 1;
    }
  };

  const isManagedExternlly = (row) => {
    return row?.details
      ? row?.details?.every(
          (detail) =>
            JSON.parse(detail)?.MANAGEMENT_TYPE ==
            payrollManagementTypes.EXTERNAL
        )
      : row?.config_type == payrollManagementTypes.EXTERNAL;
  };

  const columns = [
    {
      name: t("employees"),
      wrap: true,
      selector: (row) => row?.user?.name,
      sortable: false,
      grow: 3,
      // omit:
      //   payrollList?.currency_id &&
      //   !props?.extraPayrollRow?.user?.name,
      style: {
        backgroundColor: "#fff",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
        {
          when: (row) => isManagedExternlly(row),
          style: {
            borderRight: "1px solid #d2d2d2;",
          },
        },
      ],
      cell: (row) =>
        row?.is_commission && row?.status === "Paid" ? (
          <HasPrivileges
            reqireMain={[Privilages.REVERSE_PAYMENTS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <div className="data-table-action mr-2">
                <span className="unpay_btn_style btn p-0 px-1">
                  <FontAwesomeIcon icon={faExchangeAlt} />
                </span>
              </div>
            }
          >
            <div className="data-table-action mr-2">
              {revertLoadingId === JSON.stringify(row) ? (
                <Spinner
                  style={{
                    width: "1rem",
                    height: "1rem",
                    color: "#8997a4",
                  }}
                />
              ) : (
                <span
                  onClick={() => handleRevertPayment(row)}
                  className="unpay_btn_style btn p-0 px-1"
                >
                  <FontAwesomeIcon icon={faExchangeAlt} />
                </span>
              )}
            </div>
          </HasPrivileges>
        ) : (
          <div className="d-flex w-100 align-items-center justify-content-between">
            {row?.isExtra ? (
              <div className="mr-2">
                {row?.user?.name} {t("employees")}
              </div>
            ) : (
              <CanViewEmployeeProfile
                allowBP
                directManger={row?.user?.manager?.id}
                copiedManagers={row?.user?.copied_managers?.map((cp) => cp?.id)}
                altChildren={<div className="mr-2">{row?.user?.name}</div>}
              >
                <div className="mr-2">
                  <Link
                    className="employee-name"
                    to={`/employees/employee-profile/${row?.user?.id}`}
                  >
                    {row?.user?.name}
                  </Link>
                </div>
              </CanViewEmployeeProfile>
            )}

            {JSON.parse(row?.details?.[0] ?? "{}")?.STATUS?.toLowerCase() ===
              "paid" && (
              <HasPrivileges
                reqireMain={[Privilages.REVERSE_PAYMENTS]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="data-table-action mr-2">
                    <span className="unpay_btn_style btn p-0 px-1">
                      <FontAwesomeIcon icon={faExchangeAlt} />
                    </span>
                  </div>
                }
              >
                <div className="data-table-action mr-2">
                  {revertLoadingId === JSON.stringify(row) ? (
                    <Spinner
                      style={{
                        width: "1rem",
                        height: "1rem",
                        color: "#8997a4",
                      }}
                    />
                  ) : (
                    <span
                      onClick={() => handleRevertPayment(row)}
                      className="unpay_btn_style btn p-0 px-1"
                    >
                      <FontAwesomeIcon icon={faExchangeAlt} />
                    </span>
                  )}
                </div>
              </HasPrivileges>
            )}
          </div>
        ),
    },
    {
      name: t("base"),
      wrap: true,
      selector: "base_salary",
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(35, 170, 235, 0.14) !important",
      },
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.base_salary == 0,
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <TableCell
          configType={
            isManagedExternlly(row)
              ? payrollManagementTypes.EXTERNAL
              : payrollManagementTypes.INTERNAL
          }
          symbol={row?.currency?.symbol}
          value={
            payrollList.monthly_amount
              ? row?.monthly_base_salary
              : row?.base_salary
          }
        />
      ),
    },
    {
      name: t("allowances"),
      wrap: true,
      selector: "allowances_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.allowances_amount == 0,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={
              payrollList.monthly_amount
                ? row?.monthly_allowances
                : row?.allowances_amount
            }
          />
          {Math.abs(row?.allowances_amount) > 0 && canEditOrViewRow(row) ? (
            <div className="data-table-action">
              <InfoIconButton
                onClick={() =>
                  handleViewDetails({
                    detailsType: "ALLOWANCE",
                    title: "allowances",
                    payroll: row,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: t("bonuses"),
      wrap: true,
      selector: "bonuses_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.bonuses_amount == 0,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={row?.bonuses_amount}
          />
          {!row?.is_commission &&
          Math.abs(row?.bonuses_amount) > 0 &&
          canEditOrViewRow(row) ? (
            <div className="data-table-action">
              <InfoIconButton
                onClick={() =>
                  handleViewDetails({
                    detailsType: "BONUS",
                    title: "bonus",
                    payroll: row,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: t("overtime"),
      wrap: true,
      selector: "overtime_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.overtime_amount == 0,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={row?.overtime_amount}
          />
          {Math.abs(row?.overtime_amount) > 0 && canEditOrViewRow(row) ? (
            <div className="data-table-action">
              <InfoIconButton
                onClick={() =>
                  handleViewDetails({
                    detailsType: "OVERTIME",
                    title: "overtime",
                    payroll: row,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: t("others"),
      wrap: true,
      selector: "extra_others",
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.extra_others == 0,
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: {
            ...externalTableStyle,
            // padding: "5px 2px 5px 36px !important",
          },
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={row?.extra_others}
          />
          {Math.abs(row?.extra_others) > 0 && canEditOrViewRow(row) ? (
            <div className="data-table-action">
              <InfoIconButton
                onClick={() =>
                  handleViewDetails({
                    detailsType: "EXTRA",
                    title: "other added",
                    payroll: row,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: t("gross"),
      selector: "gross_salary",
      style: {
        backgroundColor: "rgba(35, 170, 235, 0.14) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) =>
        isManagedExternlly(row) ? (
          <div className="w-100 text-right font-weight-bold managed-style">
            {t("managed")}
          </div>
        ) : (
          row?.gross_salary
        ),
    },
    {
      name: t("penalties"),
      wrap: true,
      selector: "penalties_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.penalties_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: {
            ...externalTableStyle,
            // padding: "5px 36px 5px 2px !important",
          },
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) =>
        isManagedExternlly(row) ? (
          <div className="w-100 text-left font-weight-bold external-style">
            {t("externally")}
          </div>
        ) : (
          <div className="d-flex align-items-center w-100 justify-content-between">
            <TableCell
              configType={
                isManagedExternlly(row)
                  ? payrollManagementTypes.EXTERNAL
                  : payrollManagementTypes.INTERNAL
              }
              symbol={row?.currency?.symbol}
              value={row?.penalties_amount}
            />

            {Math.abs(row?.penalties_amount) > 0 && canEditOrViewRow(row) ? (
              <div className="data-table-action">
                <InfoIconButton
                  onClick={() => {
                    const penaltyDetails = {
                      title: "penalty",
                      detailsType: "PENALTY",
                      payroll: row,
                    };

                    setPenaltyViewDetails(penaltyDetails);
                    handleViewDetails(penaltyDetails);
                  }}
                />
              </div>
            ) : null}
          </div>
        ),
    },
    {
      name: t("social"),
      wrap: true,
      selector: "social_amount",
      sortable: false,
      grow: 1.3,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.social_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
        {
          when: (row) => row?.social_fixed,
          style: {
            backgroundColor: "#fff",
            color: "#8997a4 !important",
          },
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={
              payrollList.monthly_amount
                ? row?.monthly_social
                : row?.social_amount
            }
          />
          {(JSON.parse(row?.details?.[0] ?? "{}")?.STATUS?.toLowerCase() ===
            "pending" ||
            row?.status?.toLowerCase() === "pending") &&
            canEditOrViewRow(row) && (
              // !row?.isExtra &&
              // row?.config_type == payrollManagementTypes.INTERNAL &&
              <HasPrivileges
                reqireMain={[Privilages.EDIT_SOCIAL_MEDICAL_TAXES_AMOUNT]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="data-table-action">
                    <span className="btn p-0">
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </div>
                }
              >
                <div className="data-table-action">
                  <span
                    className="btn p-0"
                    onClick={() => {
                      setEditAmountModal({
                        isOpen: true,
                        title: "Edit Social Insurance",
                        userId: row?.user?.id,
                        paidAt: row?.paid_at,
                        from: row?.from
                          ? row?.from
                          : JSON.parse(row?.details?.[0] ?? "{}")?.FROM,
                        deductibleType: "SOCIAL",
                        oldAmount: parseFloat(row?.social_amount),
                        currency: row?.currency,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </span>
                </div>
              </HasPrivileges>
            )}
        </div>
      ),
    },
    {
      name: t("medical"),
      wrap: true,
      selector: "medical_amount",
      sortable: false,
      grow: 1.3,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.medical_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
        {
          when: (row) => row?.medical_fixed,
          style: {
            backgroundColor: "#fff",
            color: "#8997a4 !important",
          },
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={
              payrollList.monthly_amount
                ? row?.monthly_medical
                : row?.medical_amount
            }
          />
          {(JSON.parse(row?.details?.[0] ?? "{}")?.STATUS?.toLowerCase() ===
            "pending" ||
            row?.status?.toLowerCase() === "pending") &&
            canEditOrViewRow(row) && (
              // !row?.isExtra &&
              // row?.config_type == payrollManagementTypes.INTERNAL &&
              <HasPrivileges
                reqireMain={[Privilages.EDIT_SOCIAL_MEDICAL_TAXES_AMOUNT]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="data-table-action">
                    <span className="btn p-0">
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </div>
                }
              >
                <div className="data-table-action">
                  <span
                    className="btn p-0"
                    onClick={() => {
                      setEditAmountModal({
                        isOpen: true,
                        title: "Edit Medical Insurance",
                        userId: row?.user?.id,
                        paidAt: row?.paid_at,
                        from: row?.from
                          ? row?.from
                          : JSON.parse(row?.details?.[0] ?? "{}")?.FROM,
                        deductibleType: "MEDICAL",
                        oldAmount: parseFloat(row?.medical_amount),
                        currency: row?.currency,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </span>
                </div>
              </HasPrivileges>
            )}
        </div>
      ),
    },
    {
      name: t("taxes"),
      wrap: true,
      selector: "taxes_amount",
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.taxes_amount == 0,
      sortable: false,
      grow: 1.3,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
        {
          when: (row) => row?.taxes_fixed,
          style: {
            backgroundColor: "#fff",
            color: "#8997a4 !important",
          },
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={
              payrollList.monthly_amount
                ? row?.monthly_taxes
                : row?.taxes_amount
            }
          />
          {(JSON.parse(row?.details?.[0] ?? "{}")?.STATUS?.toLowerCase() ===
            "pending" ||
            row?.status?.toLowerCase() === "pending") &&
            canEditOrViewRow(row) && (
              // !row?.isExtra &&
              // row?.config_type == payrollManagementTypes.INTERNAL &&
              <HasPrivileges
                reqireMain={[Privilages.EDIT_SOCIAL_MEDICAL_TAXES_AMOUNT]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="data-table-action">
                    <span className="btn p-0">
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </div>
                }
              >
                <div className="data-table-action">
                  <span
                    className="btn p-0"
                    onClick={() => {
                      setEditAmountModal({
                        isOpen: true,
                        title: "Edit Taxes",
                        userId: row?.user?.id,
                        paidAt: row?.paid_at,
                        from: row?.from
                          ? row?.from
                          : JSON.parse(row?.details?.[0] ?? "{}")?.FROM,
                        deductibleType: "TAX",
                        oldAmount: parseFloat(row?.taxes_amount),
                        currency: row?.currency,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </span>
                </div>
              </HasPrivileges>
            )}
        </div>
      ),
    },
    {
      name: t("others"),
      wrap: true,
      selector: "deductible_others",
      sortable: false,
      grow: 1,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.deductible_others == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={
              payrollList.monthly_amount
                ? row?.monthly_deductibles
                : row?.deductible_others
            }
          />
          {Math.abs(row?.deductible_others) > 0 && canEditOrViewRow(row) ? (
            <div className="data-table-action">
              <InfoIconButton
                onClick={() =>
                  handleViewDetails({
                    detailsType: "DEDUCTION",
                    title: "other deductibles",
                    payroll: row,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: t("payable"),
      wrap: true,
      selector: "payable_amount",
      sortable: false,
      grow: 1.4,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.payable_amount == 0,
      width: "150px",
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={
              payrollList.monthly_amount
                ? row?.monthly_payable_amount
                : parseFloat(row?.payable_amount)
            }
            allowNegativeValue={true}
          />
          {(JSON.parse(row?.details?.[0] ?? "{}")?.STATUS?.toLowerCase() ==
            "paid" ||
            row?.status?.toLowerCase() == "paid") &&
            canEditOrViewRow(row) &&
            (!row?.details || (row?.details && row?.details?.length == 1)) &&
            !!!row?.is_commission && (
              <HasPrivileges
                reqireMain={[Privilages.VIEW_MONTHLY_PAYSLIPS]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="data-table-action">
                    <span className="btn p-0">
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </div>
                }
              >
                <div className="data-table-action ml-1">
                  <span
                    className="payslip_icon_style btn p-0"
                    onClick={() => handleViewPayslipModal(row)}
                  >
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                  </span>
                </div>
              </HasPrivileges>
            )}
        </div>
      ),
    },
    { name: t("pending amount"), selector: "pending", width: "100px" },
    {
      name: t("paid amount"),
      width: "100px",
      cell: (row) => (
        <PaidAmountCell row={row} refetchPayrollList={refetchPayrollList} />
      ),
    },
    {
      name: t("payment method"),
      wrap: true,
      sortable: false,
      width: "150px",
      cell: (row) => (
        <div>
          {row?.user?.payments?.map((el, i) => (
            <div key={i}>{el?.payment_method}</div>
          ))}
        </div>
      ),
    },
  ];

  const [
    attemptSubmitPayrolls,
    { loading: submitPayrollsLoading, error: submitErrors },
  ] = useMutation(SUBMIT_PAYROLLS, {
    onCompleted: (data) => {
      setRevertLoadingId(null);
      if (!data?.payroll_v2_submit) return;
      setPayrollsToSubmit({});
      refetchPayrollList();
    },
    onError: (err) => {
      setRevertLoadingId(null);
      dispatch(
        showErrorToast(
          err.graphQLErrors?.[0]?.extensions?.reason ?? "Something went wrong"
        )
      );
    },
    refetchQueries: [
      { query: weeklyPayrollQuery, variables: weeklyPayrollQueryVariables },
    ],
  });

  useEffect(() => {
    if (submitErrors) {
      dispatch(
        showErrorToast(
          submitErrors?.graphQLErrors?.[0]?.extensions?.reason ??
            "Something went wrong"
        )
      );
    }
  }, [submitErrors]);

  const handleSubmitPayment = () => {
    let records = data?.payrollReport?.data?.filter((payrolll) =>
      Object.keys(payrollsToSubmit)?.includes(payrolll?.user?.id)
    );

    let PaymentArgs = [];
    data?.payrollReport?.data.forEach((record) => {
      record?.details?.forEach((detail) => {
        const is_commission = JSON.parse(detail)?.IS_COMMESSION;
        if (
          payrollsToSubmit?.[record?.user?.id]?.includes(
            JSON.parse(detail)?.FROM
          ) ||
          payrollsToSubmit?.[JSON.parse(detail)?.ID]?.includes(
            JSON.parse(detail)?.FROM
          )
        ) {
          PaymentArgs.push({
            user_id: record?.user?.id,
            paid_at: record?.paid_at,
            from: is_commission ? null : JSON.parse(detail)?.FROM,
            status: "Paid",
            submittingValue: JSON.parse(detail)?.TOTAL?.toString(),
            commission_id: JSON.parse(detail)?.ID,
          });
        }
      });
    });

    attemptSubmitPayrolls({
      variables: {
        PaymentArgs,
      },
    });
  };

  const handleRevertPayment = (payroll) => {
    Swal.fire({
      icon: "warning",
      text: t("are you sure"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
      },
    }).then((values) => {
      if (values.isConfirmed) {
        let PaymentArgs = [];
        setRevertLoadingId(JSON.stringify(payroll));

        if (payroll?.is_commission) {
          PaymentArgs = [
            {
              user_id: payroll?.user?.id,
              paid_at: payroll?.paid_at,
              from: null,
              submittingValue: payroll?.payable_amount?.toString(),
              status: "Pending",
              commission_id: JSON.parse(payroll.id)?.commission_id,
            },
          ];
        } else {
          PaymentArgs = payroll?.details?.map((detail) => ({
            user_id: payroll?.user?.id,
            paid_at: payroll?.paid_at,
            from: JSON.parse(detail)?.FROM,
            submittingValue: JSON.parse(detail)?.TOTAL?.toString(),
            status: "Pending",
          }));
        }

        attemptSubmitPayrolls({
          variables: {
            PaymentArgs,
          },
        });
      }
    });
  };

  // (End) Update Payroll Payemnet

  function roundToTwo(num) {
    // return +(Math.round(num + "e+2") + "e-2");
    return num.toFixed(2);
  }

  const handleShowExportPayslips = () => {
    const rowsToExport = data?.payrollReport?.data?.filter((row) => {
      return payrollsToSubmit.hasOwnProperty(row?.user?.id);
    });

    let arrayToSubmit = [];

    rowsToExport?.forEach((row) => {
      if (!payrollsToSubmit?.hasOwnProperty(row?.user?.id)) return;

      if (row?.details) {
        // parent case
        row?.details?.forEach((detail) => {
          const from = JSON.parse(detail)?.FROM;
          if (payrollsToSubmit?.[row?.user?.id]?.includes(from)) {
            arrayToSubmit.push({
              user_id: row?.user?.id,
              from,
              type: isMonthly ? "Monthly" : "Weekly",
              paid_at: row?.paid_at,
              month_year: row?.paid_at,
            });
          }
        });
      } else {
        // child case
        const from = row?.from;
        if (payrollsToSubmit?.[row?.user?.id]?.includes(from)) {
          arrayToSubmit.push({
            user_id: row?.user?.id,
            from,
            type: isMonthly ? "Monthly" : "Weekly",
            paid_at: row?.paid_at,
            month_year: row?.paid_at,
          });
        }
      }
    });

    exportPayslips({
      variables: {
        input: arrayToSubmit,
      },
      onCompleted: (data) => {
        showToast(
          data?.export_payslip_report.status,
          data?.export_payslip_report.message
        );
        HelperFns.downloadFile(data?.export_payslip_report?.file);
        setPayrollsToSubmit({});
      },
    });
  };

  const handleShowExportPayroll = () => {
    getExportPayrollOptions();
  };

  const disableSubmitPaymentButton =
    JSON.stringify(payrollsToSubmit) == JSON.stringify({}) ||
    (submitPayrollsLoading && !revertLoadingId) ||
    filters?.status === "paid";

  const allPayrollsSelected =
    data?.payrollReport.data?.length &&
    data?.payrollReport.data
      ?.filter((payrolll) =>
        payrolll?.details
          ?.map((el) => JSON.parse(el))
          ?.find((el) =>
            filters?.status?.toLowerCase() == "paid"
              ? el?.STATUS?.toLowerCase() != "pending"
              : el?.STATUS?.toLowerCase() == "pending"
          )
      )
      ?.every((payroll) => {
        return (
          payrollsToSubmit.hasOwnProperty(payroll?.user?.id) &&
          payrollsToSubmit?.[payroll?.user?.id]?.length &&
          payroll?.details
            ?.filter((detail) =>
              filters?.status?.toLowerCase() == "paid"
                ? JSON.parse(detail)?.STATUS?.toLowerCase() != "pending"
                : JSON.parse(detail)?.STATUS?.toLowerCase() == "pending"
            )
            ?.every((detail) =>
              payrollsToSubmit?.[payroll?.user?.id]?.includes(
                JSON.parse(detail)?.FROM
              )
            )
        );
      });

  const handlePayrollCheckbox = (e) => {
    if (e?.name == "select-all-rows") {
      if (allPayrollsSelected) {
        setPayrollsToSubmit({});
      } else {
        // code to select all payrolls (that is pending)
        if (data?.payrollReport.data?.length) {
          data?.payrollReport.data
            ?.filter((payrolll) =>
              payrolll?.details
                ?.map((el) => JSON.parse(el))
                ?.find((el) =>
                  filters?.status?.toLowerCase() == "paid"
                    ? el?.STATUS?.toLowerCase() != "pending"
                    : el?.STATUS?.toLowerCase() == "pending"
                )
            )
            .forEach((payroll) => {
              setPayrollsToSubmit((prev) => ({
                ...prev,
                [payroll?.user?.id]: payroll?.details?.map(
                  (detail) => JSON.parse(detail)?.FROM
                ),
              }));
            });
        }
      }
      return;
    }

    const payrollDetails = JSON.parse(e?.name?.slice(11, e?.name?.length));

    if (payrollDetails?.isParent) {
      // has many froms as it's the parent
      if (
        // check if it's not selected
        !payrollsToSubmit.hasOwnProperty(payrollDetails?.userId) ||
        (payrollsToSubmit.hasOwnProperty(payrollDetails?.userId) &&
          !JSON.parse(payrollDetails?.froms)?.every((el) =>
            payrollsToSubmit?.[payrollDetails?.userId]?.includes(el)
          )) ||
        !payrollsToSubmit.hasOwnProperty(payrollDetails?.userId) ||
        (payrollsToSubmit.hasOwnProperty(payrollDetails?.userId) &&
          !JSON.parse(payrollDetails?.froms)?.every((el) =>
            payrollsToSubmit?.[payrollDetails?.userId]?.includes(el)
          ))
      ) {
        setPayrollsToSubmit((prev) => ({
          ...prev,
          ...(payrollDetails?.commission_id
            ? {
                [payrollDetails?.commission_id]: JSON.parse(
                  payrollDetails?.froms
                ),
              }
            : { [payrollDetails?.userId]: JSON.parse(payrollDetails?.froms) }),
        }));
      } else {
        setPayrollsToSubmit((prev) => {
          const newObj = { ...prev };
          if (newObj?.[payrollDetails?.userId]) {
            delete newObj?.[payrollDetails?.userId];
          }
          if (newObj?.[payrollDetails?.commission_id]) {
            delete newObj?.[payrollDetails?.commission_id];
          }
          return newObj;
        });
      }
    } else {
      // check if it's not selected
      if (
        // checked statement
        !payrollsToSubmit.hasOwnProperty(payrollDetails?.commission_id) ||
        (payrollsToSubmit.hasOwnProperty(payrollDetails?.commission_id) &&
          !payrollsToSubmit?.[payrollDetails?.commission_id]?.find(
            (el) => el == payrollDetails?.from
          )) ||
        !payrollsToSubmit.hasOwnProperty(payrollDetails?.userId) ||
        (payrollsToSubmit.hasOwnProperty(payrollDetails?.userId) &&
          !payrollsToSubmit?.[payrollDetails?.userId]?.find(
            (el) => el == payrollDetails?.from
          ))
      ) {
        if (
          payrollsToSubmit.hasOwnProperty(payrollDetails?.userId) ||
          payrollsToSubmit.hasOwnProperty(payrollDetails?.commission_id)
        ) {
          setPayrollsToSubmit((prev) => ({
            ...prev,
            ...(payrollsToSubmit.hasOwnProperty(payrollDetails?.userId) && {
              [payrollDetails?.userId]: [
                ...new Set([
                  ...prev?.[payrollDetails?.userId],
                  payrollDetails?.from,
                ]),
              ],
            }),
            ...(payrollsToSubmit.hasOwnProperty(
              payrollDetails?.commission_id
            ) && {
              [payrollDetails?.commission_id]: [
                ...new Set([
                  ...prev?.[payrollDetails?.commission_id],
                  payrollDetails?.from,
                ]),
              ],
            }),
          }));
        } else {
          setPayrollsToSubmit((prev) => ({
            ...prev,
            ...(payrollDetails?.commission_id
              ? { [payrollDetails?.commission_id]: [payrollDetails?.from] }
              : { [payrollDetails?.userId]: [payrollDetails?.from] }),
          }));
        }
      } else {
        setPayrollsToSubmit((prev) => {
          if (prev?.[payrollDetails?.userId]?.length == 1) {
            delete prev?.[payrollDetails?.userId];
            return prev;
          } else {
            return {
              ...prev,
              ...(payrollDetails?.commission_id
                ? {
                    [payrollDetails?.commission_id]: prev?.[
                      payrollDetails?.commission_id
                    ]?.filter((el) => el != payrollDetails?.from),
                  }
                : {
                    [payrollDetails?.userId]: prev?.[
                      payrollDetails?.userId
                    ]?.filter((el) => el != payrollDetails?.from),
                  }),
            };
          }
        });
      }
    }
  };

  const startOfSelectedMonth = moment(filters?.month, [
    "MM-YYYY",
    "DD-MM-YYYY",
    "YYYY-MM-DD",
  ]).startOf("month");

  const endOfSelectedMonth = moment(filters?.month, [
    "MM-YYYY",
    "DD-MM-YYYY",
    "YYYY-MM-DD",
  ]).endOf("month");

  // const weekOptions = weekData?.weeks?.filter((week) => {
  //   let { from } = week;
  //   return (
  //     moment(from, "YYYY-MM-DD") >= startOfSelectedMonth &&
  //     moment(from, "YYYY-MM-DD") <= endOfSelectedMonth
  //   );
  // });

  const handlePayrollFilter = (_, value) => {
    setIsExpected(value);
  };

  const isParentCheckboxSelected = (e) => {
    if (e?.name == "select-all-rows") {
      return allPayrollsSelected;
    }

    const payrollDetails = JSON.parse(e?.name?.slice(11, e?.name?.length));

    return payrollsToSubmit.hasOwnProperty(payrollDetails?.userId) &&
      JSON.parse(payrollDetails?.froms)?.every((el) =>
        payrollsToSubmit?.[payrollDetails?.userId]?.includes(el)
      )
      ? true
      : false;
  };

  const isChildCheckboxSelected = (e) => {
    const payrollDetails = JSON.parse(e?.name?.slice(11, e?.name?.length));

    if (payrollDetails?.commission_id) {
      return payrollsToSubmit.hasOwnProperty(payrollDetails?.commission_id) &&
        payrollsToSubmit?.[payrollDetails?.commission_id]?.includes(
          payrollDetails?.from
        )
        ? true
        : false;
    } else {
      return payrollsToSubmit.hasOwnProperty(payrollDetails?.userId) &&
        payrollsToSubmit?.[payrollDetails?.userId]?.includes(
          payrollDetails?.from
        )
        ? true
        : false;
    }
  };

  const ExpandedRow = ({ data: parentPayroll }) => {
    const payrolls = parentPayroll?.details?.map((stringifiedPayroll) => {
      const childPayroll = JSON.parse(stringifiedPayroll);

      const result = normalizeChildrenPayroll(parentPayroll, childPayroll);

      return {
        id: JSON.stringify({
          commission_id: result?.commission_id,
          userId: result?.user?.id,
          from: result?.from,
          isParent: false,
        }),
        ...result,
      };
    });

    return (
      <DataTable
        className="requests-profile-data-list payroll_datatable_wrapper_style"
        columns={columns}
        data={payrolls}
        selectableRows
        clearSelectedRows={loading}
        // selectableRowDisabled={(row) =>
        //   row?.details?.every(
        //     (el) => JSON.parse(el ?? "")?.STATUS?.toLowerCase() == "paid"
        //   )
        // }
        selectableRowsComponentProps={(row) => row}
        selectableRowsComponent={forwardRef((e, d) => {
          if (!e.disabled) {
            return (
              <div className="mr-2">
                <CheckboxBooleanForm
                  formName={dataTableRef}
                  options={[""]}
                  name={e.name}
                  type="checkbox"
                  labelStyle="checkbox"
                  containerStyle="containerStyle"
                  optionsContainerStyle="d-flex flex-column"
                  optionItemStyle="optionItemStyle mr-0"
                  optionInputStyle="optionInputStyle containerStyle checkbox fix-checkbox-payroll-datable-responsive"
                  optionLabelStyle="optionLabelStyle ml-4 my-1 w-100"
                  optionLabelActiveStyle="optionLabelActiveStyle"
                  {...e}
                  checked={isChildCheckboxSelected(e)}
                  onChange={() => handlePayrollCheckbox(e)}
                />
              </div>
            );
          } else {
            return (
              <div className="fix-checkbox-payroll-datable-responsive"></div>
            );
          }
        })}
        noHeader
        noTableHead
        expandableRows
        expandableIcon={<></>}
        expandableRowDisabled={() => true}
      />
    );
  };

  const showTargetToggler = () => {
    if (isMonthly) {
      if (shouldViewNextMonth) {
        return moment().add(1, "month").format("MM") == filters?.month;
      } else {
        return moment().format("MM") == filters?.month;
      }
    } else {
      const selectedWeek = weekData?.weeks?.find(
        (week) => week?.paidAt == filters?.paid_at
      );

      if (selectedWeek) {
        return (
          moment().isSameOrAfter(moment(selectedWeek?.from), "day") &&
          moment().isSameOrBefore(moment(selectedWeek?.paidAt), "day")
        );
      } else {
        return false;
      }
    }
  };

  const handleRefetchPayrollAndPenalties = () => {
    refetchPayrollList();
    handleViewDetails(penaltyViewDetails);
  };

  const isExportPayrollButtonDisabled =
    exportPayrollOptionsLoading || data?.payrollReport?.data?.length == 0;

  const isExportPayslipsButtonDisabled =
    exportPayslipsLoading ||
    data?.payrollReport?.data?.length == 0 ||
    filters?.status?.toLowerCase() != "paid" ||
    JSON.stringify(payrollsToSubmit) === JSON.stringify({});

  useEffect(() => {
    if (shouldFetch) {
      handelFetchWeeklyPayrollData();
      setShouldFetch(false);
    }
  }, [weeklyPayrollQueryVariables, isMonthly]);

  const renderSelectedFilters = () => {
    const selectedOffice = [
      ...(isMonthly ? [{ name: t("all offices"), id: "" }] : []),
      ...(payrollFiltersData?.payrollReportOfficesFilter?.data ?? []),
    ].filter((off) => off.id === (payrollListVars?.office_ids?.[0] ?? ""));

    const selectedCurrency =
      payrollFiltersData?.payrollReportCurrenciesFilter?.filter(
        (cur) => cur.id === payrollListVars?.currency_id
      );

    const selectedDepartments =
      payrollFiltersData?.company_departments?.data?.filter(
        (dep) => dep.id === payrollListVars?.department_id
      );

    const selectedEmployees =
      payrollFiltersData?.payrollReportEmployeesFilter?.data?.filter(
        (emp) => emp?.id === payrollListVars?.user_id
      );

    const employeeSearch = payrollListVars?.employee_search
      ? [{ name: payrollListVars?.employee_search }]
      : [];

    const onCancel = (name, emptyVal = null) => {
      setFilters((prev) => ({ ...prev, [name]: emptyVal }));
      setShouldFetch(true);
    };

    const cancelIsExpected = () => {
      setIsExpected(false);
      setShouldFetch(true);
    };

    const cancelIsExternal = () => {
      setIsExternal(false);
      setShouldFetch(true);
    };

    return (
      <div className="d-flex align-items-center flex-wrap gap-1 mb-2 flex-grow-1">
        <FilterBadge
          label={t("employee search")}
          selectedItems={employeeSearch}
          onCancelFilter={() => onCancel("employee_search")}
        />

        <FilterBadge
          label={t("year")}
          selectedItems={
            payrollListVars?.paid_at && isMonthly
              ? [
                  {
                    name: moment(payrollListVars?.paid_at, "YYYY-MM-DD").format(
                      "YYYY"
                    ),
                  },
                ]
              : []
          }
          hideCancel
        />
        <FilterBadge
          label={t("month")}
          selectedItems={
            payrollListVars?.paid_at && isMonthly
              ? [
                  {
                    name: moment(payrollListVars?.paid_at, "YYYY-MM-DD").format(
                      "MMMM"
                    ),
                  },
                ]
              : []
          }
          hideCancel
        />

        <FilterBadge
          label={t("week")}
          selectedItems={
            payrollListVars?.paid_at && !isMonthly
              ? [
                  {
                    name: payrollListVars?.paid_at,
                  },
                ]
              : []
          }
          hideCancel
        />
        <FilterBadge
          label={t("office")}
          selectedItems={selectedOffice}
          hideCancel
        />
        <FilterBadge
          label={
            isMonthly ? t("monthly payroll view") : t("weekly payroll view")
          }
          selectedItems={payrollListVars?.isExpected ? [{}] : []}
          onCancelFilter={() => cancelIsExpected("isExpected", false)}
        />
        <FilterBadge
          label={t("currency")}
          selectedItems={selectedCurrency}
          onCancelFilter={() => onCancel("currency_id")}
        />
        <FilterBadge
          label={t("departments")}
          selectedItems={selectedDepartments}
          onCancelFilter={() => onCancel("department_id")}
        />
        <FilterBadge
          label={t("status")}
          selectedItems={
            payrollListVars?.status === "Pending"
              ? []
              : [
                  {
                    name: payrollListVars?.status
                      ? t(payrollListVars?.status)
                      : t("all"),
                  },
                ]
          }
          onCancelFilter={() => onCancel("status")}
        />
        <FilterBadge
          label={t("employee")}
          selectedItems={selectedEmployees}
          onCancelFilter={() => onCancel("user_id")}
        />
        <FilterBadge
          label={t("Show Externally Managed Employees")}
          selectedItems={payrollListVars?.isExternal ? [{}] : []}
          onCancelFilter={cancelIsExternal}
        />
      </div>
    );
  };

  const handelClearFilters = () => {
    onFilterReset();
    setShouldFetch(true);
  };

  const applied_FiltersList_length =
    (payrollListVars?.office_id ? 1 : 0) +
    (payrollListVars?.currency_id ? 1 : 0) +
    (payrollListVars?.department_id ? 1 : 0) +
    (payrollListVars?.user_id ? 1 : 0) +
    (payrollListVars?.isExternal ? 1 : 0) +
    (payrollListVars?.isExpected ? 1 : 0) +
    (payrollListVars?.employee_search ? 1 : 0) +
    (payrollListVars?.status === "Pending" ? 0 : 1);

  const interceptChange = (name, value) => {
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
    return true;
  };
  const handelRadioBox = (e, val) => {
    setFilters((prev) => ({ ...prev, [e?.target?.name]: val }));
    return true;
  };

  // handle employee search input onChange function
  const handleInputSearchOnChange = (e) => {
    setFilters((prev) => ({ ...prev, [e?.target?.name]: e?.target?.value }));
    return true;
  };

  return (
    <>
      {payrollDetailsLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <HasPrivileges
        reqireMain={[Privilages.VIEW_PAYROLL_LIST]}
        allowBP
        altChildren={
          <div className="row align-items-center justify-content-end">
            <div className="w-25 mb-3 pr-3">
              <BSelect
                name="year"
                optionLabel="value"
                optionValue="value"
                options={Constants.YearData}
                formName={dataTableRef}
                keepDefaultStyle
                containerStyle="year-picker"
                icon="calendar"
              />
            </div>
          </div>
        }
      >
        <>
          {/* when un commenting don't forget to uncomment query fields */}
          <div
            className={`d-flex justify-content-end gap-5 w-100 flex-wrap-reverse ${
              isMonthly ? "" : "mb-3"
            } `}
          >
            {renderSelectedFilters()}

            <div className="d-flex gap-1 align-items-center mb-2">
              {applied_FiltersList_length ? (
                <div
                  onClick={handelClearFilters}
                  className="logs-btn clear-btn"
                >
                  <ClearIcon color="inherit" fontSize="small" />
                  {t("clear")}
                </div>
              ) : null}
              <div
                onClick={toggleDrawer(true)}
                className={`logs-btn ${
                  applied_FiltersList_length
                    ? "filter-btn-active"
                    : "filter-btn-inactive"
                }`}
              >
                <FontAwesomeIcon icon={faSlidersH} />
                {t("filters")}
                {applied_FiltersList_length ? (
                  <div className="filter-count-container">
                    {t(applied_FiltersList_length)}
                  </div>
                ) : null}
              </div>
              <HasPrivileges
                reqireMain={[Privilages.EXPORT_PAYROLL_TABLE]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div>
                    <button
                      className="btn add_new_btn_style export_btn_style text-nowrap "
                      type="button"
                      disabled
                    >
                      <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                      {t("Export Payslips")}
                    </button>
                  </div>
                }
              >
                <div>
                  <button
                    className="btn add_new_btn_style export_btn_style d-flex align-items-center justify-content-center text-nowrap"
                    type="button"
                    onClick={handleShowExportPayslips}
                    disabled={isExportPayslipsButtonDisabled}
                  >
                    {exportPayslipsLoading ? (
                      <Spinner
                        style={{
                          width: "1rem",
                          height: "1rem",
                          color: "#8997a4",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <>
                        <FontAwesomeIcon className="mr-1" icon={faFileExport} />
                        {t("Export Payslips")}
                      </>
                    )}
                  </button>
                </div>
              </HasPrivileges>
              <HasPrivileges
                reqireMain={[Privilages.EXPORT_PAYROLL_TABLE]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div>
                    <button
                      className="btn add_new_btn_style export_btn_style text-nowrap"
                      type="button"
                      disabled
                    >
                      <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                      {t("export payroll")}
                    </button>
                  </div>
                }
              >
                <div>
                  <button
                    className="btn add_new_btn_style export_btn_style d-flex align-items-center justify-content-center text-nowrap"
                    type="button"
                    onClick={handleShowExportPayroll}
                    disabled={isExportPayrollButtonDisabled}
                  >
                    {exportPayrollOptionsLoading ? (
                      <Spinner
                        style={{
                          width: "1rem",
                          height: "1rem",
                          color: "#8997a4",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <>
                        <FontAwesomeIcon className="mr-1" icon={faFileExport} />
                        {t("export payroll")}
                      </>
                    )}
                  </button>
                </div>
              </HasPrivileges>

              <HasPrivileges
                reqireMain={[Privilages.SUBMIT_PAYMENTS]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div>
                    <button
                      className="btn add_new_btn_style ml-3 text-nowrap"
                      type="button"
                      disabled
                    >
                      {t("Submit Payment")}
                    </button>
                  </div>
                }
              >
                <Tooltip
                  disableHoverListener={!disableSubmitPaymentButton}
                  title={t("select payrolls to submit first")}
                >
                  <button
                    className="btn add_new_btn_style ml-1 d-flex align-items-center justify-content-center text-nowrap"
                    type="button"
                    onClick={handleSubmitPayment}
                    disabled={disableSubmitPaymentButton}
                  >
                    {submitPayrollsLoading && !revertLoadingId ? (
                      <Spinner
                        style={{
                          width: "1rem",
                          height: "1rem",
                          color: "#fff",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      t("Submit Payment")
                    )}
                  </button>
                </Tooltip>
              </HasPrivileges>
            </div>
          </div>
        </>
      </HasPrivileges>

      {!!selectedCurrency && (
        <div className="row mb-3">
          <div className="col-xl-4 col-12">
            <PayrollWidget
              WidgetIcon="total_net.svg"
              title={t("total net pay")}
              currency={selectedCurrency?.name}
              amount={totalPayrollAmount}
              WidgetStatusColor="total_net_pay"
            />
          </div>
          <div className="col-xl-4 col-12">
            <PayrollWidget
              WidgetIcon="total_paid.svg"
              title={t("total paid")}
              currency={selectedCurrency?.name}
              amount={PaidPayrollAmount}
              WidgetStatusColor="total_paid"
            />
          </div>
          <div className="col-xl-4 col-12">
            <PayrollWidget
              WidgetIcon="pending.svg"
              title={t("pending payment")}
              currency={selectedCurrency?.name}
              amount={PendingPayrollAmount}
              WidgetStatusColor="pending_payment"
            />
          </div>
        </div>
      )}

      <DataTable
        className="requests-profile-data-list payroll_main_datatable_wrapper_style"
        columns={groupColumns}
        data={[]}
        noHeader
        persistTableHead
        customStyles={{
          noData: "d-flex flex-column",
        }}
        noDataComponent={
          <DataTable
            noDataComponent={<div className="p-4"> {t("no_records")} </div>}
            className="requests-profile-data-list payroll_datatable_wrapper_style"
            columns={columns}
            data={data?.payrollReport.data?.map((payroll) => ({
              id: JSON.stringify({
                userId: payroll?.user?.id,
                froms: JSON.stringify(
                  payroll?.details
                    // ?.filter(
                    //   (payroll) =>
                    //     JSON.parse(payroll)?.STATUS?.toLowerCase() == "pending"
                    // )
                    ?.map((detail) => JSON.parse(detail)?.FROM)
                ),
                isParent: true,
              }),
              ...payroll,
            }))}
            selectableRows
            // selectableRowDisabled={(row) => payrollList?.status === "paid"}
            clearSelectedRows={loading}
            selectableRowsComponentProps={(row) => row}
            expandableRowExpanded={(row) =>
              !!row?.details && row?.details?.length > 1
            }
            selectableRowsComponent={forwardRef((e, d) => {
              if (!e.disabled) {
                return (
                  <div className="mr-2">
                    <CheckboxBooleanForm
                      formName={dataTableRef}
                      options={[""]}
                      name={e.name}
                      type="checkbox"
                      labelStyle="checkbox"
                      containerStyle="containerStyle"
                      optionsContainerStyle="d-flex flex-column"
                      optionItemStyle="optionItemStyle mr-0"
                      optionInputStyle="optionInputStyle containerStyle checkbox fix-checkbox-payroll-datable-responsive"
                      optionLabelStyle="optionLabelStyle ml-4 my-1 w-100"
                      optionLabelActiveStyle="optionLabelActiveStyle"
                      {...e}
                      onChange={() => handlePayrollCheckbox(e)}
                      checked={isParentCheckboxSelected(e)}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="fix-checkbox-payroll-datable-responsive"></div>
                );
              }
            })}
            noHeader
            persistTableHead
            sortServer
            defaultSortAsc={payrollList.sorting.dir == "asc"}
            defaultSortField={payrollList.sorting.key}
            paginationComponent={() => (
              <Pagination
                styleWraper=""
                onPaginate={handlePaginate}
                customPaginator={pagination}
              />
            )}
            pagination={true}
            paginationServer={true}
            progressPending={loading}
            progressComponent={<Loader />}
            expandableRows={true}
            expandableRowDisabled={(row) => row?.details?.length < 2}
            expandableRowsComponent={<ExpandedRow />}
            expandableIcon={{
              expanded: <FontAwesomeIcon icon={faAngleUp} />,
              collapsed: <FontAwesomeIcon icon={faAngleDown} />,
            }}
          />
        }
      />

      {/* Start of Export Modal */}
      {/* <ExportModal
        modal_isOpen={showExportModal}
        modal_toggle={toggleShowExportModal}
        dataTableRef={dataTableRef}
      /> */}
      {/* End of Export Modal  */}

      {/* Start of Payslip Modal */}
      <PayslipModal
        paySlipData={payslipDetailsData?.usersPaySlip}
        isPayslipLoading={userPayslipLoading}
        modal_isOpen={showPayslipModal}
        modal_toggle={toggleHidePayslipModal}
        type={isMonthly ? "Monthly" : "Weekly"}
        paidAt={payrollDetailsForPaySlip?.paid_at}
        from={payrollDetailsForPaySlip?.from}
      />
      {/* End of Payslip Modal  */}

      {/* Strt of View payroll cell details  */}
      <ViewWeeklyPayrollDetails
        isModalVissible={isDetailsModalOpen}
        dismissModal={dismissDetailsModal}
        data={detailsModalData}
        refetchPayrollAndPenalties={handleRefetchPayrollAndPenalties}
        isLoading={
          loadingOvertimeDetails ||
          loadingBonusDetails ||
          loadingPenaltyDetails ||
          loadingOtherDeductDetails ||
          loadingOtherAddedDetails ||
          loadingAllowancesDetails
        }
        isManager={true}
      />

      {editAmountModal?.isOpen ? (
        <EditWeeklyDeductiblesModal
          isOpen={editAmountModal?.isOpen}
          title={editAmountModal?.title}
          userId={editAmountModal?.userId}
          paidAt={editAmountModal?.paidAt}
          from={editAmountModal?.from}
          oldAmount={editAmountModal?.oldAmount}
          deductibleType={editAmountModal?.deductibleType}
          currency={editAmountModal?.currency}
          handleCloseModal={handleCloseEditAmountModal}
          payrollType={isMonthly ? "Monthly" : "Weekly"}
          refetchQueries={[
            {
              query: weeklyPayrollQuery,
              variables: weeklyPayrollQueryVariables,
            },
          ]}
        />
      ) : null}

      {/* End of Edit amount  */}
      {isExportPayrolModalOpen ? (
        <ExportPayrollModal
          type={isMonthly ? "Monthly" : "Weekly"}
          paidAt={data?.payrollReport?.data?.[0]?.paid_at}
          officeId={filters?.office_id}
          departmentId={filters?.department_id}
          currencyId={filters?.currency_id}
          status={
            filters?.status === "unpaid"
              ? "Pending"
              : filters?.status === "paid"
              ? "Paid"
              : undefined
          }
          userId={filters?.user_id}
          isExpected={isExpected}
          isExternal={isExternal}
          payrollFiler={filters}
        />
      ) : null}
      <EditPenaltyModal refetchList={handleRefetchPayrollAndPenalties} />

      <FiltersDrawer
        isFilterDrawerOpen={isFilterDrawerOpen}
        toggleDrawer={toggleDrawer}
        onFilterReset={onFilterReset}
        onFilterSubmit={onFilterSubmit}
        dataTableRef={dataTableRef}
        hideFromTo
        hideEmployees
        hideDepartments
        hidePositions
        hideOffices
      >
        <InputForm
          icon="search"
          value={filters?.employee_search?.trimStart()}
          formName={dataTableRef}
          name="employee_search"
          inputStyle="border-0"
          placeholder={t("employee search")}
          inputContainerStyle="w-100"
          onChange={(e) => handleInputSearchOnChange(e)}
          rootStyle="boxContainer shadow-sm flex-fill px-2 m-0 py-0"
        />

        <NewMultiSelect
          isLoading={false}
          options={
            Constants.YearData?.map((y) => ({ ...y, name: `${y.value}` })) ?? []
          }
          value={filters.year}
          dataTableRef={dataTableRef}
          name="year"
          label={t("year")}
          icon="calendar"
          placeHolder={t("year")}
          optionLabel="name"
          optionValue="value"
          isSingle
          disableClear
          onIntercept={(name, opt) => interceptChange(name, opt?.value)}
        />

        <NewMultiSelect
          isLoading={false}
          options={
            Constants.MonthData?.map((m) => ({ ...m, name: `${m.value}` })) ??
            []
          }
          value={filters.month}
          dataTableRef={dataTableRef}
          name="month"
          label={t("month")}
          icon="calendar"
          placeHolder={t("month")}
          optionLabel="name"
          optionValue="key"
          isSingle
          disableClear
          onIntercept={(name, opt) => interceptChange(name, opt?.key)}
        />

        {!isMonthly ? (
          <>
            <NewMultiSelect
              isLoading={false}
              options={weekData?.weeks ?? []}
              value={filters.paid_at}
              dataTableRef={dataTableRef}
              name="paid_at"
              label={t("week")}
              icon="calendar"
              placeHolder={t("week")}
              optionLabel="name"
              optionValue="paidAt"
              isSingle
              disableClear
              onIntercept={(name, opt) => interceptChange(name, opt?.paidAt)}
            />
          </>
        ) : null}

        <NewMultiSelect
          isLoading={false}
          options={[
            ...(isMonthly ? [{ name: t("all offices"), id: "" }] : []),
            ...(payrollFiltersData?.payrollReportOfficesFilter?.data ?? []),
          ]}
          value={filters.office_id}
          dataTableRef={dataTableRef}
          name="office_id"
          label={t("Offices")}
          icon="office"
          placeHolder={t("Offices")}
          optionLabel="name"
          optionValue="id"
          isSingle
          disableClear
          onIntercept={(name, opt) => interceptChange(name, opt?.id)}
        />

        {showTargetToggler() ? (
          <div className="w-100 d-flex align-items-center justify-content-between">
            <label className="mx-2">
              {isMonthly ? t("monthly payroll view") : t("weekly payroll view")}
            </label>
            <AntSwitch
              name="isExpected"
              onChange={handlePayrollFilter}
              checked={isExpected}
            />
          </div>
        ) : null}
        <NewMultiSelect
          isLoading={false}
          options={payrollFiltersData?.payrollReportCurrenciesFilter ?? []}
          value={filters.currency_id}
          dataTableRef={dataTableRef}
          name="currency_id"
          label={t("currencies")}
          placeHolder={t("currencies")}
          optionLabel="name"
          optionValue="id"
          isSingle
          onIntercept={(name, opt) => interceptChange(name, opt?.id)}
        />
        <NewMultiSelect
          isLoading={false}
          options={payrollFiltersData?.company_departments?.data ?? []}
          value={filters.department_id}
          dataTableRef={dataTableRef}
          name="department_id"
          label={t("departments")}
          placeHolder={t("select department")}
          optionLabel="name"
          optionValue="id"
          icon="building"
          isSingle
          onIntercept={(name, opt) => interceptChange(name, opt?.id)}
        />
        <RadioboxForm
          formName={dataTableRef}
          options={Constants.PayrollStatusFilter}
          name="status"
          type="radio"
          containerStyle="my-2 my-lg-0"
          labelStyle="label-style"
          optionsContainerStyle="optionsContainerStyle flex-nowrap"
          optionItemStyle="optionItemStyle payroll_option_items"
          optionInputStyle=" "
          optionLabelStyle="optionLabelStyle"
          interceptChange={handelRadioBox}
          value={filters.status}
        />
        <NewMultiSelect
          isLoading={false}
          options={payrollFiltersData?.payrollReportEmployeesFilter?.data ?? []}
          value={filters.user_id}
          dataTableRef={dataTableRef}
          name="user_id"
          label={t("employees")}
          placeHolder={t("select employee")}
          optionLabel="name"
          optionValue="id"
          icon="person"
          isSingle
          onIntercept={(name, opt) => interceptChange(name, opt?.id)}
        />
        <div className="w-100 d-flex align-items-center justify-content-between">
          <label className="mr-1">
            {t("show externally managed employees")}
          </label>
          <AntSwitch
            name="isExternal"
            onChange={handleToggleShowExternal}
            checked={isExternal}
          />
        </div>
      </FiltersDrawer>
    </>
  );
};

export default PayrollView;

const PAY_PARTIALLY = gql`
  mutation payPartially($user_id: Int, $paid_at: String, $amount: String) {
    payroll_v2_pay_partially(
      user_id: $user_id
      paid_at: $paid_at
      amount: $amount
    )
  }
`;

const PaidAmountCell = ({ row, refetchPayrollList }) => {
  // Local State
  const [editPartially, setEditPartially] = useState(false);
  const [partiallyAmount, setPartiallyAmount] = useState("");

  // Server State
  const [payPartially, { loading: payPartiallyLoading }] =
    useMutation(PAY_PARTIALLY);

  /* ↓ Helpers ↓ */

  const handlePayPartially = (row) => {
    const user_id = +safeJsonParse(row?.id)?.userId;
    payPartially({
      variables: {
        user_id,
        paid_at: row.paid_at,
        amount: partiallyAmount,
      },
      onCompleted: () => {
        refetchPayrollList();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  if (payPartiallyLoading) return <CircularProgress size={20} />;

  return (
    <Box
      className="d-flex gap-5"
      sx={{ button: { padding: 0 }, svg: { fontSize: 13 } }}
    >
      {editPartially ? (
        <>
          <TextField
            size="small"
            type="number"
            disabled={payPartiallyLoading}
            value={partiallyAmount || row?.paid || 0}
            onChange={(e) => setPartiallyAmount(e.target.value)}
          />

          <AcceptIconButton onClick={() => handlePayPartially(row)} />
          <TimesIconButton
            label="Cancel"
            onClick={() => setEditPartially(false)}
          />
        </>
      ) : (
        <>
          {row?.paid}
          {row?.is_commission ? null : (
            <EditIconButton onClick={() => setEditPartially(true)} />
          )}
        </>
      )}
    </Box>
  );
};
