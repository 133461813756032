import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import moment from "moment";
import gql from "graphql-tag";
import swal from "sweetalert";
import Privileges from "../../Constants/Privilages";
import HelperFns, { showToast } from "../../Helpers/HelperFns";

import IconButtonWithTooltip, {
  EditIconButton,
  RemoveIconButton,
  TimesIconButton,
} from "../../Components/IconButtonWithTooltip";
import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
} from "form-builder";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { AddButton } from "../../Components/Buttons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginOnBehalfButton from "../Companies/LoginOnBehalfButton";
import { CompanyUpsertModal } from "../../Components/AdminPanelModals";
import { faPauseCircle, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { PauseCircleOutline, PlayCircleOutline } from "@mui/icons-material";

/**
 *
 * Queries & Mutations
 *
 */

const GET_COMPANIES = gql`
  query getCompanies(
    $page: Int
    $first: Int!
    $isSuper: Boolean!
    $input: CompanyFilterInput
  ) {
    companies(page: $page, first: $first, input: $input)
      @include(if: $isSuper) {
      data {
        id
        name
        usersCount
        primarySAAS {
          user {
            id
            name
          }
        }
        secondarySAAS {
          user {
            id
            name
          }
        }
        allow_am_access
        getCurrentSubcriptionStatus
        canAddNewEmployee
        last_sign_in_created_at
        businessPartners {
          user {
            id
            company {
              id
            }
            name
            email
            phone
            phone_country_code: country_short_name
          }
        }
        activeSubscription: currentSubscription {
          employees_limit
          end_date
        }
        status
        allow_to_use_api
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    saas_companies(page: $page, first: $first, input: $input)
      @skip(if: $isSuper) {
      data {
        id
        name
        usersCount
        primarySAAS {
          user {
            id
            name
          }
        }
        secondarySAAS {
          user {
            id
            name
          }
        }
        allow_am_access
        getCurrentSubcriptionStatus
        last_sign_in_created_at
        businessPartners {
          user {
            id
            company {
              id
            }
            name
            email
            phone
            phone_country_code: country_short_name
          }
        }
        activeSubscription: currentSubscription {
          employees_limit
          end_date
        }
        status
        allow_to_use_api
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;
const GET_COMPANIES_OPTIONS = gql`
  query getCompaniesOptions {
    accountMangers: active_saas_users_menu {
      id
      name
    }
  }
`;

const DELETE_COMPANY = gql`
  mutation deleteCompany($id: ID!) {
    delete_company(id: $id) {
      id
    }
  }
`;
const ACTIVATE_COMPANY = gql`
  mutation activateCompany($id: ID) {
    activate_company(input: { company_id: $id, company_status: activate }) {
      id
    }
  }
`;
const SUSPEND_COMPANY = gql`
  mutation suspendCompany($id: ID) {
    suspend_company(input: { company_id: $id, company_status: suspend }) {
      id
    }
  }
`;

const TOGGLE_API_ACCESS = gql`
  mutation toggleCompanyApiAccess($company_id: ID) {
    allowToUseApi(company_id: $company_id) {
      id
    }
  }
`;
/**
 *
 * Companies List
 *
 */

const sendFilters = ({
  to,
  from,
  isTrial,
  relevantCompaniesIds,
  primaryAccountManager,
  secondaryAccountManager,
  ...filters
}) => {
  return {
    page: 1,
    input: {
      ...filters,
      company_ids: relevantCompaniesIds,
      isTrial: isTrial === "all" ? undefined : Boolean(+isTrial),
      primaryAccountManager: primaryAccountManager?.length
        ? primaryAccountManager.map(({ id }) => id)
        : undefined,
      secondaryAccountManager: secondaryAccountManager?.length
        ? secondaryAccountManager.map(({ id }) => id)
        : undefined,
      subscriptionEndDate:
        from && to
          ? {
              to: moment(to).format("YYYY-MM-DD"),
              from: moment(from).format("YYYY-MM-DD"),
            }
          : undefined,
    },
  };
};

const modalInitState = { isOpen: false, data: null };
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const CompaniesList = ({ relevantCompaniesIds }) => {
  const { t } = useTranslation();
  const isSuper = HelperFns.checkPrivileges({
    privileges: [Privileges.SUPER_PRIVILEGE],
    allowBP: false,
  });

  // Local State
  const [companies, setCompanies] = React.useState([[]]);
  const [modalState, setModalState] = React.useState(modalInitState);
  const [pagination, setPagination] = React.useState(paginationInitState);
  const [filters, setFilters] = React.useState({
    to: "",
    from: "",
    name: "",
    isTrial: "all",
    company_status: "active",
    primaryAccountManager: [],
    secondaryAccountManager: [],
    orderByRelation: { field: "name", direction: "ASC" },
  });

  // Server State
  const [deleteCompany, { loading: deleteCompanyLoading }] =
    useMutation(DELETE_COMPANY);
  const [activateCompany, { loading: activateCompanyLoading }] =
    useMutation(ACTIVATE_COMPANY);
  const [suspendCompany, { loading: suspendCompanyLoading }] =
    useMutation(SUSPEND_COMPANY);
  const { data: options, loading: optionsLoading } = useQuery(
    GET_COMPANIES_OPTIONS
  );
  const { loading, refetch: refetchList } = useQuery(GET_COMPANIES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      isSuper,
      ...sendFilters({ relevantCompaniesIds, ...filters }),
      first: pagination.perPage,
    },
    onCompleted: (res) => {
      const companies = isSuper ? res?.companies : res?.saas_companies;

      setCompanies(companies?.data || []);
      setPagination(companies?.paginatorInfo || paginationInitState);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  const [toggleCompanyApiAccess, { loading: toggleAPIAccessLoading }] =
    useMutation(TOGGLE_API_ACCESS, {
      onCompleted(res) {
        // do someting
        if (res?.allowToUseApi?.id) {
          refetchList();
          showToast("success");
        }
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });

  const handleTogggleAPItBtn = (company_id) => {
    toggleCompanyApiAccess({
      variables: {
        company_id,
      },
    });
  };

  // Constants
  const columns = [
    {
      name: t("company"),
      sortable: true,
      sortField: "name",
      grow: 1.2,
      cell: (row) => {
        return (
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.VIEW_COMPANY_PROFILE,
            ]}
            altChildren={<>{row?.name}</>}
          >
            <Link to={`/admin-panel/company-profile/${row?.id}`}>
              {row?.name}
            </Link>
          </HasPrivileges>
        );
      },
    },
    {
      name: t("business partners"),
      grow: 1.5,
      cell: (row) =>
        !row?.businessPartners?.length ? (
          <></>
        ) : (
          <div>
            {row?.businessPartners?.map((partner) => (
              <div
                key={partner?.user?.id}
                className="d-flex gap-5 align-items-center"
              >
                {partner?.user?.name}
                {row?.allow_am_access ? (
                  <HasPrivileges
                    reqireMain={[Privileges.SIGN_IN_AS_BUSINESS_PARTNER]}
                  >
                    <LoginOnBehalfButton id={partner?.user?.id} />
                  </HasPrivileges>
                ) : null}
              </div>
            ))}
          </div>
        ),
    },
    {
      name: t("primary account manager"),
      grow: 1.5,
      selector: ({ primarySAAS = {} }) => primarySAAS?.user?.name,
    },
    {
      name: t("secondary account managers"),
      grow: 1.5,
      cell: ({ secondarySAAS = [] }) =>
        secondarySAAS?.map((sass) => sass?.user?.name).join(", "),
    },
    {
      name: t("subscription end date"),
      sortable: true,
      sortField: "subscriptions",
      grow: 1.5,
      cell: (row) => (
        <>
          {row?.activeSubscription?.end_date != null
            ? moment(row?.activeSubscription?.end_date).format("DD - MM - YYYY")
            : ""}
        </>
      ),
    },
    {
      name: t("last_sign_in_created_at"),
      sortable: true,
      sortField: "activations",
      grow: 1.5,
      cell: (row) => row?.last_sign_in_created_at,
    },
    {
      name: t("No. Of Employees / Limit"),
      grow: 1.2,
      cell: (row) => {
        const company_employees_count = row?.usersCount;
        const limit = row?.activeSubscription?.employees_limit;
        if (!company_employees_count || !limit) return "";
        return <>{company_employees_count + " / " + limit}</>;
      },
    },
    {
      name: t("status"),
      grow: 1.2,
      cell: (row) => {
        const company_status =
          " / " + (row?.getCurrentSubcriptionStatus || "N/A");
        return row?.status === "suspended"
          ? t("inactive" + company_status)
          : t(row?.status) + company_status;
      },
    },
    {
      name: t("API"),
      selector: "allow_to_use_api",
      grow: 0.8,
      cell: (row) => (
        <HasPrivileges
          altChildren={
            <>
              {row?.allow_to_use_api ? (
                <PauseCircleOutline
                  sx={{ fontSize: 22, color: "#ff6a6a" }}
                  className="pause-icon-style"
                />
              ) : (
                <PlayCircleOutline
                  sx={{ fontSize: 22, color: "#27b40c" }}
                  className="play-icon-style"
                />
              )}
            </>
          }
          reqireMain={[Privileges.SUPER_PRIVILEGE]}
        >
          {row?.allow_to_use_api ? (
            <IconButtonWithTooltip
              label="Disable API"
              onClick={() => handleTogggleAPItBtn(row.id)}
              icon={
                <PauseCircleOutline
                  sx={{ fontSize: 22, color: "#ff6a6a" }}
                  className="pause-icon-style"
                />
              }
            />
          ) : (
            <IconButtonWithTooltip
              label="Enable API"
              onClick={() => handleTogggleAPItBtn(row.id)}
              icon={
                <PlayCircleOutline
                  sx={{ fontSize: 22, color: "#27b40c" }}
                  className="play-icon-style"
                />
              }
            />
          )}
        </HasPrivileges>
      ),
    },
    {
      name: t("actions"),
      grow: 1.1,
      cell: (row) => (
        <div className="cards_table_actions">
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.ADD_EDIT_COMPANY,
            ]}
          >
            <EditIconButton onClick={() => handleEditCompany(row?.id)} />
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.SUSPEND_ACTIVATE_COMPANY,
            ]}
          >
            {row?.status === "active" ? (
              <IconButtonWithTooltip
                label="suspend"
                onClick={() => handleSuspendCompany(row)}
                icon={
                  <FontAwesomeIcon
                    size="sm"
                    color="#ffb700"
                    icon={faPauseCircle}
                  />
                }
              />
            ) : (
              <IconButtonWithTooltip
                label="activate"
                onClick={() => handleActivateCompany(row)}
                icon={
                  <FontAwesomeIcon
                    size="sm"
                    color="#ffb700"
                    icon={faPowerOff}
                  />
                }
              />
            )}
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[Privileges.SUPER_PRIVILEGE, Privileges.DELETE_COMPANY]}
          >
            <RemoveIconButton onClick={() => handleDeleteCompany(row)} />
          </HasPrivileges>
        </div>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handleFilters = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClearFromTo = () => {
    setFilters((prev) => ({ ...prev, from: "", to: "" }));
  };

  const handleSelectFilters = (val, { name }) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleOpenModal = () => {
    setModalState({ isOpen: true, data: null });
  };

  const handleEditCompany = (id) => {
    setModalState({ isOpen: true, data: { id } });
  };

  const handleSuspendCompany = (row) => {
    swal({
      title: t("are you sure"),
      text: t("default_warning_suspension_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willSuspend) => {
      if (willSuspend) {
        suspendCompany({
          variables: { id: row?.id },
          onCompleted: () => {
            refetchList();
            showToast("success");
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  const handleActivateCompany = (row) => {
    swal({
      title: t("are you sure"),
      text: t("default_warning_activation_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willActivate) => {
      if (willActivate) {
        activateCompany({
          variables: { id: row?.id },
          onCompleted: () => {
            refetchList();
            showToast("success");
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  const handleDeleteCompany = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        deleteCompany({
          variables: { id: row?.id },
          onCompleted: () => {
            refetchList();
            showToast("success");
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  const handlePaginate = (page = pagination.currentPage) => {
    refetchList({ page });
  };

  const handleSorting = (row, order) => {
    setFilters((prev) => ({
      ...prev,
      orderByRelation: {
        field: row?.sortField,
        direction: order.toUpperCase(),
      },
    }));
  };

  return (
    <>
      {deleteCompanyLoading ||
      activateCompanyLoading ||
      toggleAPIAccessLoading ||
      suspendCompanyLoading ? (
        <Loader fixed />
      ) : null}

      {/* Filters */}
      <div className="d-flex justify-content-between align-items-center mt-3 gap-20">
        <div className="d-flex flex-column">
          <RadioboxForm
            name="company_status"
            value={filters.company_status}
            onChange={handleFilters}
            options={[
              { label: "active", value: "active" },
              { label: "inactive", value: "suspended" },
              { label: "All", value: "all" },
            ]}
            optionInputStyle=" "
            containerStyle="mb-0"
            optionItemStyle="d-inline-block mx-2"
            optionsContainerStyle="d-flex"
            rootStyle="mb-2"
          />
          <RadioboxForm
            name="isTrial"
            value={filters.isTrial}
            onChange={handleFilters}
            options={[
              { label: "client", value: 0 },
              { label: "trial", value: 1 },
              { label: "All", value: "all" },
            ]}
            optionInputStyle=" "
            containerStyle="mb-0"
            optionItemStyle="d-inline-block mx-2"
            optionsContainerStyle="d-flex gap-10"
          />
        </div>
        <InputForm
          name="name"
          icon="search"
          type="search"
          placeholder="search"
          value={filters.name}
          onChange={handleFilters}
          inputContainerStyle="w-100"
          rootStyle="flex-fill"
        />
        <HasPrivileges
          reqireMain={[Privileges.SUPER_PRIVILEGE, Privileges.ADD_EDIT_COMPANY]}
        >
          <AddButton onClick={handleOpenModal} />
        </HasPrivileges>
      </div>
      <div className="d-flex align-items-end gap-20 mb-4 mt-3">
        <BSelect
          isMulti
          isClearable
          name="primaryAccountManager"
          label="primary Account Manager"
          icon="user"
          placeholder={t("select user")}
          value={filters.primaryAccountManager}
          onChange={handleSelectFilters}
          inputContainerStyle="w-100"
          options={options?.accountMangers || []}
          isLoading={optionsLoading}
          containerStyle=" "
          rootStyle="flex-1"
        />
        <BSelect
          isMulti
          isClearable
          name="secondaryAccountManager"
          label="secondary Account Manager"
          icon="user"
          placeholder={t("select user")}
          value={filters.secondaryAccountManager}
          onChange={handleSelectFilters}
          inputContainerStyle="w-100"
          options={options?.accountMangers || []}
          isLoading={optionsLoading}
          containerStyle=" "
          rootStyle="flex-1"
        />
        <div className="d-flex align-items-end gap-10 flex-1">
          <DateTimePickerForm
            hasIcon
            label="from"
            labelStyle="w-100"
            value={filters.from}
            rootStyle="flex-fill"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("from", val)}
          />
          <DateTimePickerForm
            hasIcon
            label="to"
            labelStyle="w-100"
            value={filters.to}
            rootStyle="flex-fill"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("to", val)}
          />
          {filters?.from && filters?.to ? (
            <TimesIconButton
              label="clear from & to"
              onClick={handleClearFromTo}
            />
          ) : null}
        </div>
      </div>

      {/* List */}
      <DataTable
        noDataComponent={t("no_records")}
        className="cards_table"
        columns={columns}
        data={companies}
        noHeader
        sortServer
        onSort={handleSorting}
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
        pagination
        paginationServer
        progressPending={loading}
        progressComponent={<Loader />}
      />

      {/* Modals */}
      {modalState?.isOpen ? (
        <CompanyUpsertModal
          data={modalState?.data}
          refetchList={refetchList}
          onClose={() => setModalState(modalInitState)}
        />
      ) : null}
    </>
  );
};

export default CompaniesList;
