import React from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {setIsEmployeeImportModalOpen,setIsEmployeeImportModalErrorsOpen } from "../../../../Store/Actions";
import MainModal from "../../../MainModal";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ErrosModalAddBulkEmployees = (props) => {
  const { t } = useTranslation();

  const handleOpenImportModal = () => {
    props.setIsEmployeeImportModalOpen(true);

    props.setIsEmployeeImportModalErrorsOpen(false);
  };


  const modalFooterComponent = () => {
    return (
      <div className="d-flex flex-row w-100 justify-content-between align-items-center">
        <button
          className="btn btn-danger"
          onClick={() => props.setIsEmployeeImportModalErrorsOpen(false)}
        >
          {t("cancel")}
        </button>
        <button className="btn btn-primary" onClick={handleOpenImportModal}>
          <div className="upload-btn-for-main-modal-icon-wrapper d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              className="font-awesome-icon-main-modal"
              icon={faFileUpload}
            />
          </div>
          <p className="mr-3 my-auto">{t("import new file")}</p>
        </button>
      </div>
    );
  };

  return (
    <MainModal
      isOpen={props.isEmployeeImportModalErrorsOpen}
      modalTitle="employee import failed, please fix the following errors and try again"
      className="import_employees-modal-error-style"
      modalFooterComponent={modalFooterComponent}
    >
      <div className="error-employees-upload-container d-flex flex-column justify-content-start align-items-start">
        <Row className="error-modal-header w-100 mx-0">
          <Col xs={1}>{t("rows#")}</Col>
          <Col
            xs={2}
            className="row-name d-flex justify-content-start justify-content-lg-center"
          >
            {t("name")}
          </Col>
          <Col>
            <Row className="w-100">
              <Col xs={3}>{t("column")}</Col>
              <Col xs={3}>{t("value")}</Col>
              <Col>{t("error")}</Col>
            </Row>
          </Col>
        </Row>
        <div className="w-100 overflow-auto errors-wrapper">
          {props.uploadEmployeesFailedErrors?.map((msg) => {
            return (
              <Row className="info-error-modal w-100 mx-0 py-3">
                <Col
                  xs={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  {msg?.row}
                </Col>
                <Col
                  xs={2}
                  className="d-flex justify-content-start justify-content-lg-center align-items-center text-truncate"
                >
                  {msg?.name}
                </Col>
                <Col>
                  {msg?.error_data.map((error) => (
                    <Row className="w-100">
                      <Col xs={3}>{error?.column}</Col>
                      <Col xs={3} className="text-truncate">
                        {error?.value}
                      </Col>
                      <Col>{error?.message}</Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    uploadEmployeesFailedErrors: state?.super?.uploadEmployeesFailedErrors,
    isEmployeeImportModalErrorsOpen:
      state?.super?.isEmployeeImportModalErrorsOpen,
  };
};

export default connect(mapStateToProps,{setIsEmployeeImportModalOpen,setIsEmployeeImportModalErrorsOpen})(ErrosModalAddBulkEmployees);
